import 'remirror/styles/all.css';

import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Remirror,
  useRemirror,
  useEditorEvent,
  useExtensionEvent,
  ThemeProvider,
  useHelpers,
} from '@remirror/react';

import {
  BoldExtension,
  CalloutExtension,
  ItalicExtension,
  CodeExtension,
  BulletListExtension,
  OrderedListExtension,
  BlockquoteExtension,
  HardBreakExtension,
  HeadingExtension,
  UnderlineExtension,
  LinkExtension,
  StrikeExtension,
  CodeBlockExtension,
  MarkdownExtension,
  TableExtension,
  ListItemExtension,
  ListItemSharedExtension,
  TaskListExtension,
  TaskListItemExtension,
  MentionAtomExtension,
} from 'remirror/extensions';

import {
  FloatingToolbar,
  FormattingButtonGroup,
  HeadingLevelButtonGroup,
} from '@remirror/react-ui';

import '../../styles/prosemirror.css';
import { EditorState, InvalidContentHandler } from '@remirror/core';
import lodashDebounce from 'lodash/debounce';
import { logger } from '@tactiq/model';

const CITATION_HREF_PREFIX = '/markdown-citation/';

const CitationClickHandler = ({
  onClick,
}: {
  onClick: ((from: number, to: number) => void) | undefined;
}) => {
  useExtensionEvent(
    LinkExtension,
    'onClick',
    useCallback(
      (_, data) => {
        if (data.href.startsWith(CITATION_HREF_PREFIX)) {
          const [from, to] = data.href
            .slice(CITATION_HREF_PREFIX.length)
            .split('/')
            .map((x) => parseInt(x));
          onClick && onClick(from, to);
        }
        return true;
      },
      [onClick]
    )
  );

  return null;
};

const FocusListener = ({
  onFocus,
  onBlur,
}: {
  onFocus: (() => void) | undefined;
  onBlur: (() => void) | undefined;
}) => {
  useEditorEvent('focus', () => {
    onFocus && onFocus();
  });

  useEditorEvent('blur', () => {
    onBlur && onBlur();
  });

  return null;
};

const Toolbar = () => {
  const [focused, setFocused] = React.useState(false);

  useEditorEvent('focus', () => {
    setFocused(true);
  });

  useEditorEvent('blur', () => {
    setFocused(false);
  });

  if (!focused) {
    return null;
  }

  return (
    <FloatingToolbar>
      <FormattingButtonGroup />
      <HeadingLevelButtonGroup />
    </FloatingToolbar>
  );
};

const extensions = () => {
  return [
    new LinkExtension({ autoLink: true }),
    new BoldExtension({}),
    new StrikeExtension({}),
    new ItalicExtension({}),
    new HeadingExtension({}),
    new UnderlineExtension({}),
    new BlockquoteExtension({}),

    new BulletListExtension({}),
    new OrderedListExtension({}),
    new ListItemExtension({
      enableCollapsible: true,
    }),
    new ListItemSharedExtension(),
    new TaskListExtension(),
    new TaskListItemExtension({}),

    new CodeExtension({}),
    new CalloutExtension({}),
    new CodeBlockExtension({}),
    new TableExtension({}),
    new MarkdownExtension({ copyAsMarkdown: false }),
    new HardBreakExtension({}),
    new MentionAtomExtension({
      extraAttributes: { type: 'user' },
      matchers: [
        {
          name: 'at',
          char: '@',
          matchOffset: 0,
          supportedCharacters: /\S+/,
        },
      ],
    }),
  ];
};

function parseCaptureGroups(captureGroups: string[]): [number, number] {
  const seconds = parseInt(captureGroups[3]);
  const minutes = parseInt(captureGroups[2] ?? '0');
  const hours = parseInt(captureGroups[1] ?? '0');
  const start = hours * 3600 + minutes * 60 + seconds;
  if (captureGroups[4] === undefined) {
    return [start, start];
  }
  const endSeconds = parseInt(captureGroups[7]);
  const endMinutes = parseInt(captureGroups[6] ?? '0');
  const endHours = parseInt(captureGroups[5] ?? '0');
  const end = endHours * 3600 + endMinutes * 60 + endSeconds;
  return [start, end];
}

const timestampRegex =
  /\\?\[(?:(\d{1,2}):)?(\d{2}):(\d{2})(?:(\s*-\s*)(?:(\d{1,2}):)?(\d{2}):(\d{2}))?\\?\]/g;

const convertCitationsToLinks = (input: string) => {
  const matches = input.matchAll(timestampRegex);
  let lastIndex = 0;
  const children: string[] = [];
  for (const match of matches) {
    const fullMatch = match[0];
    const index = match.index;
    if (index === undefined) {
      continue;
    }
    const interval = parseCaptureGroups(match);
    const text = input.slice(lastIndex, index);
    children.push(text);
    children.push(
      `[${fullMatch}](${CITATION_HREF_PREFIX}${interval[0]}/${interval[1]})`
    );
    lastIndex = index + fullMatch.length;
  }
  if (lastIndex < input.length) {
    children.push(input.slice(lastIndex));
  }
  if (children.length === 0) {
    children.push(input);
  }
  return children.join('');
};

function stripCitationsLinks(input: string) {
  const matches = input.matchAll(/\]\(\/markdown-citation\/\d+\/\d+\)/g);
  let lastIndex = 0;
  const children: string[] = [];
  for (const match of matches) {
    let index = match.index;
    const matchIndex = index;
    if (index === undefined) {
      continue;
    }
    // move left until we find matching '['
    let nesting = 0;
    while (index > 0) {
      if (input[index] === ']') {
        nesting += 1;
      } else if (input[index] === '[') {
        nesting -= 1;
        if (nesting === 0) {
          break;
        }
      }
      index -= 1;
    }
    const text = input.slice(lastIndex, index);
    children.push(text);
    children.push(input.slice(index + 1, matchIndex));
    lastIndex = matchIndex + match[0].length;
  }
  if (lastIndex < input.length) {
    children.push(input.slice(lastIndex));
  }
  if (children.length === 0) {
    children.push(input);
  }
  return children.join('');
}

export const RichTextInput: React.FC<{
  value: string;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  onCitationClick?: (from: number, to: number) => void;
  isEditable?: boolean;
  autoFocus?: boolean;
  debounce?: number;
  disableCitations?: boolean;
}> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  isEditable,
  autoFocus,
  onCitationClick,
  disableCitations,
  debounce = 0,
}) => {
  const content = disableCitations ? value : convertCitationsToLinks(value);

  const onError: InvalidContentHandler = useCallback(
    ({ json, invalidContent, transformers }) => {
      // Automatically remove all invalid nodes and marks.
      logger.warn('Invalid content in RichTextInput', {
        invalidContent,
      });
      return transformers.remove(json, invalidContent);
    },
    []
  );

  const visual = useRemirror({
    extensions,
    stringHandler: 'markdown',
    content,
    onError,
  });

  const changeHandler = useMemo(
    () => lodashDebounce(onChange, debounce),
    [debounce, onChange]
  );

  // Clean up the debounce on unmount
  useEffect(() => () => changeHandler.cancel(), [changeHandler]);

  return (
    <ThemeProvider>
      <Remirror
        autoFocus={autoFocus}
        manager={visual.manager}
        autoRender="end"
        onChange={({ helpers, state, tr }) => {
          const newMarkdown = helpers.getMarkdown(state);
          const contentChanged = tr?.docChanged;

          // we still need to update the state when it's not editable
          // to allow for selection/cursor position changes
          if (!isEditable && contentChanged) {
            return;
          }
          visual.setState(state);
          if (contentChanged) {
            changeHandler(stripCitationsLinks(newMarkdown));
          }
        }}
        state={visual.state}
        classNames={isEditable ? [''] : ['remirror-readonly']}
      >
        <Focus onFocus={onFocus} onBlur={onBlur} state={visual.state} />
        {disableCitations ? null : (
          <CitationClickHandler onClick={onCitationClick} />
        )}
        {isEditable ? <Toolbar /> : null}
      </Remirror>
    </ThemeProvider>
  );
};

function Focus(props: {
  onFocus?: () => void;
  onBlur?: (value: string) => void;
  state: EditorState;
}) {
  const helpers = useHelpers();
  return (
    <FocusListener
      onFocus={props.onFocus}
      onBlur={() => props.onBlur?.(helpers.getMarkdown(props.state))}
    />
  );
}
