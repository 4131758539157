import { FeatureFlagServiceBase } from '@tactiq/model';
import { isProduction } from '../firebase/config';

class FeatureFlagService extends FeatureFlagServiceBase {
  isPreviewMeetingsCleanupWarningEnabled(): boolean {
    return this.isEnabled('webapp-enable-preview-meetings-cleanup-warning');
  }

  areActionItemMeetingKitItemsEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkititems-actionitems');
  }

  isMeetingKitPublishingEnabled(): boolean {
    return this.isEnabled('web-enable-meetingkit-publishing');
  }

  isGoogleCalendarWritePermissionsEnabled(): boolean {
    return this.isEnabled('web-enable-google-calendar-write-permissions');
  }

  isReportingEnabled(): boolean {
    return this.isEnabled('web-enable-reporting');
  }

  isSetupPageTypeformEnabled(): boolean {
    return this.isEnabled('web-enable-setuppage-typeform');
  }

  isAiSummaryPreviewExperimentEnabled(): boolean {
    return this.isEnabled('ai-summary-preview-experiment') ?? false;
  }

  isAutomaticWorkflowTriggersEnabled(): boolean {
    return this.isEnabled('automatic-workflow-triggers');
  }

  isWorkflowPublishingEnabled(): boolean {
    return this.isEnabled('workflows-publishing');
  }

  isAlwaysConfirmRecordingSettingEnabled(): boolean {
    return this.isEnabled('always-confirm-recording-setting');
  }

  isDec2024SigninEnabled(): boolean {
    return this.isEnabled('webapp-enable-dec2024-signin');
  }

  isNewMeetingListsEnabled(): boolean {
    return this.isEnabled(WebappEnabledNewMeetingLists);
  }

  getAvailableIntegrations(): Set<string> {
    const baseIntegrations = new Set(['slack']);
    const variant = this.getVariant('workflows-integrations-preview');
    if (!variant.enabled) return baseIntegrations;

    let items: string[] = [];
    if (variant.payload && variant.payload.type === 'json') {
      try {
        items = JSON.parse(variant.payload.value);
      } catch {}
    }
    return new Set([...baseIntegrations, ...items]);
  }

  getFlagsForABTesting(): Record<string, boolean> {
    return {
      [`flag_${WebappEnabledNewMeetingLists.replaceAll('-', '_')}`]:
        this.isNewMeetingListsEnabled(),
    };
  }
}

const WebappEnabledNewMeetingLists = 'webapp-enable-newmeetinglists';

const featureFlagService = new FeatureFlagService({
  appName: 'tactiq-webapp',
  environment: isProduction() ? 'production' : 'development',
});

export default featureFlagService;
