import React, { ComponentType, ReactElement, ReactNode, useId } from 'react';
import { DataSchema } from '../../services/Integration';
import { LiquidTemplateInput } from '../../components/LiquidTemplateInput';
import get from 'lodash/get';
import set from 'lodash/set';
import { FormattedMessage } from 'react-intl';
import { jsonSchemaGet } from '@tactiq/model';
import { AutocompleteProps } from './nodes/useAutocomplete';
import cloneDeep from 'lodash/cloneDeep';
import { cx } from '../../helpers/utils';

export type UiSchemaItem = {
  path: string;
  recursive?: boolean;
  label?: () => ReactNode;
  Component?: ComponentType<{
    disabled: boolean;
    value: any;
    onChange: (next: any) => void;
  }>;
};

export type UiSchema = Array<UiSchemaItem>;
export type FormOverride = {
  preParameters?: UiSchema;
  parameters?: UiSchema;
  config?: UiSchema;
  defaultFieldData?: Record<string, unknown>;
};

/**
 * Render a form from a JsonSchema object
 */
export function JsonForm(props: {
  schema: DataSchema;
  value: unknown;
  onChange: (next: unknown) => void;
  disabled: boolean;
  objectKey?: string;
  formOverride?: FormOverride;
  path?: string[];
  autocomplete?: AutocompleteProps;
}): ReactElement | null {
  const {
    disabled,
    schema,
    value,
    onChange,
    objectKey,
    formOverride,
    path = [],
    autocomplete,
  } = props;
  const stateProps = { value, onChange, disabled, objectKey };
  const { type } = schema;

  // Ui schema is non-recursive
  if (formOverride?.parameters) {
    return (
      <>
        {formOverride?.parameters.map(
          ({ recursive, path: itemPath, label, Component }) => {
            const itemSchema = jsonSchemaGet(schema, itemPath);
            if (!itemSchema)
              throw new Error(
                `Could not find schema at: ${itemPath.toString()}`
              );

            const nextOnChange = (next: unknown) => {
              onChange(
                // @TODO Figure out how to properly type the json schema
                set(cloneDeep(value as object), itemPath, next)
              );
            };
            const nextValue = get(value, itemPath, '');

            if (Component)
              return (
                <FormRow
                  key={itemPath}
                  id={itemPath}
                  label={label?.() ?? ''}
                  type=""
                >
                  <Component
                    value={nextValue}
                    onChange={nextOnChange}
                    disabled={disabled}
                  />
                </FormRow>
              );

            if (recursive)
              return (
                <JsonForm
                  key={itemPath}
                  schema={itemSchema}
                  value={nextValue}
                  onChange={nextOnChange}
                  disabled={disabled}
                  path={path.concat(itemPath)}
                  autocomplete={autocomplete}
                />
              );

            return (
              <StringInput
                key={itemPath}
                schema={itemSchema}
                label={label?.()}
                isArray={itemSchema.type === 'array'}
                onChange={nextOnChange}
                value={nextValue}
                disabled={disabled}
                autocomplete={autocomplete}
              />
            );
          }
        )}
      </>
    );
  }

  switch (type) {
    case 'object': {
      // @TODO Figure out how to properly type the json schema
      const props = stateProps as {
        value: Record<string, unknown>;
        onChange: (next: Record<string, unknown>) => void;
        disabled: boolean;
      };
      if (!schema.properties) return null;
      return (
        <ObjectForm
          path={path}
          formOverride={formOverride}
          properties={schema.properties}
          autocomplete={autocomplete}
          {...props}
        />
      );
    }

    case 'number':
    case 'integer':
    case 'string':
    case 'boolean':
    case 'array': {
      const usersRefCollection = ['users', 'contacts', 'owners'];
      const referenceCollection =
        schema.referenceCollection ?? schema.items?.referenceCollection;
      // If the schema is a reference collection, set the default value to the meeting participant emails
      // This is because nothing else will work in the context of the form for dynamic schemas
      // To be safe we are checking if the key is of "users" type
      if (usersRefCollection.includes(referenceCollection?.key)) {
        if (!stateProps.value) {
          onChange('{{ meeting.participantEmails }}');
        }
      }

      return (
        <StringInput
          schema={schema}
          isArray={type === 'array'}
          autocomplete={autocomplete}
          {...stateProps}
        />
      );
    }

    default:
      return null;
  }
}

function ObjectForm(props: {
  value: Record<string, unknown>;
  onChange: (next: Record<string, unknown>) => void;
  properties: NonNullable<DataSchema['properties']>;
  disabled: boolean;
  path: string[];
  formOverride?: FormOverride;
  autocomplete?: AutocompleteProps;
}) {
  const { properties, value, onChange, disabled, path, formOverride } = props;
  return (
    <>
      {Object.entries(properties)
        .filter(([, schema]) => !schema.readOnly)
        .map(([key, schema]) => {
          const defaultValue = schema.type === 'object' ? {} : '';
          return (
            <JsonForm
              key={key}
              objectKey={key}
              schema={schema}
              value={value[key] ?? defaultValue}
              onChange={(next) => onChange({ ...value, [key]: next })}
              disabled={disabled}
              path={path.concat(key)}
              formOverride={formOverride}
              autocomplete={props.autocomplete}
            />
          );
        })}
    </>
  );
}

function StringInput({
  schema,
  value,
  onChange,
  disabled,
  objectKey,
  label,
  isArray,
  autocomplete,
}: {
  schema: DataSchema;
  value: string | unknown;
  onChange: (next: string) => void;
  objectKey?: string;
  disabled: boolean;
  label?: ReactNode;
  isArray: boolean;
  autocomplete?: AutocompleteProps;
}) {
  const isReference =
    schema.referenceCollection || schema.items?.referenceCollection;
  const formId = useId();
  return (
    <FormRow
      id={formId}
      label={label ?? schema.title ?? schema.description ?? objectKey}
      className="flex-grow"
      type={
        isReference ? (
          isReference.key
        ) : isArray ? (
          <FormattedMessage defaultMessage="List" />
        ) : (
          <FormattedMessage defaultMessage="Text" />
        )
      }
    >
      <LiquidTemplateInput
        {...autocomplete}
        id={formId}
        workflowId=""
        className="min-h-16"
        value={String(value)}
        onChange={(next) => onChange(next)}
        disabled={disabled || Boolean(isReference)}
        isArray={isArray}
      />
    </FormRow>
  );
}

function FormRow({
  children,
  id,
  label,
  type,
  className,
}: {
  children: ReactNode;
  id: string;
  label: ReactNode;
  type: ReactNode;
  className?: string;
}) {
  return (
    <div className={cx(className ?? '', 'flex w-full flex-col gap-1')}>
      <div
        id={id}
        className="flex min-w-32 items-end justify-between font-medium text-sm"
      >
        <label htmlFor={id}>{label}</label>
        <span className="text-slate-400 text-xs">{type}</span>
      </div>
      {children}
    </div>
  );
}
