import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gotTeam } from '../redux/modules/user';
import { useMutation } from '@apollo/client';
import {
  AddTeamMemberDocument,
  ChangeMemberRolesDocument,
  LeaveTeamDocument,
  RemovePendingInvitationDocument,
  RemoveTeamMemberDocument,
  Team,
  TeamMember,
  TeamMemberStatus,
} from '../graphql/operations';
import {
  trackTeamRemoveInvitation,
  trackTeamRemoveMember,
  trackWebEvent,
} from '../helpers/analytics';
import { delay } from '@tactiq/model';
import {
  selectTeamCancellationDiscountCouponUsed,
  selectUid,
} from '../redux/selectors';

/**
 * Remove a member from a team
 */
export function useRemoveTeamMember(input: {
  userId: string;
  teamId: string;
}): (member: { uid: string }) => Promise<void> {
  const { userId, teamId } = input;
  const dispatch = useDispatch();
  const [leaveTeam, { error: leaveTeamError }] = useMutation(LeaveTeamDocument);
  const [removeTeamMember, { error: removeTeamMemberError }] = useMutation(
    RemoveTeamMemberDocument
  );

  if (leaveTeamError) throw leaveTeamError;
  if (removeTeamMemberError) throw removeTeamMemberError;

  return useCallback(
    async (member: { uid: string }) => {
      if (member.uid === userId) {
        await leaveTeam();
        dispatch(gotTeam(undefined));
      } else {
        dispatch(
          gotTeam(
            (
              await removeTeamMember({
                variables: { input: { memberId: member.uid } },
              })
            ).data?.RemoveTeamMember
          )
        );
      }
      trackTeamRemoveMember({ teamId, memberId: member.uid });
    },
    [userId, teamId, leaveTeam, dispatch, removeTeamMember]
  );
}

/**
 * Remove a pending invitation
 */
export function useRemoveInvitation(): (member: {
  email: string;
}) => Promise<void> {
  const dispatch = useDispatch();
  const [removeTeamInvitation] = useMutation(RemovePendingInvitationDocument);

  return useCallback(
    async (member: { email: string }) => {
      dispatch(
        gotTeam(
          (
            await removeTeamInvitation({
              variables: { input: { memberEmail: member.email } },
            })
          ).data?.RemovePendingInvitation
        )
      );
      trackTeamRemoveInvitation();
    },
    [dispatch, removeTeamInvitation]
  );
}

/**
 * Assign a new admin for the team
 */
export function useAssignNewAdmin(): (memberId: string) => Promise<void> {
  const dispatch = useDispatch();
  const [changeMemberRoles] = useMutation(ChangeMemberRolesDocument);

  return useCallback(
    async (memberId: string) => {
      trackWebEvent('New admin assigned');
      dispatch(
        gotTeam(
          (
            await changeMemberRoles({
              variables: {
                input: {
                  memberId,
                  roles: {
                    ADMIN: true,
                  },
                },
              },
            })
          ).data?.team_changeMemberRoles
        )
      );
    },
    [changeMemberRoles, dispatch]
  );
}

export const useAddTeamMember = (options: {
  onCompleted: () => void;
}): {
  request: (emails: string[]) => Promise<void>;
  loading: boolean;
} => {
  const dispatch = useDispatch();
  const { onCompleted } = options;
  const [loading, setLoading] = useState(false);

  const [addTeamMember] = useMutation(AddTeamMemberDocument);

  const request = useCallback(
    async (emails: string[]) => {
      setLoading(true);
      for (const memberEmail of emails) {
        dispatch(
          gotTeam(
            (
              await addTeamMember({
                variables: { input: { memberEmail } },
              })
            ).data?.AddTeamMember
          )
        );
        await delay(1100);
      }

      trackWebEvent('Team - Add Members', {
        count: emails.length,
      });

      setLoading(false);
      onCompleted();
    },
    [onCompleted, dispatch, addTeamMember]
  );

  return {
    request,
    loading,
  };
};

/*
admin cannot request an upgrade - admin can just upgrade themselves
only the current user can request an upgrade - you cannot request upgrade for someone else
you cannot request upgrade if you have already requested upgrade
you can only request upgrade if you don’t have a paid plan or you have a personal plan (in which case you want to get consolidated into the team’s billing)
your team needs to have a paid plan to make upgrade requests possible

upgrade means: get a seat on the team’s plan assigned to you
*/

export const canMemberRequestUpgrade = (
  team: Team,
  member?: TeamMember
): boolean => {
  if (!member) {
    return false;
  }

  const userId = useSelector(selectUid);
  const isAdmin =
    team?.members.some((m) => m.uid === userId && m.roles.ADMIN) ?? false;
  const loggedInUserMember = team.members.find((m) => m.uid === userId);
  const isFreeUser = loggedInUserMember?.status === TeamMemberStatus.FREE;
  const isCurrentUser = userId === member.uid;

  return (
    !isAdmin &&
    isCurrentUser &&
    !member.requestedUpgrade &&
    (isFreeUser || member.status === TeamMemberStatus.PRO) &&
    team.isPaid
  );
};

export function getTeamCancellationDiscountCouponUsed(): string {
  return useSelector(selectTeamCancellationDiscountCouponUsed);
}
