import { useMutation } from '@apollo/client';
import { logger } from '@tactiq/model';
import isEqual from 'lodash/isEqual';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import {
  CopyMeetingKitDocument,
  CreateMeetingKitItemDocument,
  DeleteMeetingKitDocument,
  MeetingKitItemOutputType,
  MeetingKitItemType,
  MeetingKitOwnerType,
  PublishMeetingKitDocument,
  UpdateMeetingKitDocument,
  UpdateMeetingKitInput,
  UpdatePublicMeetingKitDocument,
  UseMeetingKitDocument,
} from '../../graphql/operations';
import { trackWebEvent, trackWebPage } from '../../helpers/analytics';
import {
  removeMeetingKit,
  setMeetingKit,
  setMeetingKitItem,
} from '../../redux/modules/global';
import { RootState } from '../../redux/store';
import { Page } from '../Common/Navigation';
import { EmojiField } from '../Common/icons/EmojiField';
import { getMeetingKitItemIcon } from './helpers';
import { Alert } from '../../components/Alert';
import { TextInput } from '../../components/TextInput';
import { BriefcaseIcon, FolderIcon, Trash, ArrowDownUp } from 'lucide-react';
import { PageHeader } from '../../components/pageparts/PageHeader';
import { Breadcrumbs } from '../../components/pageparts/Breadcrumbs';
import { PageHeaderChip } from '../../components/pageparts/PageHeaderChip';
import { DescriptionList } from '../../components/pageparts/DescriptionList';
import { DescriptionListItem } from '../../components/pageparts/DescriptionListItem';
import { DescriptionListItemContentSubList } from '../../components/pageparts/DescriptionListItemContentSubList';
import { DescriptionListItemContentSubItem } from '../../components/pageparts/DescriptionListItemContentSubItem';
import { Chip } from '../../components/Chips';
import featureFlagService from '../../helpers/feature-flags';
import { ReactSortable } from 'react-sortablejs';
import { ModalDialog } from '../../components/modals';

/**
 * Meeting kit page
 * @param {unknown }param0 params
 * @param {boolean} param0.isEditing is editing
 * @returns {React.FC} a component
 */
export const MeetingKitPage: React.FC<{ isEditing?: boolean }> = ({
  isEditing: requestedEditMode,
}) => {
  const intl = useIntl();

  const usedMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.used
  );

  const exploreMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.explore
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const match = useParams<{ kitId: string }>();

  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const usedKit = usedMeetingKits.find((k) => k.id === match.kitId);
  const exploreKit = exploreMeetingKits.find((k) => k.id === match.kitId);

  const [kitIsOutDated, setKitIsOutDated] = useState(false);
  const kit = usedKit ?? exploreKit;
  const kitItems = [...(kit?.items ?? []).map((item) => ({ ...item }))];
  kitItems.sort((a, b) => a.order - b.order);
  const [sortedItems, setItems] = useState(kitItems);

  const canEdit =
    kit?.owner.type === MeetingKitOwnerType.SELF && !kit?.publishedFrom;
  const isEditing = canEdit && requestedEditMode;
  const canPublish =
    !isEditing && canEdit && featureFlagService.isMeetingKitPublishingEnabled();

  useEffect(() => {
    if (!kit) return;
    if (kit.owner.sourceLastUpdatedAt) {
      setKitIsOutDated(
        kit.owner.sourceLastUpdatedAt - (kit.owner.updatedAt ?? 0) > 0
      );
    } else {
      setKitIsOutDated(false);
    }
  }, [kit]);

  useEffect(() => {
    kit && trackWebPage('Meeting Kit', { kitId: match.kitId, isEditing });
  }, [match, kit, isEditing]);

  const [createMeetingKitItem, createMeetingKitItemMutation] = useMutation(
    CreateMeetingKitItemDocument
  );

  const [updateMeetingKit, updateMeetingKitMutation] = useMutation(
    UpdateMeetingKitDocument
  );

  const [updatePublicMeetingKit, updatePublicMeetingKitMutation] = useMutation(
    UpdatePublicMeetingKitDocument
  );

  const [deleteMeetingKit, deleteMeetingKitMutation] = useMutation(
    DeleteMeetingKitDocument
  );

  const [startUsingMeetingKit, useMeetingKitMutation] = useMutation(
    UseMeetingKitDocument
  );

  const [copyMeetingKit, copyMeetingKitMutation] = useMutation(
    CopyMeetingKitDocument
  );

  const [publishMeetingKit, publishMeetingKitMutation] = useMutation(
    PublishMeetingKitDocument
  );

  const [kitDetails, setKitDetails] = useState<UpdateMeetingKitInput>();

  useEffect(() => {
    !isEditing &&
      kit &&
      setKitDetails({
        meetingKitId: kit.id,
        name: kit.name,
        description: kit.description,
        icon: kit.icon,
        items: sortedItems.map((item, index) => ({
          item: item.id,
          order: index,
        })),
      });
  }, [kit, sortedItems, isEditing]);

  const startEditing = useCallback(() => {
    kit && navigate(`/meeting-kits/${kit.id}/edit`);
  }, [kit, navigate]);

  const saveChanges = useCallback(async () => {
    if (!kit) return;
    if (!kitDetails) return;
    if (isEqual(kitDetails, { name: kit.name, description: kit.description }))
      return;

    trackWebEvent('Meeting Kit - Update', { kit: kit.id });

    const updatedKit = (
      await updateMeetingKit({
        variables: {
          input: {
            ...kitDetails,
            meetingKitId: kit.id,
          },
        },
      })
    ).data?.updateMeetingKit.meetingKit;

    updatedKit && dispatch(setMeetingKit(updatedKit));

    navigate(`/meeting-kits/${kit.id}`);
  }, [dispatch, kit, kitDetails, navigate, updateMeetingKit]);

  const intlMeetingKitItemOutputType: Record<MeetingKitItemOutputType, string> =
    {
      [MeetingKitItemOutputType.TEXT]: intl.formatMessage({
        defaultMessage: 'Plain Text',
        id: 'XOi37d',
      }),
      [MeetingKitItemOutputType.MARKDOWN]: intl.formatMessage({
        defaultMessage: 'Markdown',
        id: 'jr4+vD',
      }),
      [MeetingKitItemOutputType.ACTION_ITEMS]: intl.formatMessage({
        defaultMessage: 'Action Items',
        id: 'kXd1r0',
      }),
    };

  let content;
  let actions;

  if (isEditing) {
    actions = (
      <>
        <Button
          loading={updateMeetingKitMutation.loading}
          onClick={saveChanges}
        >
          <FormattedMessage defaultMessage="Save" id="jvo0vs" />
        </Button>
        <Button
          loading={updateMeetingKitMutation.loading}
          onClick={() => {
            navigate(`/meeting-kits/${kit.id}`);
          }}
        >
          <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
        </Button>
      </>
    );
  } else if (canEdit) {
    actions = (
      <>
        <Button
          variant="naked"
          color="error"
          onClick={() => setDeleteDialogOpen(true)}
          startIcon={<Trash className="h-5 w-5 text-red-600" />}
        ></Button>
        <Button onClick={startEditing}>
          <FormattedMessage defaultMessage="Edit" id="wEQDC6" />
        </Button>
      </>
    );
  } else {
    actions = kit && (
      <>
        {usedKit ? (
          <>
            {kit?.publishedFrom ? (
              <Chip>
                <FormattedMessage
                  defaultMessage="You are viewing a published version of your kit"
                  id="z6JTl9"
                />
              </Chip>
            ) : (
              <>
                <Chip>
                  <FormattedMessage
                    defaultMessage="You are using a shared kit"
                    id="SweDfL"
                  />
                </Chip>
                <Button
                  loading={deleteMeetingKitMutation.loading}
                  variant="neutral-secondary"
                  startIcon={<Trash className="h-5 w-5" />}
                  color="warning"
                  onClick={async () => {
                    trackWebEvent('Stop using kit', {
                      from: 'meeting-kits-page',
                    });

                    await deleteMeetingKit({
                      variables: {
                        input: {
                          meetingKitId: kit.id,
                        },
                      },
                    });

                    dispatch(removeMeetingKit(kit.id));
                    navigate(`/meeting-kits/${kit.owner.sourceId}`);
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Stop Using Kit"
                    id="b2c7IX"
                  />
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            {usedMeetingKits.find((k) => k.owner.sourceId === kit.id) ? (
              <>
                <Chip>
                  <FormattedMessage
                    defaultMessage="You are already using this kit"
                    id="ZLXyRX"
                  />
                </Chip>
                <Button
                  onClick={async () => {
                    const alreadyUsedKit = usedMeetingKits.find(
                      (k) => k.owner.sourceId === kit.id
                    );
                    alreadyUsedKit &&
                      navigate(`/meeting-kits/${alreadyUsedKit.id}`);
                  }}
                >
                  <FormattedMessage defaultMessage="View Kit" id="juLkgB" />
                </Button>
              </>
            ) : (
              <>
                <Button
                  loading={
                    useMeetingKitMutation.loading ||
                    copyMeetingKitMutation.loading
                  }
                  color="success"
                  onClick={async () => {
                    trackWebEvent('Use meeting kit', {
                      from: 'meeting-kits-page',
                    });

                    const newUsedKit = (
                      await startUsingMeetingKit({
                        variables: {
                          input: {
                            meetingKitId: kit.id,
                          },
                        },
                      })
                    ).data?.useMeetingKit.meetingKit;

                    newUsedKit && dispatch(setMeetingKit(newUsedKit));
                    enqueueSnackbar(
                      intl.formatMessage({
                        defaultMessage: 'You can now use this kit',
                        id: 'EQjtxn',
                      }),
                      { variant: 'SUCCESS' }
                    );
                    newUsedKit && navigate(`/meeting-kits/${newUsedKit.id}`);
                  }}
                >
                  <FormattedMessage defaultMessage="Use Kit" id="zvbl3G" />
                </Button>
                {kit.shared.allowCopy && (
                  <Button
                    loading={
                      useMeetingKitMutation.loading ||
                      copyMeetingKitMutation.loading
                    }
                    onClick={async () => {
                      trackWebEvent('Use meeting kit', {
                        from: 'meeting-kits-page',
                      });

                      const copiedKit = (
                        await copyMeetingKit({
                          variables: {
                            input: {
                              meetingKitId: kit.id,
                            },
                          },
                        })
                      ).data?.copyMeetingKit.meetingKit;

                      copiedKit && dispatch(setMeetingKit(copiedKit));
                      enqueueSnackbar(
                        intl.formatMessage({
                          defaultMessage: 'You can now use and edit this kit',
                          id: 'zD4j75',
                        }),
                        { variant: 'SUCCESS' }
                      );
                      copiedKit &&
                        navigate(`/meeting-kits/${copiedKit.id}/edit`);
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Copy and Edit Kit"
                      id="DjG+g1"
                    />
                  </Button>
                )}
              </>
            )}
            <Button
              onClick={() => {
                window.navigator.clipboard
                  .writeText(window.location.href)
                  .catch(logger.error);
                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage: 'Link copied to clipboard',
                    id: '2yCGR2',
                  }),
                  { variant: 'SUCCESS' }
                );
              }}
            >
              <FormattedMessage defaultMessage="Copy Link" id="u5aHb4" />
            </Button>
          </>
        )}
      </>
    );
  }

  if (kit) {
    const itemList = sortedItems.map((item) => ({
      ...item,
      ...(kitItems.find((x) => x.id === item.id) ?? {}),
    }));

    content = (
      <>
        {kitIsOutDated &&
          (kit.owner.type === MeetingKitOwnerType.SELF && kit.publishedFrom ? (
            <div className="my-4">
              <Alert
                severity="info"
                description={intl.formatMessage({
                  defaultMessage:
                    'This version is outdated. You can publish the changes at any time.',
                  id: 'KjYEnY',
                })}
                action={
                  <Button
                    loading={updatePublicMeetingKitMutation.loading}
                    onClick={async () => {
                      trackWebEvent('Meeting Kit - Published changes', {
                        meetingKitId: kit.id,
                      });
                      const updatedKit = (
                        await updatePublicMeetingKit({
                          variables: {
                            input: {
                              meetingKitId: kit.id,
                            },
                          },
                        })
                      ).data?.updatePublicMeetingKit.meetingKit;
                      updatedKit && dispatch(setMeetingKit(updatedKit));
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="Publish changes"
                      id="oT3u80"
                    />
                  </Button>
                }
              />
            </div>
          ) : (
            <div className="my-4">
              <Alert
                severity="info"
                description={intl.formatMessage({
                  defaultMessage:
                    'This meeting kit has been updated by its creator, You can update it by clicking the update button.',
                  id: 'oyYitk',
                })}
                action={
                  <Button
                    loading={updatePublicMeetingKitMutation.loading}
                    onClick={async () => {
                      trackWebEvent(
                        'Meeting Kit - Updated meeting kit from source',
                        { meetingKitId: kit.id }
                      );
                      const updatedKit = (
                        await updatePublicMeetingKit({
                          variables: {
                            input: {
                              meetingKitId: kit.id,
                            },
                          },
                        })
                      ).data?.updatePublicMeetingKit.meetingKit;
                      updatedKit && dispatch(setMeetingKit(updatedKit));
                    }}
                  >
                    <FormattedMessage defaultMessage="Update kit" id="Qxh5jd" />
                  </Button>
                }
              />
            </div>
          ))}
        <DescriptionList>
          <DescriptionListItem
            label={<FormattedMessage defaultMessage="Icon" id="kk4TVW" />}
            content={
              <EmojiField
                onChange={
                  isEditing && kitDetails
                    ? (emoji) => {
                        setKitDetails({
                          ...kitDetails,
                          icon: emoji.unified,
                        });
                      }
                    : undefined
                }
                value={kitDetails?.icon ?? kit.icon}
                defaultIcon={<FolderIcon fontSize="large" />}
              />
            }
          />
          <DescriptionListItem
            label={<FormattedMessage defaultMessage="Name" id="HAlOn1" />}
            content={
              isEditing && kitDetails ? (
                <TextInput
                  disabled={updateMeetingKitMutation.loading}
                  value={kitDetails.name}
                  onChange={(value) => {
                    setKitDetails({
                      ...kitDetails,
                      name: value,
                    });
                  }}
                />
              ) : (
                kit.name
              )
            }
          />
          <DescriptionListItem
            label={
              <FormattedMessage defaultMessage="Description" id="Q8Qw5B" />
            }
            content={
              isEditing ? (
                <TextInput
                  type="textarea"
                  disabled={updateMeetingKitMutation.loading}
                  value={kitDetails?.description}
                  onChange={(value) => {
                    kitDetails &&
                      setKitDetails({
                        ...kitDetails,
                        description: value,
                      });
                  }}
                />
              ) : (
                kit.description
              )
            }
          />
          <DescriptionListItem
            label={<FormattedMessage defaultMessage="Contents" id="MyIJbr" />}
            content={
              <div className="flex flex-col space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <p className="mt-2 flex-1 text-slate-700 text-sm md:mt-0">
                    <FormattedMessage
                      defaultMessage="Prompts (questions) in this meeting kit"
                      id="fuU1sj"
                    />
                  </p>
                  {!isEditing && canEdit && (
                    <Button
                      loading={createMeetingKitItemMutation.loading}
                      onClick={async () => {
                        trackWebEvent('Create a tool', {
                          from: 'meeting-kits-page',
                        });

                        const item = (
                          await createMeetingKitItem({
                            variables: {
                              input: {
                                meetingKitId: kit.id,
                                type: MeetingKitItemType.PROMPT,
                              },
                            },
                          })
                        ).data?.createMeetingKitItem?.meetingKitItem;

                        if (item) {
                          dispatch(setMeetingKitItem({ kitId: kit.id, item }));
                          navigate(`/meeting-kits/${kit.id}/${item.id}/edit`);
                        } else {
                          enqueueSnackbar(
                            intl.formatMessage({
                              defaultMessage: 'Failed to create a tool',
                              id: 'ywdes7',
                            }),
                            { variant: 'WARNING' }
                          );
                        }
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Add a prompt"
                        id="k3mTKl"
                      />
                    </Button>
                  )}
                </div>
                {kit.items.length > 0 && (
                  <DescriptionListItemContentSubList>
                    {isEditing ? (
                      <ReactSortable list={itemList} setList={setItems}>
                        {itemList.map((item) => (
                          <DescriptionListItemContentSubItem
                            className="cursor-move"
                            key={item.id}
                            icon={
                              <EmojiField
                                value={item.icon}
                                defaultIcon={getMeetingKitItemIcon(
                                  item,
                                  'h-5 w-5 flex-shrink-0 text-slate-400'
                                )}
                                EmojiProps={{
                                  unified: item.icon,
                                  size: 20,
                                }}
                              />
                            }
                            label={item.name}
                            secondaryLabel={
                              intlMeetingKitItemOutputType[item.outputType]
                            }
                            actions={
                              isEditing ? (
                                <ArrowDownUp className="h-5 w-5 text-slate-400" />
                              ) : (
                                <Button
                                  onClick={() => {
                                    if (!usedKit) return;
                                    if (
                                      usedKit.owner.type !==
                                        MeetingKitOwnerType.SELF &&
                                      usedKit.shared.allowCopy
                                    )
                                      return;

                                    navigate(
                                      `/meeting-kits/${kit.id}/${item.id}`
                                    );
                                  }}
                                  variant="naked"
                                >
                                  <FormattedMessage
                                    defaultMessage="View"
                                    id="FgydNe"
                                  />
                                </Button>
                              )
                            }
                          />
                        ))}
                      </ReactSortable>
                    ) : (
                      kit.items.map((item) => (
                        <DescriptionListItemContentSubItem
                          key={item.id}
                          icon={
                            <EmojiField
                              value={item.icon}
                              defaultIcon={getMeetingKitItemIcon(
                                item,
                                'h-5 w-5 flex-shrink-0 text-slate-400'
                              )}
                              EmojiProps={{
                                unified: item.icon,
                                size: 20,
                              }}
                            />
                          }
                          label={item.name}
                          actions={
                            <Button
                              onClick={() => {
                                if (!usedKit) return;
                                if (
                                  usedKit.owner.type !==
                                    MeetingKitOwnerType.SELF &&
                                  usedKit.shared.allowCopy
                                )
                                  return;

                                navigate(`/meeting-kits/${kit.id}/${item.id}`);
                              }}
                              variant="naked"
                            >
                              <FormattedMessage
                                defaultMessage="View"
                                id="FgydNe"
                              />
                            </Button>
                          }
                        />
                      ))
                    )}
                  </DescriptionListItemContentSubList>
                )}
              </div>
            }
          />

          {canPublish && (
            <DescriptionListItem
              label={
                <FormattedMessage
                  defaultMessage="Sharing and Publishing"
                  id="F+bvVc"
                />
              }
              content={
                <div className="flex flex-row items-center space-x-2">
                  {kit.publishedId ? (
                    <>
                      {(usedMeetingKits.find((k) => k.id === kit.publishedId)
                        ?.owner.sourceLastUpdatedAt ?? 0) -
                        (usedMeetingKits.find((k) => k.id === kit.publishedId)
                          ?.owner.updatedAt ?? 0) >
                      0 ? (
                        <p className="flex-1 text-red-600">
                          <FormattedMessage
                            defaultMessage="Published version is outdated"
                            id="dQBmxU"
                          />
                        </p>
                      ) : (
                        <p className="flex-1">
                          <FormattedMessage
                            defaultMessage="Published"
                            id="w7tf2z"
                          />
                        </p>
                      )}
                      <Button
                        variant="naked"
                        onClick={async () => {
                          navigate(`/meeting-kits/${kit.publishedId}`);
                        }}
                      >
                        <FormattedMessage defaultMessage="View" id="FgydNe" />
                      </Button>
                    </>
                  ) : (
                    <>
                      <p className="flex-1">
                        <FormattedMessage
                          defaultMessage="Not shared or published"
                          id="/5pzxd"
                        />
                      </p>
                      <Button
                        loading={publishMeetingKitMutation.loading}
                        onClick={async () => {
                          trackWebEvent('Published meeting kit');

                          const result = (
                            await publishMeetingKit({
                              variables: {
                                input: {
                                  meetingKitId: kit.id,
                                },
                              },
                            })
                          ).data?.publishMeetingKit;

                          if (result) {
                            dispatch(setMeetingKit(result.meetingKit));
                            dispatch(setMeetingKit(result.publishedMeetingKit));
                          }
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="Publish"
                          id="syEQFE"
                        />
                      </Button>
                    </>
                  )}
                </div>
              }
            />
          )}
        </DescriptionList>
      </>
    );
  } else {
    content = (
      <Alert
        severity="warning"
        description={
          <FormattedMessage
            defaultMessage="Meeting Kit not found or you don't have access to it"
            id="46Iqan"
          />
        }
      />
    );
  }

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'AI Kits',
        id: '2xPU/d',
      })}
      maxWidth={'md'}
    >
      <PageHeader
        breadcrumbs={
          <Breadcrumbs
            backHref="/meeting-kits"
            items={[
              usedKit
                ? {
                    label: (
                      <FormattedMessage
                        defaultMessage="All Meeting Kits"
                        id="ufETaW"
                      />
                    ),
                    href: '/meeting-kits',
                  }
                : {
                    label: (
                      <FormattedMessage defaultMessage="Explore" id="7JlauX" />
                    ),
                    href: '/meeting-kits/explore',
                  },
              ...(usedKit && kit?.publishedFrom
                ? [
                    {
                      label: kit.name,
                      href: `/meeting-kits/${kit.publishedFrom}`,
                    },
                  ]
                : []),
              ...(kit
                ? [
                    {
                      label:
                        usedKit && kit.publishedFrom ? 'Published' : kit.name,
                      href: `/meeting-kits/${kit.id}`,
                    },
                  ]
                : []),
            ]}
          />
        }
        title={
          kit?.name ?? (
            <FormattedMessage
              defaultMessage="Meeting Kit Not Found"
              id="NuzTFt"
            />
          )
        }
        actions={actions}
        chips={
          kit
            ? [
                <PageHeaderChip
                  icon={BriefcaseIcon}
                  label={`${kit.items.length} items`}
                  key="itemcount"
                />,
              ]
            : undefined
        }
      />

      {content}

      {kit && (
        <ModalDialog
          open={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          title={
            <FormattedMessage
              defaultMessage="Delete the Meeting Kit?"
              id="ZgMjpr"
            />
          }
          text={
            <FormattedMessage
              defaultMessage="All tools in this kit will be deleted. You will not be able to recover it - this is irreversible. Do you still want to delete it?"
              id="FNeNHi"
            />
          }
          actions={
            <div className="flex flex-row gap-2">
              <Button
                color="error"
                loading={deleteMeetingKitMutation.loading}
                onClick={async () => {
                  trackWebEvent('Delete meeting kit');
                  await deleteMeetingKit({
                    variables: {
                      input: {
                        meetingKitId: kit.id,
                      },
                    },
                  });
                  dispatch(removeMeetingKit(kit.id));
                  navigate(`/meeting-kits`);
                }}
              >
                <FormattedMessage defaultMessage="Delete" id="K3r6DQ" />
              </Button>
              <Button
                loading={deleteMeetingKitMutation.loading}
                onClick={() => setDeleteDialogOpen(false)}
                autoFocus
              >
                <FormattedMessage defaultMessage="Cancel" id="47FYwb" />
              </Button>
            </div>
          }
        />
      )}
    </Page>
  );
};
