import React from 'react';
import slack from '../../../img/slackIcon.svg';
import notion from '../../../img/notion.png';
import linear from '../../../img/linear.png';
import hubspot from '../../../img/hubspot.png';
import googledrive from '../../../img/GoogleDrive32px.svg';
import pipedrive from '../../../img/pipedrive.png';
import workable from '../../../img/workable.png';
import { Mail, Sparkles } from 'lucide-react';
import { SpaceIcon } from '../../Common/icons';

type IconProps = {
  className?: string;
};

export const AllIntegrations_IntegrationApp = [
  {
    id: 'slack',
    name: 'Slack',
    Icon: ({ className }: IconProps) => (
      <img src={slack} alt="Slack" className={className} />
    ),
    iconUrl: slack,
  },
  {
    id: 'notion',
    name: 'Notion',
    iconUrl: notion,
    Icon: ({ className }: IconProps) => (
      <img src={notion} alt="Notion" className={className} />
    ),
  },
  {
    id: 'linear',
    name: 'Linear',
    iconUrl: linear,
    Icon: ({ className }: IconProps) => (
      <img src={linear} alt="Linear" className={className} />
    ),
  },
  {
    id: 'hubspot',
    name: 'HubSpot',
    iconUrl: hubspot,
    Icon: ({ className }: IconProps) => (
      <img src={hubspot} alt="HubSpot" className={className} />
    ),
  },
  {
    id: 'pipedrive',
    name: 'Pipedrive',
    iconUrl: pipedrive,
    Icon: ({ className }: IconProps) => (
      <img src={pipedrive} alt="Pipedrive" className={className} />
    ),
  },
  {
    id: 'googledrive',
    name: 'Google Drive',
    iconUrl: googledrive,
    Icon: ({ className }: IconProps) => (
      <img src={googledrive} alt="Pipedrive" className={className} />
    ),
  },
  {
    id: 'workable',
    name: 'Workable',
    iconUrl: workable,
    Icon: ({ className }: IconProps) => (
      <img src={workable} alt="Workable" className={className} />
    ),
  },
];

export const PseudoIntegrations = [
  {
    id: 'email',
    name: 'Email',
    Icon: Mail,
  },
  {
    id: 'spaces',
    name: 'Spaces',
    Icon: SpaceIcon,
  },
  {
    id: 'ai',
    name: 'AI',
    Icon: Sparkles,
  },
];

export const AllIntegrations = [
  ...AllIntegrations_IntegrationApp,
  ...PseudoIntegrations,
];

export type IntegrationId =
  (typeof AllIntegrations_IntegrationApp)[number]['id'];
