import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Page } from '../Common/Navigation';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../components/buttons';
import {
  CompassIcon,
  EllipsisVertical,
  History,
  LandPlot,
  Plus,
  TrashIcon,
  X,
} from 'lucide-react';
import { cellPaddingClasses, Table } from '../../components/Table';
import { DeadEnd } from '../../components/DeadEnd';
import { Menu } from '../../components/Menu';
import {
  useWorkflowArchive,
  useWorkflowList,
  useWorkflowPublish,
  useWorkflowStopUsing,
} from '../../services/Workflow';
import { Spinner } from '../../components/Spinner';
import { useNavigate } from 'react-router';
import { ConfirmActionDialog } from '../../components/modals';
import {
  trackWorkflowEditorOpened,
  trackWorkflowExploreTemplatesButton,
  trackWorkflowHistoryOpened,
  trackWorkflowProvideFeedbackButton,
  workflowListViewed,
} from '../../helpers/analytics';
import {
  ToggleButton,
  ToggleButtonGroup,
} from '../../components/ToggleButtonGroup';
import {
  ListWorkflowsQuery,
  WorkflowsType,
  WorkflowTriggerType,
} from '../../graphql/operations';
import { Avatar } from '../../components/Avatar';
import { Tooltip } from '../../components/Tooltip';
import { WorkflowDiscoveryModal } from './WorkflowDiscovery';
import { Chip } from '../../components/Chips';
import { Link } from 'react-router-dom';
import { cx } from '../../helpers/utils';
import featureFlagService from '../../helpers/feature-flags';
import { DuplicateWorkflowButton } from './DuplicateWorkflowButton';
import { WatchWorkflowIntroVideoButton } from './VideoTutorialButtons';
import { useSendAutomationEvent } from '../../services/Email';
import { AutomationEventName } from '@tactiq/model';
import { AllIntegrations } from '../Integration/integrations/integration-app';

export default function WorkflowList({
  exploreDialogOpen = false,
}: {
  exploreDialogOpen?: boolean;
}): ReactElement {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: 'My Workflows',
    id: 'KbEin0',
  });
  const [type, setType] = useState(WorkflowsType.ALL);
  const sendAutomationEvent = useSendAutomationEvent();
  const types = React.useMemo(
    () => [
      {
        id: WorkflowsType.ALL,
        name: intl.formatMessage({ defaultMessage: 'All', id: 'zQvVDJ' }),
      },
      {
        id: WorkflowsType.MINE,
        name: intl.formatMessage({ defaultMessage: 'Mine', id: 'snC7V6' }),
      },
      {
        id: WorkflowsType.USED_TEAM,
        name: intl.formatMessage({
          defaultMessage: 'Used from Team',
          id: 'RFyIeZ',
        }),
      },
      {
        id: WorkflowsType.USED_TACTIQ,
        name: intl.formatMessage({
          defaultMessage: 'Used from Tactiq',
          id: 'xiBnVC',
        }),
      },
    ],
    [intl]
  );

  const { data, loading, error } = useWorkflowList({
    input: { type },
  });

  if (error) throw error;

  useEffect(() => {
    if (!loading) {
      if (data?.workflows) {
        sendAutomationEvent.request({
          input: { type: AutomationEventName.workflow_list_viewed },
        });
        workflowListViewed({
          type,
          workflowsCount: data?.workflows?.length,
        });
      }
    }
  }, [data?.workflows, data?.workflows?.length, loading, type]);

  // Discover button is shown in the empty state if there are no used workflows,
  const showDiscoverButton =
    data?.workflows?.length &&
    (type === WorkflowsType.USED_TEAM || type === WorkflowsType.USED_TACTIQ);

  return (
    <Page title={title} grow maxWidth={'lg'}>
      <div className="mb-4 flex flex-wrap items-center justify-between gap-y-4">
        <div>
          <h1 className="mb-2 font-bold text-3xl tracking-tight">{title}</h1>
          <p className="text-slate-500">
            <FormattedMessage
              defaultMessage="Create and manage your workflow automations here"
              id="mgM09X"
            />
          </p>
        </div>
        <div className="flex items-center gap-x-4">
          <WatchWorkflowIntroVideoButton source="my workflows" />
          <ProvideFeedbackButton />
          <CreateWorkflowButton exploreDialogOpen={exploreDialogOpen} />
        </div>
      </div>
      <div className="my-6 flex flex-wrap items-center justify-between gap-3">
        <ToggleButtonGroup value={type} onChange={setType} style="disconnected">
          {types.map((type) => (
            <ToggleButton key={type.id} value={type.id}>
              {type.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        {showDiscoverButton ? (
          <div className="flex justify-end">
            <DiscoverButton type={type} />
          </div>
        ) : null}
      </div>

      {!data?.workflows && loading ? (
        <Spinner className="m-auto my-16" size="1.5rem" />
      ) : (
        <WorkflowTable type={type} workflows={data?.workflows} />
      )}
    </Page>
  );
}

function WorkflowTable({
  type,
  workflows,
}: {
  type: WorkflowsType;
  workflows: ListWorkflowsQuery['workflows'];
}): ReactElement {
  const { formatMessage } = useIntl();

  const archive = useWorkflowArchive();
  const stopUsing = useWorkflowStopUsing();
  const publish = useWorkflowPublish();
  const navigate = useNavigate();
  const [archiveId, setArchiveId] = useState<null | string>(null);
  const [removeId, setRemoveId] = useState<null | string>(null);
  const [publishId, setPublishId] = useState<null | string>(null);

  const isUsedType = [
    WorkflowsType.USED_TEAM,
    WorkflowsType.USED_TACTIQ,
  ].includes(type);

  const triggerTypes = new Map([
    [WorkflowTriggerType.MANUAL, formatMessage({ defaultMessage: 'Manual' })],
    [
      WorkflowTriggerType.AUTOMATIC,
      formatMessage({ defaultMessage: 'Automatic' }),
    ],
  ]);

  const canPublish = featureFlagService.isWorkflowPublishingEnabled();

  const data = workflows?.filter(
    (workflow) => !workflow.isArchived && (isUsedType ? workflow.isUsed : true)
  );

  if (!data || data?.length === 0) {
    return <EmptyState type={type} />;
  }

  return (
    <>
      <Table
        initialPageSize={100}
        autoSize={true}
        cellPadding={false}
        columns={[
          {
            accessorKey: 'name',
            header: formatMessage({ defaultMessage: 'Name', id: 'HAlOn1' }),
            enableSorting: true,
            cell: ({ row }) => (
              <Tooltip
                arrow
                placement="top"
                title={row.original.name}
                isDisabled={row.original.name.length < 32} // this number is arbitrary but it works on my machine yoloshipit
              >
                <div>
                  <CellLink
                    id={row.original.id}
                    className="sticky max-w-[14rem] font-medium text-slate-700 text-sm group-hover/row:underline lg:max-w-[22rem] xl:max-w-[28rem]"
                  >
                    <div className="flex items-center gap-2">
                      <span className="truncate">{row.original.name}</span>
                      <span className="hover:no-underline">
                        {row.original.definition.nodes.every(
                          (nd) => nd.data.isValid
                        ) ? null : (
                          <Chip color="yellow">Draft</Chip>
                        )}
                      </span>
                    </div>
                  </CellLink>
                </div>
              </Tooltip>
            ),
          },
          {
            accessorKey: 'steps',
            enableSorting: false,
            header: formatMessage({ defaultMessage: 'Steps', id: 'huCHmk' }),
            cell: ({ row }) => (
              <CellLink id={row.original.id}>
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage
                      defaultMessage="{count} steps"
                      values={{ count: row.original.definition.nodes.length }}
                      id="2GQj+0"
                    />
                  }
                >
                  <div className="inline-flex rounded-full border border-slate-200 bg-white px-2 py-0.5 font-medium text-slate-500 text-xs">
                    {row.original.definition.nodes.length}
                  </div>
                </Tooltip>
              </CellLink>
            ),
          },
          {
            accessorKey: 'owner',
            header: formatMessage({ defaultMessage: 'Owner', id: 'zINlao' }),
            enableSorting: true,
            cell: ({ row }) => (
              <CellLink id={row.original.id}>
                <span className="flex shrink-0 items-center gap-2 text-slate-500 text-sm">
                  <Avatar
                    name={row.original.createdBy.displayName}
                    src={row.original.createdBy.avatarUrl}
                    size="sm"
                  />
                  {row.original.createdBy.displayName}
                </span>
              </CellLink>
            ),
          },
          {
            accessorKey: 'triggerType',
            header: formatMessage({
              defaultMessage: 'Trigger type',
              id: '6Vngei',
            }),
            enableSorting: true,
            size: 100,
            maxSize: 150,
            cell: ({ row }) => (
              <CellLink id={row.original.id}>
                <div className="inline-flex rounded-full border border-slate-200 bg-white px-2 py-0.5 font-medium text-slate-500 text-xs">
                  {triggerTypes.get(row.original.triggerType)}
                </div>
              </CellLink>
            ),
          },
          {
            accessorKey: 'integrations',
            enableSorting: true,
            header: formatMessage({
              defaultMessage: 'Integrations',
              id: '6cBDhe',
            }),
            cell: ({ row }) => (
              <CellLink id={row.original.id}>
                <div className="flex gap-2">
                  {row.original.integrations.map((intg) => (
                    <Chip key={intg} color="slate">
                      {AllIntegrations.find((ii) => ii.id === intg)?.name ??
                        intg}
                    </Chip>
                  ))}
                </div>
              </CellLink>
            ),
          },
          {
            id: 'actions',
            meta: {
              stopEventPropagation: true,
              align: 'right',
            },
            cell: ({ row }) => (
              <div className="sticky inline-flex items-center justify-end gap-x-3 pr-2">
                <Tooltip
                  arrow
                  placement="top"
                  title={
                    <FormattedMessage defaultMessage="History" id="djJp6c" />
                  }
                >
                  <Button
                    variant="icon"
                    size="small"
                    onClick={() => {
                      trackWorkflowHistoryOpened({ workflowId: row.id });
                      navigate(`/workflows/${row.original.id}/activity`);
                    }}
                  >
                    <History size="1rem" />
                  </Button>
                </Tooltip>
                <DuplicateWorkflowButton id={row.original.id} variant="icon" />
                <Menu>
                  <Menu.Trigger>
                    <Button variant="naked">
                      <EllipsisVertical size="1rem" />
                    </Button>
                  </Menu.Trigger>

                  {canPublish &&
                  row.original.canModify &&
                  !row.original.tactiq ? (
                    <Menu.Item
                      icon={<LandPlot size="1rem" />}
                      className="text-green-500"
                      onClick={() => {
                        setPublishId(row.original.id);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Publish Workflow"
                        id="KRbN+y"
                      />
                    </Menu.Item>
                  ) : null}

                  {isUsedType ? (
                    <Menu.Item
                      icon={<X size="1rem" />}
                      className="text-red-500"
                      onClick={() => {
                        setRemoveId(row.original.id);
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="Stop using"
                        id="RIceT3"
                      />
                    </Menu.Item>
                  ) : (
                    <Menu.Item
                      icon={<TrashIcon size="1rem" />}
                      className="text-red-500"
                      onClick={() => {
                        setArchiveId(row.original.id);
                      }}
                      disabled={!row.original.canModify || row.original.tactiq}
                    >
                      <FormattedMessage
                        defaultMessage="Delete Workflow"
                        id="LzXP/m"
                      />
                    </Menu.Item>
                  )}
                </Menu>
              </div>
            ),
          },
        ]}
        data={data}
      />

      {archiveId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage defaultMessage="Delete workflow" id="RPw47l" />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to delete this workflow?"
              id="dqGjs3"
            />
          }
          yes={<FormattedMessage defaultMessage="Delete" id="K3r6DQ" />}
          yesProps={{ loading: archive.loading, color: 'error' }}
          onYes={async () => {
            await archive.request({
              input: { id: archiveId, isArchived: true },
            });
            setArchiveId(null);
          }}
          onNo={() => setArchiveId(null)}
        />
      )}

      {removeId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage
              defaultMessage="Stop using workflow"
              id="N9eLq8"
            />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to stop using this workflow?"
              id="549m56"
            />
          }
          yes={<FormattedMessage defaultMessage="Yes" id="a5msuh" />}
          yesProps={{ loading: stopUsing.loading, color: 'error' }}
          onYes={async () => {
            await stopUsing.request({
              input: { id: removeId },
            });
            setRemoveId(null);
          }}
          onNo={() => setRemoveId(null)}
        />
      )}

      {publishId && (
        <ConfirmActionDialog
          open
          title={
            <FormattedMessage defaultMessage="Publish workflow" id="ZaAaO9" />
          }
          text={
            <FormattedMessage
              defaultMessage="Are you sure you want to publish this workflow?"
              id="8BiPWd"
            />
          }
          yes={<FormattedMessage defaultMessage="Yes" id="a5msuh" />}
          yesProps={{ loading: publish.loading, color: 'error' }}
          onYes={async () => {
            await publish.request({
              input: { id: publishId },
            });
            setPublishId(null);
          }}
          onNo={() => setPublishId(null)}
        />
      )}
    </>
  );
}

function EmptyState(props: { type: WorkflowsType }) {
  const { formatMessage } = useIntl();
  const { type } = props;
  let title;
  let cta;
  if (type === WorkflowsType.USED_TEAM) {
    title = formatMessage({
      defaultMessage: 'Discover workflows from your team',
      id: 'DShQqI',
    });
    cta = <DiscoverButton type={WorkflowsType.USED_TEAM} variant="filled" />;
  } else if (type === WorkflowsType.USED_TACTIQ) {
    title = formatMessage({
      defaultMessage: 'Discover workflows from Tactiq',
      id: 'iz7RGl',
    });
    cta = <DiscoverButton type={WorkflowsType.USED_TACTIQ} variant="filled" />;
  } else {
    title = formatMessage({
      defaultMessage: 'Create your first workflow',
      id: 'LhWBrL',
    });
    cta = (
      <div className="flex flex-row gap-4">
        <WatchWorkflowIntroVideoButton source="my workflows empty state" />
        <CreateWorkflowButton />
      </div>
    );
  }
  return (
    <div className="mx-auto my-16 max-w-2xl">
      <DeadEnd
        title={title}
        description={formatMessage({
          defaultMessage:
            'Streamline your after meeting workflow by automating tasks. Use Tactiq Al prompts and connect with third-party apps to setup triggers and actions',
          id: 'uW0puE',
        })}
        action={cta}
      />
    </div>
  );
}

function CreateWorkflowButton({
  exploreDialogOpen = false,
}: {
  exploreDialogOpen?: boolean;
}) {
  const [open, setOpen] = useState(exploreDialogOpen);
  return (
    <>
      <WorkflowDiscoveryModal open={open} setOpen={setOpen} />
      <Button
        variant="neutral-secondary"
        onClick={() => {
          trackWorkflowExploreTemplatesButton();
          setOpen(true);
        }}
        size="small"
      >
        <FormattedMessage defaultMessage="Explore templates" id="1ODiI7" />
      </Button>
      <Button
        startIcon={<Plus size="1rem" />}
        onClick={() => setOpen(true)}
        size="small"
      >
        <FormattedMessage defaultMessage="New workflow" id="GIHRKG" />
      </Button>
    </>
  );
}

function DiscoverButton(props: {
  type: WorkflowsType.USED_TEAM | WorkflowsType.USED_TACTIQ;
  variant?: 'filled' | 'neutral-secondary';
}) {
  const { type, variant } = props;
  const [open, setOpen] = useState(false);
  let defaultType: WorkflowsType.TEAM | WorkflowsType.TACTIQ;
  if (type === WorkflowsType.USED_TEAM) {
    defaultType = WorkflowsType.TEAM;
  } else {
    defaultType = WorkflowsType.TACTIQ;
  }
  return (
    <>
      <WorkflowDiscoveryModal
        open={open}
        setOpen={setOpen}
        defaultType={defaultType}
      />
      <Button
        variant={variant ?? 'neutral-secondary'}
        startIcon={<CompassIcon size="1rem" />}
        onClick={() => setOpen(true)}
        size="small"
      >
        <FormattedMessage defaultMessage="Discover more" id="n9Vbqi" />
      </Button>
    </>
  );
}

function ProvideFeedbackButton() {
  return (
    <Button
      href="https://tactiq.typeform.com/ai-workflow"
      target="_blank"
      variant="neutral-secondary"
      size="small"
      onClick={() => trackWorkflowProvideFeedbackButton()}
    >
      <FormattedMessage defaultMessage="Provide feedback" id="GdjHV6" />
    </Button>
  );
}

function CellLink({
  id,
  children,
  className,
}: {
  id: string;
  children: ReactNode;
  className?: string;
}) {
  return (
    <Link
      className={cx('block', cellPaddingClasses, className)}
      to={`/workflows/${id}`}
      onClick={() => {
        trackWorkflowEditorOpened({ workflowId: id });
      }}
    >
      {children}
    </Link>
  );
}
