import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { Archive } from 'lucide-react';
import { useArchiveMeeting } from '../actions/useArchiveMeeting';
import { DeleteDialog } from '../card/DeleteDialog';
import { selectTeam } from '../../../redux/selectors';
import { useSelector } from 'react-redux';
import { trackBulkArchive } from '../../../helpers/analytics';

export const BulkActionsDrawer: React.FC<{
  meetingsSelected: string[];
  onCompleted: () => void;
}> = ({ meetingsSelected, onCompleted }) => {
  const [isDeleteDialogOpen, setDeleteDialogOpen] =
    React.useState<boolean>(false);

  const archiveMeetings = useArchiveMeeting({
    meetingIds: meetingsSelected,
    fullSize: false,
    onCompleted: onCompleted,
  });

  const team_id = useSelector(selectTeam)?.id;

  return (
    <div className="fixed bottom-1 left-1/2 animate-slideUp rounded-lg bg-slate-1000 p-4 text-white motion-reduce:animate-none ">
      <div className="flex flex-row items-center gap-3">
        <p>
          {meetingsSelected.length}{' '}
          {meetingsSelected.length === 1 ? (
            <FormattedMessage defaultMessage="meeting selected" />
          ) : (
            <FormattedMessage defaultMessage="meetings selected" />
          )}
        </p>
        <Button
          type="button"
          variant="filled"
          size="small"
          startIcon={<Archive />}
          onClick={() => setDeleteDialogOpen(true)}
        >
          <FormattedMessage defaultMessage="Archive All" />
        </Button>
      </div>

      {isDeleteDialogOpen ? (
        <DeleteDialog
          open
          onClose={() => setDeleteDialogOpen(false)}
          onDelete={() => {
            trackBulkArchive(team_id);
            archiveMeetings.trigger(false);
          }}
          isArchived={false}
          bulkAction
          loading={archiveMeetings.loading}
        />
      ) : null}
    </div>
  );
};
