import { useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from '../../components/buttons';
import {
  JoinWaitlistDocument,
  UserIntegrationConnection,
} from '../../graphql/operations';
import { IntegrationImplementation } from '../../models/integration';
import { RootState } from '../../redux/store';
import { IntegrationIconClasses } from './IntegrationComponents';
import { Plug2, Circle } from 'lucide-react';

const IntegrationRowClasses =
  'p-4 flex flex-col items-start gap-y-5 cursor-pointer transition-colors bg-white border border-slate-200 hover:border-slate-400 duration-300 rounded-card ';

const IntegrationInfoClasses = 'flex-1 flex flex-col';

const IntegrationTitleClasses =
  'text-slate-800 text-lg font-semibold tracking-tight leading-6 flex items-center flex-1';

/**
 * Integration List items
 * @param {unknown} param0 params
 * @param {IntegrationImplementation} param0.integration integration
 * @param {UserIntegrationConnection[]} param0.userConnections user connections
 * @returns {React.FC} component
 */
export const IntegrationListItem: React.FC<{
  integration: IntegrationImplementation;
  userConnections: UserIntegrationConnection[];
}> = ({ integration, userConnections }) => {
  const navigate = useNavigate();
  const waitlists = useSelector((state: RootState) => state.user.waitlists);
  const integrationsState = useSelector(
    (state: RootState) => state.global.integrations
  );
  const hasGoogleCalendar = useSelector(
    (state: RootState) => state.user.hasGoogleCalendar
  );
  const intl = useIntl();

  const [joinWaitlist] = useMutation(JoinWaitlistDocument);

  const isConnected = integration.isConnected
    ? integration.isConnected({
        ...integrationsState,
        hasGoogleCalendar,
        userConnections,
      })
    : userConnections.find((x) =>
        integration.connectionFilter
          ? integration.connectionFilter(x)
          : x.type === integration.id
      );
  const cta = isConnected ? (
    <div className="flex items-center gap-1.5 rounded-md bg-emerald-100/50 px-2 py-1.5 text-emerald-600">
      <div className="relative h-2 w-2">
        <Circle
          className="absolute h-2 w-2 animate-ping fill-emerald-400"
          strokeWidth={0}
        />
        <Circle className="relative h-2 w-2 fill-emerald-500" strokeWidth={0} />
      </div>
      <p className="font-medium text-xs">Connected</p>
    </div>
  ) : (
    <Button
      size="small"
      variant="neutral-secondary"
      className="font-semibold text-xs"
      startIcon={
        <Plug2 strokeWidth={2} className="-rotate-45 h-4 w-4 text-slate-600" />
      }
    >
      <FormattedMessage
        defaultMessage="Connect"
        id="7qAAhx"
        description="Connect integration button title."
      />
    </Button>
  );

  return (
    <div
      className={IntegrationRowClasses}
      onClick={async () => {
        if (integration.waitlist && !isConnected) {
          if (waitlists.find((x) => x === integration.id)) {
            enqueueSnackbar(
              intl.formatMessage({
                defaultMessage:
                  "You are already on the waitlist. We'll notify you when this feature becomes available.",
                id: 'XBqiz8',
                description: 'Integration waitlist message',
              }),
              { variant: 'SUCCESS' }
            );
          } else {
            await joinWaitlist({
              variables: { integrationId: integration.id },
            });
          }
        } else {
          navigate(`/integrations/${integration.id}`);
        }
      }}
    >
      <div className="flex flex-col gap-y-3">
        <div className="flex gap-3">
          <div className={IntegrationIconClasses}>
            {typeof integration.icon === 'string' ? (
              <img src={integration.icon} alt={`${integration.title} logo`} />
            ) : (
              <integration.icon />
            )}
          </div>
          <div className={IntegrationInfoClasses}>
            <div className={IntegrationTitleClasses}>
              <p>{integration.title}</p>
            </div>
          </div>
        </div>
      </div>

      {integration.waitlist && !isConnected ? (
        waitlists.find((x) => x === integration.id) ? (
          <div className=" flex items-center gap-1.5 rounded-md bg-amber-100/50 px-2 py-1.5 text-amber-600">
            <div className="relative h-2 w-2">
              <Circle
                className="absolute h-2 w-2 animate-ping fill-amber-400 duration-[2000ms]"
                strokeWidth={0}
              />
              <Circle
                className="relative h-2 w-2 fill-amber-500"
                strokeWidth={0}
              />
            </div>
            <p className="font-medium text-xs">
              <FormattedMessage
                defaultMessage="In waitlist"
                description="In waitlist tag text"
              />
            </p>
          </div>
        ) : (
          <Button
            size="small"
            variant="neutral-secondary"
            className=" font-semibold text-xs"
          >
            <FormattedMessage
              defaultMessage="Join {waitlist}"
              id="UAM18N"
              description="Join waitlist button title"
              values={{
                waitlist: integration.connector ? (
                  <FormattedMessage
                    defaultMessage="beta"
                    description="Join waitlist button title"
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage="waitlist"
                    description="Join waitlist button title"
                  />
                ),
              }}
            />
          </Button>
        )
      ) : (
        cta
      )}
    </div>
  );
};
