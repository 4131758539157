import React from 'react';
import { cx } from '../../../../helpers/utils';

const tailwindWidthClasses = [
  'w-4',
  'w-5',
  'w-6',
  'w-7',
  'w-8',
  'w-9',
  'w-10',
  'w-11',
  'w-12',
  'w-14',
  'w-16',
  'w-20',
  'w-24',
  'w-28',
  'w-32',
  'w-36',
  'w-40',
];

const randomWidth = () => {
  return tailwindWidthClasses[
    Math.floor(Math.random() * tailwindWidthClasses.length) // NOSONAR
  ];
};

const Skeleton: React.FC = () => {
  return (
    <div className="flex h-32 grow items-center justify-between gap-4 rounded bg-slate-100 p-4">
      <div className="motion-safe:animate-pulse">
        <div className="size-10 rounded-full bg-slate-200" />
      </div>
      <div className="flex max-h-full flex-col gap-2 overflow-hidden motion-safe:animate-pulse">
        <div className="h-4 w-36 rounded bg-slate-200" />
        <div className="flex flex-wrap gap-x-1 gap-y-2">
          {Array.from({ length: 30 }).map((_, index) => (
            <div
              key={`skeleton-${index}`}
              className={cx('h-3 rounded-md bg-slate-200', randomWidth())}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const TranscriptSkeleton: React.FC = () => {
  return (
    <div className="mt-3 ml-1">
      {/* AI Prompts */}
      <div className="mb-6 flex flex-col gap-3">
        <div className="h-8 w-3/4 rounded bg-slate-200" />
        <div className="grid grid-cols-2 grid-rows-3 gap-2">
          <div className="h-10 w-full rounded bg-slate-200" />
          <div className="h-10 w-full rounded bg-slate-200" />
          <div className="h-10 w-full rounded bg-slate-200" />
          <div className="h-10 w-full rounded bg-slate-200" />
          <div className="h-10 w-full rounded bg-slate-200" />
          <div className="h-10 w-full rounded bg-slate-200" />
        </div>
        <div className="fled-row flex gap-2">
          <div className="flex h-8 w-full items-center rounded border border-slate-200" />
          <div className="h-8 w-28 rounded bg-slate-200" />
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="h-8 w-1/2 rounded bg-slate-200" />
        <div className="flex w-full flex-col gap-4 overflow-clip">
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={`transcript-skeleton-${index}`} />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TranscriptListSkeleton: React.FC = () => {
  return (
    <div className="mt-3">
      <div className="flex w-full flex-col gap-4 overflow-clip">
        {Array.from({ length: 6 }).map((_, index) => (
          <div
            className="flex h-32 grow items-center justify-between gap-4 rounded bg-slate-100 p-4"
            key={`transcript-skeleton-list-${index}`}
          >
            <div className="flex flex-col items-center justify-center gap-2 motion-safe:animate-pulse">
              <div className="h-10 w-6 rounded bg-slate-200" />
              <div className="h-6 w-10 rounded bg-slate-200" />
            </div>
            <div className="flex h-full grow flex-col gap-2 overflow-hidden motion-safe:animate-pulse">
              <div className="h-4 w-36 rounded bg-slate-200" />
              <div className="flex w-full flex-nowrap gap-x-1 gap-y-2 overflow-hidden">
                {Array.from({ length: 8 }).map((_, index) => (
                  <div
                    key={`transcript-skeleton-list-text-${index}`}
                    className={cx('h-3 rounded-md bg-slate-200', randomWidth())}
                  ></div>
                ))}
              </div>
              <div className="flex flex-row gap-2">
                <div className="h-5 w-10 rounded bg-slate-200" />
                <div className="h-5 w-10 rounded bg-slate-200" />
                <div className="h-5 w-10 rounded bg-slate-200" />
                <div className="h-5 w-10 rounded bg-slate-200" />
              </div>
            </div>
            <div className="flex flex-row items-center justify-center gap-4 motion-safe:animate-pulse">
              <div className="size-8 rounded bg-slate-200" />
              <div className="size-8 rounded bg-slate-200" />
              <div className="size-8 rounded bg-slate-200" />
              <div className="size-8 rounded bg-slate-200" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
