import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/buttons';
import { requestMeetingAccess } from '../../../../helpers/api';
import { selectUserEmail } from '../../../../redux/selectors';
import { forceSignOut } from '../../../../helpers/authentication';

/**
 * Unauthorized meeting view
 */
export const MeetingUnauthorized: React.FC<{ meetingId: string }> = ({
  meetingId,
}) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = React.useState(false);
  const email = useSelector(selectUserEmail);
  const onRequestAccess = useCallback(async () => {
    setIsLoading(true);

    await requestMeetingAccess(meetingId);

    setIsLoading(false);
    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage:
          'We notified the owner of the meeting, you will get an email when the access is granted.',
        description: 'Message when the user has requested access to a meeting',
        id: 'T60F38',
      }),
      { variant: 'SUCCESS' }
    );
  }, [intl, meetingId]);

  return (
    <div className="mx-auto flex flex-col gap-3 pt-6">
      <h1 className="text-3xl">
        <FormattedMessage
          defaultMessage="🔒 Oh no!"
          id="x2HPs0"
          description="Unauthorized meeting view. Title"
        />
      </h1>
      <div>
        <FormattedMessage
          defaultMessage="Looks like you don't have permissions to see this meeting."
          id="QCfVcP"
          description="Unauthorized meeting view. Description"
        />
        <br />
        {email && (
          <FormattedMessage
            defaultMessage="You are logged-in as {email}"
            id="zSkF0p"
            description="Unauthorized meeting view. Logged in account message"
            values={{ email: <strong>{email}</strong> }}
          />
        )}
      </div>
      <div className="flex items-center gap-2">
        <Button loading={isLoading} onClick={onRequestAccess}>
          <FormattedMessage
            defaultMessage="Request access"
            description="Unauthorized meeting view. Request access button label"
            id="CIJ+s7"
          />
        </Button>
        <FormattedMessage defaultMessage="or" id="Ntjkqd" />
        <Button
          variant="neutral-secondary"
          onClick={async () => {
            return forceSignOut('unauthorized');
          }}
        >
          <FormattedMessage
            defaultMessage="Sign-in into another account"
            description="Unauthorized meeting view. Sign-in button label"
            id="DIfJhx"
          />
        </Button>
      </div>
    </div>
  );
};
