import { logger } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAsyncEffect } from 'use-async-effect';
import { trackWebEvent } from '../../../helpers/analytics';
import {
  AtlassianSite,
  ConfluencePage,
  createConfluencePage,
  getAccessibleAtlassianSites,
  searchConfluencePages,
} from '../../../helpers/api';
import { APIError, InvalidGrantError } from '../../../helpers/error';
import icon from '../../../img/confluence.png';
import {
  IntegrationImplementation,
  IntegrationShare,
  ShareDialogProps,
} from '../../../models/integration';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';
import { createOAuthConnector } from './connector';
import {
  ShareDialogFormClasses,
  ShareDialogListWrapperClasses,
  ShareDialogWrapperClasses,
} from './styles';
import { deleteConnection } from '../../../helpers/api/integrations/connection';
import { ExternalLink, Search } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';
import { Tooltip } from '../../../components/Tooltip';
import { Button } from '../../../components/buttons';
import { Avatar } from '../../../components/Avatar';

const ShareDialog: React.FC<ShareDialogProps> = (props) => {
  const { connection, share } = props;
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSite, setSelectedSite] = useState<AtlassianSite | null>(null);
  const [sites, setSites] = useState<AtlassianSite[]>([]);
  const [pages, setPages] = useState<ConfluencePage[]>([]);
  const intl = useIntl();

  const handleSearch = async (cloudId: string) => {
    const result = await searchConfluencePages({
      query,
      connectionKey: connection.connectionKey,
      cloudId,
    });
    setPages(result.pages);
  };

  const getAccessibleSites = async () => {
    try {
      const result = await getAccessibleAtlassianSites(
        connection.connectionKey
      );
      setSites(result);

      if (result.length === 1) {
        setSelectedSite(result[0]);
        await handleSearch(result[0].id);
      }
    } catch (err) {
      if (err instanceof InvalidGrantError) {
        enqueueSnackbar(
          intl.formatMessage({
            defaultMessage:
              'Confluence access token is expired or invalid, please try to connect again',
            description:
              'Invalid Confluence grant error message. Toast warn messsage.',
            id: 'tARw+C',
          }),
          { variant: 'WARNING' }
        );
        await deleteConnection(connection.connectionKey);
        trackWebEvent('Integration - Delete', {
          type: connection.type,
        });
      }

      throw err;
    }
  };

  useAsyncEffect(async () => {
    if (selectedSite) {
      await handleSearch(selectedSite.id);
    } else {
      await getAccessibleSites();
    }

    setIsLoading(false);
    // take `query` out of deps because loading is controlled by isLoading flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, isLoading]);

  let content = null;

  if (isLoading) {
    content = (
      <li>
        <LoadingContent />
      </li>
    );
  } else if (!selectedSite) {
    content = sites.map((site) => (
      <li key={site.id}>
        <Button
          key={site.id}
          className="w-full"
          variant="naked"
          onClick={() => {
            trackWebEvent('Confluence integration - site selected');
            setSelectedSite(site);
          }}
          startIcon={<Avatar src={site.avatarUrl} name={site.name} />}
        >
          {site.name}
        </Button>
      </li>
    ));
  } else if (pages.length) {
    content = pages.map((page) => (
      <li key={page.id} className="full flex items-center justify-between">
        <Button
          variant="naked"
          className="w-full"
          onClick={() => {
            trackWebEvent('Confluence integration - page selected');
            share(integration, connection, {
              parentId: page.id,
              parentName: page.title,
              spaceKey: page.space.key,
              cloudId: selectedSite.id,
            }).catch(logger.error);
          }}
        >
          {page.space.name} - {page.title}
        </Button>
        <Tooltip
          title={
            <FormattedMessage
              defaultMessage="Open in Confluence"
              id="pbavPs"
              description="Confluence integration. Open page in Confluence link button text."
            />
          }
        >
          <Button
            variant="icon"
            size="small"
            href={page.link}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
          >
            <ExternalLink />
          </Button>
        </Tooltip>
      </li>
    ));
  } else {
    content = (
      <li>
        <FormattedMessage
          defaultMessage="Use search field to find a page to share under."
          id="ciM2ex"
          description="Confluence integration. Empty search query placeholder."
        />
      </li>
    );
  }

  return (
    <div className={ShareDialogWrapperClasses}>
      <div>
        <FormattedMessage
          defaultMessage="Please select parent page. Your new page will be created as its child."
          description="Confluence integration. Select pages message."
          id="Ab4o8M"
        />
      </div>
      <form
        className={ShareDialogFormClasses}
        onSubmit={(event) => {
          setIsLoading(true);

          event.preventDefault();
        }}
      >
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Search Confluence pages',
            id: '8sLtd5',
            description:
              'Confluence integration. Search pages input placeholder.',
          })}
          inputProps={{
            'aria-label': intl.formatMessage({
              defaultMessage: 'search Confluence pages',
              id: 'BmxCXM',
              description:
                'Confluence integration. Search pages input aria-label.',
            }),
            name: 'query',
          }}
          disabled={isLoading}
          value={query}
          onChange={setQuery}
        />
        <Button
          variant="icon"
          type="submit"
          aria-label={intl.formatMessage({
            defaultMessage: 'Search',
            id: 'ur76cF',
            description:
              'Confluence integration. Search button icon aria-label.',
          })}
        >
          <Search className="h-6 w-6" />
        </Button>
      </form>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <ul className={ShareDialogListWrapperClasses}>{content}</ul>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  try {
    const result = await createConfluencePage({
      connectionKey: connection.connectionKey,
      parentId: options.parentId,
      spaceKey: options.spaceKey,
      cloudId: options.cloudId,
      meetingId: options.meetingId,
      title: options.title,
      rawTranscript: options.rawTranscript,
      shareSettings: {
        isSharingDetails: options.isSharingDetails,
        isSharingHighlights: options.isSharingHighlights,
        isSharingNotes: options.isSharingNotes,
        isSharingTranscript: options.isSharingTranscript,
      },
    });

    return { link: result.link };
  } catch (err) {
    if (
      err instanceof APIError &&
      err.consumerMessage.includes(
        'A page with this title already exists in the space'
      )
    ) {
      trackWebEvent('Confluence integration - Page already exists error');
      enqueueSnackbar('A page with this title already exists in the space', {
        variant: 'WARNING',
      });
      throw err;
    } else {
      throw err;
    }
  }
};

export const integration: IntegrationImplementation = {
  id: 'atlassian',
  title: 'Confluence',
  icon,
  description: () => (
    <div className="text-slate-500">
      <FormattedMessage
        defaultMessage="Store your transcripts and share your highlights to Confluence"
        id="JQbqgk"
        description="Confluence integration. Description"
      />
    </div>
  ),
  connector: createOAuthConnector('atlassian', 'Confluence'),
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Share transcript"
      description="Confluence integration. Share description"
      id="gU+RwY"
    />
  ),
  share: shareImpl,
  ShareDialog,
  getSharingDestinationTitle: ({ parentName }) => {
    return parentName;
  },
  helpUrl:
    'https://help.tactiq.io/en/articles/5860993-how-to-save-transcript-in-confluence',
};

export default integration;
