import { createContext, useContext } from 'react';

export const EdgeStateContext = createContext<{
  hoveredEdgeId: string | null;
  reconnectingEdgeId: string | null;
}>({
  hoveredEdgeId: null,
  reconnectingEdgeId: null,
});
export const useEdgesState = () => useContext(EdgeStateContext);
export const EdgeStateProvider = EdgeStateContext.Provider;
