import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Switch } from '../../../components/buttons/Switch';
import icon from '../../../img/logo-128.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import {
  selectUserEmail,
  selectUserName,
  selectUserSettings,
} from '../../../redux/selectors';

const Description: React.FC = () => {
  const userEmail = useSelector(selectUserEmail);
  const userName = useSelector(selectUserName);

  return (
    <div className="flex flex-col gap-4 text-slate-600">
      <FormattedMessage
        defaultMessage="Automatically save your transcripts to Tactiq."
        description="Tactiq Storage. Description."
        id="eHit+4"
      />

      <div className="flex text-slate-500 text-sm">
        <FormattedMessage
          defaultMessage="Logged in as {loggedInAs}"
          id="6tXcUu"
          description="Tactiq logged in as"
          values={{
            loggedInAs: (
              <div className="font-medium text-slate-800">
                {userName} ({userEmail})
              </div>
            ),
          }}
        />
      </div>

      <h2 className="mt-4 font-semibold text-slate-800 text-xl">
        <FormattedMessage
          defaultMessage="Settings"
          id="L4vIki"
          description="Tactiq Settings title"
        />
      </h2>

      <AutomaticallySaveTranscriptsToTactiqSetting />
    </div>
  );
};

const AutomaticallySaveTranscriptsToTactiqSetting: React.FC = () => {
  const settings = useSelector(selectUserSettings);
  const intl = useIntl();

  if (settings.storage?.__typename !== 'UserSettingsStorageV2') return null;
  if (!settings.storage?.providers) return null;

  const setting = settings.storage.providers;

  return (
    <div className="flex md:max-w-2xl">
      <div className="flex-1 text-slate-500">
        <FormattedMessage
          defaultMessage="Automatically save transcripts to Tactiq during the meeting"
          id="UadtTl"
          description="Automatically save transcripts to Tactiq setting"
        />
      </div>

      <Switch
        isOn={setting.Tactiq.enabled}
        onClick={() => {
          enqueueSnackbar(
            intl.formatMessage({
              defaultMessage:
                'This feature is available for enterprise customers. Please reach out to support team for assistance!',
              id: 'Qq1FHP',
            }),
            { variant: 'WARNING' }
          );
        }}
      />
    </div>
  );
};

const integration: IntegrationImplementation = {
  id: 'tactiq',
  category: IntegrationCategory.Storage,
  order: 1,
  title: 'Tactiq',
  icon,
  description: Description,
  connector: () => <></>,
  isConnected: () => true,
  hidden: false,
};

export default integration;
