import React from 'react';
import { FormOverride } from './JsonForm';
import { FormattedMessage } from 'react-intl';
import { GoogleDriveFolderSelect } from './GoogleDriveFolderSelect';
import { Tooltip } from '../../components/Tooltip';
import { HelpCircle } from 'lucide-react';

const linear: FormOverride = {
  config: [
    {
      path: 'team_id',
      label: () => <FormattedMessage defaultMessage="Team" />,
    },
  ],
  parameters: [
    {
      path: 'title',
      label: () => <FormattedMessage defaultMessage="Title" />,
    },
    {
      path: 'description',
      label: () => <FormattedMessage defaultMessage="Description" />,
    },
  ],
};

const notion: FormOverride = {
  config: [
    {
      path: 'database_id',
      label: () => <FormattedMessage defaultMessage="Database" />,
    },
  ],
  parameters: [
    {
      path: 'children',
      label: () => <FormattedMessage defaultMessage="Page content" />,
    },
    {
      path: 'properties',
      recursive: true,
      label: () => <FormattedMessage defaultMessage="Database" />,
    },
  ],
};

const slack: FormOverride = {
  config: [
    {
      path: 'conversationId',
      label: () => <FormattedMessage defaultMessage="Channel" />,
    },
  ],
  parameters: [
    {
      path: 'text',
      label: () => <FormattedMessage defaultMessage="Message" />,
    },
  ],
};

const hubspot: FormOverride = {
  parameters: [
    {
      path: 'hs_meeting_body',
      label: () => <FormattedMessage defaultMessage="Meeting Notes" />,
    },
  ],
};

const pipedrive: FormOverride = {
  parameters: [
    {
      path: 'note',
      label: () => <FormattedMessage defaultMessage="Meeting Notes" />,
    },
  ],
};

const workable: FormOverride = {
  parameters: [
    {
      path: 'comment.body',
      label: () => <FormattedMessage defaultMessage="Comment" />,
    },
  ],
};

const googledrive: FormOverride = {
  defaultFieldData: {
    parents: [],
    name: '{{meeting.title}}',
  },
  parameters: [
    {
      path: 'parents',
      label: () => <FormattedMessage defaultMessage="Folder" />,
      Component: GoogleDriveFolderSelect,
    },
    {
      path: 'name',
      label: () => (
        <Tooltip
          title={
            <FormattedMessage defaultMessage="The name of the file in your Google Drive. Warning: Using the name of an existing file will override its contents." />
          }
        >
          <div className="flex items-center gap-1">
            <FormattedMessage defaultMessage="Filename" />
            <HelpCircle size="1rem" />
          </div>
        </Tooltip>
      ),
    },
    {
      path: 'content',
      label: () => <FormattedMessage defaultMessage="Meeting Notes" />,
    },
  ],
};

// NOTE: Fixed parameters should be added in processFieldConversions function in backend
// should only add parameters that customers will interact with
export const JsonFormOverrides: Record<string, FormOverride | undefined> = {
  linear,
  notion,
  slack,
  hubspot,
  pipedrive,
  workable,
  googledrive,
};
