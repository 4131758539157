import React from 'react';
import { cx } from '../../helpers/utils';

/**
 * A styled checkbox for boolean values
 */
export const Checkbox: React.FC<{
  checked: boolean;
  label: string | React.ReactNode;
  id: string;
  onChange: (checked: boolean) => void;
  hideLabel?: boolean;
  disabled?: boolean;
  /** Optional classname applied to the wrapper div */
  className?: string;
}> = ({
  checked,
  label,
  id,
  onChange,
  hideLabel = false,
  disabled = false,
  className,
}) => {
  return (
    <div
      className={cx(
        'flex items-center',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
    >
      <input
        disabled={disabled}
        onChange={() => onChange(!checked)}
        checked={checked}
        id={id}
        type="checkbox"
        value={checked ? 'on' : 'off'}
        className={cx(
          'form-checkbox relative h-4 w-4 flex-shrink-0 rounded border-slate-300 bg-slate-100 focus:outline-none focus:ring-2 focus:ring-neutral-700',
          disabled
            ? 'cursor-not-allowed text-slate-300'
            : 'cursor-pointer text-neutral-primary',
          hideLabel
            ? 'before:-top-5 before:-right-2 before:absolute before:block before:h-14 before:w-8'
            : ''
        )}
      />
      <label
        htmlFor={id}
        onClick={(e) => e.stopPropagation()}
        className={cx(
          'ms-3 flex-grow text-sm',
          disabled
            ? 'cursor-not-allowed text-slate-300'
            : 'cursor-pointer text-slate-600',
          hideLabel ? 'sr-only' : ''
        )}
      >
        {label}
      </label>
    </div>
  );
};
