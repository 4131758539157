import React, { ReactElement } from 'react';
import { useReactFlow } from '@xyflow/react';
import { WorkflowNodeProps, SingleSourceNode } from './BaseNode';
import {
  useAddableSpaces,
  useCreateSpaceDialog,
} from '../../../services/Space';
import { Select } from '../../../components/Select';
import { ListTodo } from 'lucide-react';
import { useIntl } from 'react-intl';
import { minHeights } from './minHeights';

const CREATE_SPACE = 'create-space';

export function SaveToSpace(
  props: WorkflowNodeProps<{ space: string }>
): ReactElement {
  const addableSpaces = useAddableSpaces();
  const reactFlow = useReactFlow();
  const isExecution = Boolean(props.data.execution);
  const intl = useIntl();
  const showCreateSpaceDialog = useCreateSpaceDialog();

  return (
    <SingleSourceNode
      icon={
        <ListTodo className="size-8 rounded-lg border border-fuchsia-500/10 bg-fuchsia-50 p-1.5 text-fuchsia-500" />
      }
      workflowNode={props}
      noResize
      minHeight={minHeights.SaveToSpace}
    >
      <div className="nodrag">
        <Select
          full
          disabled={isExecution}
          value={props.data.space}
          onChange={(space) => {
            if (space === CREATE_SPACE) {
              showCreateSpaceDialog();
              return;
            }
            reactFlow.updateNodeData(props.id, { space });
          }}
          options={[
            ...addableSpaces.map((ii) => ({
              value: ii.id,
              icon: ii.icon,
              label: ii.name,
            })),
            {
              divider: addableSpaces.length > 0 ? 'top' : undefined,
              value: CREATE_SPACE,
              selectable: false,
              label: intl.formatMessage({
                defaultMessage: 'Create a space',
              }),
            },
          ]}
        />
      </div>
    </SingleSourceNode>
  );
}
