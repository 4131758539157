import React from 'react';
import { CenteredPanel } from '../components/panels/centered';
import { Button } from '../components/buttons';
import { fetchApiV2 } from '../helpers/api/helpers';

export const DesktopAuthLanding: React.FC = function () {
  return (
    <CenteredPanel>
      <div className="flex flex-col items-center gap-4">
        <h1 className="font-bold text-3xl">Welcome to Tactiq</h1>
        <p className="text-center">
          Tactiq is a meeting productivity tool that helps you take notes,
          manage tasks, and track decisions in real-time.
        </p>

        <div className="flex gap-4">
          <Button
            onClick={async () => {
              const result = await fetchApiV2<{ token: string }>(
                '/a/user/custom-token'
              );

              if (result && !('error' in result) && result.token) {
                // redirect to app
                window.location.href = `tactiq://desktop-auth?token=${result.token}`;
                return;
              }
              alert('Error generating token');
            }}
            className="w-70"
          >
            Sign in to Desktop App
          </Button>
        </div>
      </div>
    </CenteredPanel>
  );
};
