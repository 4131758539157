import { Condition } from './Condition';
import { Confirmation } from './Confirmation';
import { CustomPrompt } from './CustomPrompt';
import { RunMeetingKit } from './RunMeetingKit';
import { SaveToSpace } from './SaveToSpace';
import { Share } from './Share';
import { StartNode } from './StartNode';
import { Template } from './Template';
import { SendData } from './SendData';
import { useIntl } from 'react-intl';
import { AddLabels } from './AddLabels';

export const nodeTypes = {
  AddLabels,
  Condition,
  Confirmation,
  CustomPrompt,
  RunMeetingKit,
  SaveToSpace,
  Share,
  StartNode,
  SendData,
  Template,
};

// The node types that can create outputs.
// Stored here so it stays in sync
export const outputNodeTypes = new Set([
  'Confirmation',
  'CustomPrompt',
  'RunMeetingKit',
  'Template',
]);

export type NodeType = keyof typeof nodeTypes;

export const useNodeTypeToName = (): Record<NodeType, string> => {
  const intl = useIntl();

  const Share = intl.formatMessage({
    defaultMessage: 'Share to an integration',
    id: 'XAznvs',
  });
  return {
    SaveToSpace: intl.formatMessage({
      defaultMessage: 'Save to a space',
      id: '09nd4p',
    }),
    AddLabels: intl.formatMessage({
      defaultMessage: 'Add labels',
      id: 'nYQ9sk',
    }),
    RunMeetingKit: intl.formatMessage({
      defaultMessage: 'AI: Use a quick prompt',
      id: 'O6OGiL',
    }),
    CustomPrompt: intl.formatMessage({
      defaultMessage: 'AI: Write your own prompt',
      id: 'Sxqlfd',
    }),
    Share,
    SendData: Share,
    Confirmation: intl.formatMessage({
      defaultMessage: 'Review before proceeding',
      id: 'Vu4qrQ',
    }),
    Condition: intl.formatMessage({
      defaultMessage: 'Condition',
      id: 'XMpO69',
    }),
    StartNode: intl.formatMessage({
      defaultMessage: 'Start the workflow from a meeting',
      id: '4HXNl1',
    }),
    Template: intl.formatMessage({
      defaultMessage: 'Compose text from template',
      id: 'JiN2vp',
    }),
  };
};
