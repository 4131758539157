import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { deleteMeeting } from '../../../graphql/meetings';
import { trackDeleteMeeting } from '../../../helpers/analytics';
import { kTranscripts } from '../../../helpers/routes';
import { deleteMeeting as deleteMeetingAction } from '../../../redux/modules/global';

export const useDeleteMeeting = (options: {
  meetingId: string;
  fullSize: boolean;
  onCompleted?: () => void;
}): {
  trigger: () => void;
  loading: boolean;
} => {
  const { meetingId, fullSize, onCompleted } = options;

  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onDelete = useCallback(async () => {
    trackDeleteMeeting();
    setLoading(true);
    try {
      await deleteMeeting(meetingId);
      dispatch(deleteMeetingAction(meetingId));
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Your meeting was deleted',
          id: 'Dd5k+T',
        }),
        { variant: 'SUCCESS' }
      );
    } catch (error) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Unable to delete the meeting',
          id: '0ywvqv',
        }),
        { variant: 'ERROR' }
      );
    } finally {
      onCompleted?.();
      setLoading(false);
    }

    if (fullSize) {
      navigate(kTranscripts);
    }
  }, [fullSize, meetingId, dispatch, intl, onCompleted, navigate]);

  return {
    trigger: onDelete,
    loading,
  };
};
