import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import {
  NotionPage,
  createNotionPage,
  searchNotionPages,
} from '../../../helpers/api';
import icon from '../../../img/notion.png';
import {
  IntegrationImplementation,
  IntegrationShare,
} from '../../../models/integration';
import { useAsyncEffect } from 'use-async-effect';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';
import { createOAuthConnector } from './connector';
import { File, Search } from 'lucide-react';
import { TextInput } from '../../../components/TextInput';

const Folder: React.FC = () => {
  return (
    <Button
      href="https://notion.so"
      onClick={() => {
        trackWebEvent('Integration - Connection List Action', {
          type: 'notion',
          action: 'open-folder',
        });
        return true;
      }}
    >
      <FormattedMessage
        defaultMessage="Open {productName}"
        id="IuNL3G"
        description="Notion integration. Open link title."
        values={{
          productName: 'Notion',
        }}
      />
    </Button>
  );
};

const ShareDialogWrapperClasses =
  'flex flex-col h-full overflow-hidden max-h-[80vh]';

const ShareDialogFormClasses = 'flex py-0.5 px-1 items-center w-full';

const ShareDialogListWrapperClasses = 'h-full overflow-y-scroll';

const ShareDialog: React.FC<any> = (props) => {
  const { connection, share } = props;
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pages, setPages] = useState<NotionPage[]>([]);
  const intl = useIntl();

  useAsyncEffect(
    async () => {
      if (connection && isLoading) {
        const result = await searchNotionPages({
          query,
          connectionKey: connection.connectionKey,
        });
        setPages(result.pages);
        setIsLoading(false);
      }
      // take `query` out of deps because loading is controlled by isLoading flag
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connection, isLoading]
  );

  const pagesList = pages.length ? (
    <ul>
      {pages.map((x) => (
        <li key={x.id}>
          <Button
            variant="naked"
            className="w-full"
            onClick={() => {
              trackWebEvent('Selected a page in Notion to share under');
              share(integration, connection, {
                parentId: x.id,
                parentName: x.name,
              });
            }}
            startIcon={
              x.icon && x.icon.type === 'emoji' && x.icon.emoji ? (
                <span>{x.icon.emoji}</span>
              ) : (
                <File />
              )
            }
          >
            {x.name}
          </Button>
        </li>
      ))}
    </ul>
  ) : (
    <span>
      <FormattedMessage
        defaultMessage="No pages was found. Try another search term."
        id="OYeI7o"
        description="Notion integration. No pages found message text."
      />
    </span>
  );

  return (
    <div className={ShareDialogWrapperClasses}>
      <div>
        <FormattedMessage
          defaultMessage="Please select parent page. Your new page will be created as its child."
          description="Notion integration. Select pages message."
          id="cFB/WB"
        />
      </div>
      <form
        className={ShareDialogFormClasses}
        onSubmit={(event) => {
          setIsLoading(true);

          event.preventDefault();
        }}
      >
        <TextInput
          placeholder={intl.formatMessage({
            defaultMessage: 'Search Notion pages',
            id: '/X/12H',
            description: 'Notion integration. Search pages input placeholder.',
          })}
          inputProps={{
            'aria-label': intl.formatMessage({
              defaultMessage: 'search Notion pages',
              id: 'IlK+0c',
              description: 'Notion integration. Search pages input aria-label.',
            }),
            name: 'query',
          }}
          disabled={isLoading}
          value={query}
          onChange={setQuery}
        />
        <Button
          variant="icon"
          type="submit"
          aria-label={intl.formatMessage({
            defaultMessage: 'Search',
            id: '1nqjKz',
            description: 'Notion integration. Search button icon aria-label.',
          })}
        >
          <Search className="h-6 w-6" />
        </Button>
      </form>
      {isLoading ? (
        <LoadingContent />
      ) : (
        <div className={ShareDialogListWrapperClasses}>{pagesList}</div>
      )}
    </div>
  );
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const result = await createNotionPage({
    connectionKey: connection.connectionKey,
    parentId: options.parentId,
    meetingId: options.meetingId,
    title: options.title,
    rawTranscript: options.rawTranscript,
    shareSettings: {
      isSharingDetails: options.isSharingDetails,
      isSharingHighlights: options.isSharingHighlights,
      isSharingNotes: options.isSharingNotes,
      isSharingTranscript: options.isSharingTranscript,
    },
  });

  return { link: result.link };
};

export const integration: IntegrationImplementation = {
  id: 'notion',
  title: 'Notion',
  icon,
  description: () => (
    <div className="text-slate-500">
      <FormattedMessage
        defaultMessage="Store your transcripts and share your highlights to Notion.

  (you will need to select at least one page to export your transcripts)"
        id="br0t/O"
        description="Notion integration. Description"
      />
    </div>
  ),
  connector: createOAuthConnector('notion', 'Notion'),
  actions: [Folder],
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Create a page"
      description="Notion integration. Share description"
      id="vjriaJ"
    />
  ),
  share: shareImpl,
  ShareDialog,
  getSharingDestinationTitle: (options) => options.parentName,
  helpUrl:
    'https://help.tactiq.io/en/articles/5492858-tactiq-s-notion-integration',
};

export default integration;
