import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MeetingListHeaderUploading: React.FC = () => {
  return (
    <div className="flex flex-col gap-2 border-slate-200 border-t bg-slate-50 px-8 py-2 text-left font-semibold text-sm sm:flex-row">
      <FormattedMessage defaultMessage="Uploading now" />
    </div>
  );
};
