import React, { useEffect, PropsWithChildren, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Page } from '../../Common/Navigation';
import MeetingUnauthorized, { MeetingNotFound } from './error';
import {
  selectUnauthorizedMeetings,
  selectNotFoundMeetings,
  selectUid,
  createSelectRawTranscriptSelector,
  selectTeam,
} from '../../../redux/selectors';
import { trackWebPage } from '../../../helpers/analytics';
import { watchMeeting, watchTranscript } from '../../../helpers/api';
import { MeetingContent } from './MeetingContent';
import { useFullMeeting } from '../common/meeting-hooks';
import { RootState, TactiqStore } from '../../../redux/store';
import { trackMeetingView } from '../../../graphql/meetings';
import { logger } from '@tactiq/model';
import { getMeeting } from '../../../redux/sagas/getMeeting';
import { fetchTranscript } from '../../../redux/sagas/fetch-meeting';
import { MeetingKitItemOutputType } from '../../../graphql/operations';
import { ApolloClientFactory } from '../../../graphql/client';
import { MeetingSkeleton } from './skeleton/MeetingSkeleton';

const MeetingView: React.FC<
  PropsWithChildren<{
    showShareModal?: boolean;
  }>
> = (props) => {
  const { showShareModal } = props;
  const [loadingState, setLoadingState] = useState<
    'justloaded' | 'triedtogetfrombackend' | 'triedtogetfrombrowser' | 'done'
  >('justloaded');
  const intl = useIntl();

  const { id, key } = useParams() as {
    id: string;
    key: string | undefined;
  };

  const dispatch = useDispatch();
  const store = useStore() as unknown as TactiqStore;
  const meetingId = id;
  const uid = useSelector(selectUid);
  const meeting = useFullMeeting(meetingId);
  const team = useSelector(selectTeam);
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const rawTranscript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );
  const isMeetingLoaded = !!meeting;
  const isUnauthorized = Boolean(
    useSelector(selectUnauthorizedMeetings)[meetingId]
  );
  const isNotFound = Boolean(useSelector(selectNotFoundMeetings)[meetingId]);
  const [client, setClient] =
    useState<(typeof ApolloClientFactory)['_client']>();
  useEffect(() => {
    async function init() {
      const apolloClient = await ApolloClientFactory.getClient();
      setClient(apolloClient);
    }

    init().catch(logger.error);
  });

  // kick-off page data loading
  useEffect(() => {
    if (!meeting || !rawTranscript) {
      getMeeting(store, meetingId, meeting, {
        key,
        force: loadingState === 'justloaded',
      })
        .then(() => {
          setLoadingState('done');
        })
        .catch((e) => {
          logger.error(e);
          setLoadingState('triedtogetfrombackend');
        });
    } else {
      setLoadingState('done');
    }
  }, [store, meeting, meetingId, key, rawTranscript, loadingState]);

  useEffect(() => {
    if (loadingState === 'triedtogetfrombackend' && meeting && !rawTranscript) {
      setLoadingState('triedtogetfrombrowser');
      fetchTranscript(store, meeting).catch((e) => logger.error(e));
    }
  }, [meeting, store, rawTranscript, loadingState]);

  useEffect(() => {
    if (isMeetingLoaded && client) {
      return watchMeeting(client, dispatch, meetingId);
    }
  }, [dispatch, isMeetingLoaded, meetingId, client]);

  useEffect(() => {
    if (isMeetingLoaded) {
      const isOwner = meeting.userId === uid;

      trackWebPage('Meeting Details', {
        team_id: team?.id,
        isPreview: meeting.isPreview ?? false,
        isTutorial: meeting.isTutorial ?? false,
        meetingId: meeting.id,
        platform: meeting.platform,
        access: meeting.access,
        accessType: meeting.accessType,
        hasAi: !!meeting.aiOutputs,
        hasAiPromptOutputs: !!meeting.aiOutputs?.length,
        hasAiActionItems:
          meeting.aiOutputs?.findIndex(
            (aiOutput) =>
              aiOutput.contentType === MeetingKitItemOutputType.ACTION_ITEMS
          ) !== -1,
        isOwner,
      });

      trackMeetingView(meetingId).catch(logger.error);
    }

    // we need to run this hook only once when meeting is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMeetingLoaded]);

  const tactiqMeetingTranscriptLink = meeting?.transcripts?.find(
    (link) => link.__typename === 'TactiqMeetingTranscriptLink'
  )?.externalId;

  useEffect(() => {
    // once we have transcript, we can watch for changes
    // while the extension does not listen for the changes for free users, webapp should auto-update, at least, for now
    if (tactiqMeetingTranscriptLink && client) {
      return watchTranscript(client, meetingId, store);
    }
  }, [meetingId, store, tactiqMeetingTranscriptLink, client]);

  let content = null;

  if (isUnauthorized) {
    content = <MeetingUnauthorized meetingId={meetingId} />;
  } else if (isNotFound) {
    content = <MeetingNotFound />;
  } else if (!meeting) {
    content = <MeetingSkeleton />;
  } else {
    content = (
      <MeetingContent meetingId={meetingId} showShareModal={showShareModal} />
    );
  }

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Transcripts',
        id: 'ux3fA8',
        description: 'Menu bar text.',
      })}
      grow
      maxWidth="full"
    >
      {content}
    </Page>
  );
};

export default MeetingView;
