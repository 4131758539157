import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddableSpaces } from '../redux/selectors';
import {
  FetchAllSpacesDocument,
  FetchSpaceDetailsDocument,
  UpdateSpaceOwnerDocument,
  UserSpace,
  UpdateSpaceDocument,
  UpdateSpaceInput,
  UpdateSpaceMutation,
  AddToSpaceDocument,
  RemoveFromSpaceDocument,
} from '../graphql/operations';
import { useMutation, useQuery, MutationResult } from '@apollo/client';
import { QueryResult } from './helpers';
import {
  gotSpace,
  showCreateSpaceDialog as showCreateSpaceDialogAction,
} from '../redux/modules/global';
import { enqueueSnackbar } from 'notistack';
import { FormattedMessage } from 'react-intl';

/** Return all spaces a user can add meetings to */
export function useAddableSpaces(): UserSpace[] {
  const addableSpaces = useSelector(selectAddableSpaces);
  return addableSpaces;
}

/**
 * Return a single space
 */
export function useSpace(options: {
  id: string | undefined;
  refetch?: boolean;
}): QueryResult<typeof FetchSpaceDetailsDocument> {
  const { id, refetch } = options;
  const dispatch = useDispatch();
  const { data, error, loading } = useQuery(FetchSpaceDetailsDocument, {
    skip: !id,
    variables: { id: id ?? '' },
    fetchPolicy: refetch ? 'cache-and-network' : 'cache-first',
    // Keep redux in sync while we are still using spaces stored there
    onCompleted(data) {
      data.space && dispatch(gotSpace(data.space));
    },
  });

  return { data, error, loading };
}

/**
 * Return a multiple spaces
 */
export function useSpaceList(options: {
  teamId: string | undefined;
  refetch?: boolean;
}): QueryResult<typeof FetchAllSpacesDocument> {
  const { teamId, refetch } = options;
  const { data, error, loading } = useQuery(FetchAllSpacesDocument, {
    variables: teamId ? { input: { teamId } } : undefined,
    fetchPolicy: refetch ? 'cache-and-network' : 'cache-first',
  });

  return { data, error, loading };
}

/**
 * Transfer ownership of a space
 */
export function useSpaceUpdateOwner() {
  const [fn, { data, loading, error }] = useMutation(UpdateSpaceOwnerDocument);

  return {
    request: (variables: { input: { spaceId: string; createdBy: string }[] }) =>
      fn({ variables: { input: variables.input } }),
    data,
    error,
    loading,
  };
}

export function useCreateSpaceDialog() {
  const dispatch = useDispatch();
  return () => dispatch(showCreateSpaceDialogAction());
}

export const useSpaceUpateMutation = (): {
  onUpdate: (input: UpdateSpaceInput) => Promise<void>;
  updateResult: MutationResult<UpdateSpaceMutation>;
} => {
  const dispatch = useDispatch();
  const [updateSpace, updateResult] = useMutation(UpdateSpaceDocument);

  const onUpdate = async (input: UpdateSpaceInput) => {
    const response = await updateSpace({
      variables: {
        input,
      },
    });

    // Error handing
    if (response.data?.updateSpace.success) {
      const updatedSpace = response.data?.updateSpace.space;
      updatedSpace && dispatch(gotSpace(updatedSpace));
    }
  };

  return { onUpdate, updateResult };
};

/**
 * Hook to attach or add a meeting to a Space, or Remove it from it.
 *
 * @example
 * ```
 * const { updateMeetingSpaceStatus } = useUpdateMeetingSpaceStatus();
 * // Add meeting into a UserSpace
 * await updateMeetingSpaceStatus(meetingId, userSpace, true);
 * // Remove meeting from a UserSpace
 * await updateMeetingSpaceStatus(meetingId, userSpace, false);
 * ```
 *
 * @param meetingId Meeting you'd like to add or remove from a space
 * @param userSpace UserSpace in question
 * @param shouldAdd Should add the meeting, if false it removes it
 * @param onCompleted
 */
export const useUpdateMeetingSpaceStatus = () => {
  const [addToSpace, addToSpaceMutation] = useMutation(AddToSpaceDocument);
  const [removeFromSpace, removeFromSpaceMutation] = useMutation(
    RemoveFromSpaceDocument
  );

  const updateMeetingSpaceStatus = async (
    meetingId: string,
    userSpace: UserSpace,
    shouldAdd: boolean,
    onCompleted?: (() => void) | undefined
  ) => {
    if (!shouldAdd) {
      await removeFromSpace({
        variables: {
          input: {
            meetingId,
            spaceId: userSpace.id,
          },
        },
        onCompleted: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Meeting has been removed from the space - {spaceIcon} {spaceName}"
              id="hnzejC"
              values={{
                spaceIcon: userSpace.icon,
                spaceName: userSpace.name,
              }}
            />,
            { variant: 'SUCCESS' }
          );
          onCompleted?.();
        },
        onError: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Failed to remove the meeting from the space - {spaceIcon} {spaceName}"
              id="xDPeAW"
              values={{
                spaceIcon: userSpace.icon,
                spaceName: userSpace.name,
              }}
            />,
            { variant: 'ERROR' }
          );
        },
      });
    } else {
      await addToSpace({
        variables: {
          input: {
            meetingId,
            spaceId: userSpace.id,
          },
        },
        onCompleted: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Meeting has been added to the space - {spaceIcon} {spaceName}"
              id="7zw06j"
              values={{
                spaceIcon: userSpace.icon,
                spaceName: userSpace.name,
              }}
            />,
            { variant: 'SUCCESS' }
          );
          onCompleted?.();
        },
        onError: () => {
          enqueueSnackbar(
            <FormattedMessage
              defaultMessage="Failed to add the meeting to the space - {spaceIcon} {spaceName}"
              id="NkC75Y"
              values={{
                spaceIcon: userSpace.icon,
                spaceName: userSpace.name,
              }}
            />,
            { variant: 'ERROR' }
          );
        },
      });
    }
  };

  return {
    updateMeetingSpaceStatus,
    loading: removeFromSpaceMutation.loading || addToSpaceMutation.loading,
  };
};
