import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextInput } from '../../../components/TextInput';
import { Button } from '../../../components/buttons';
import { UserIntegrationConnection } from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import {
  connectToQuip,
  createQuipDocument,
} from '../../../helpers/api/integrations/quip';
import icon from '../../../img/quip-icon.svg';
import {
  IntegrationImplementation,
  IntegrationShare,
} from '../../../models/integration';
import { Loading } from '../../Landing/Loading/LoadingComponent';

const QuipConnector: React.FC<any> = (props) => {
  const [isTokenVisible, setTokenVisible] = useState(!props.currentToken);
  const [isBlank, setBlank] = useState(true);
  const [currentToken, setCurrentToken] = useState(props.currentToken ?? '');
  const [isConnecting, setConnecting] = useState(false);
  const [hasNavigated, setNavigated] = useState(false);

  const intl = useIntl();

  if (isBlank) {
    return (
      <Button onClick={() => setBlank(false)}>
        <FormattedMessage
          defaultMessage="+ Connect"
          id="Lg7Bbq"
          description="Integration. Connect button title."
        />
      </Button>
    );
  }

  if (!hasNavigated) {
    return (
      <Button
        onClick={() => {
          trackWebEvent('Integration - Start Connecting', {
            type: 'quip',
          });
          window.open(
            'https://quip.com/dev/token',
            'QuipToken',
            'height=300,width=300'
          );
          setNavigated(true);
        }}
      >
        <FormattedMessage
          defaultMessage="Get Quip API Token"
          id="5jSzc2"
          description="Quip integration. Get API token button."
        />
      </Button>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <TextInput
        type={isTokenVisible ? 'text' : 'password'}
        value={currentToken}
        placeholder={intl.formatMessage({
          defaultMessage: 'Paste your Quip token here',
          id: 'HrUp7o',
          description: 'Quip integration. Token text field placeholder.',
        })}
        onChange={setCurrentToken}
        disabled={isConnecting}
      />
      {isConnecting ? (
        <Loading />
      ) : (
        <Button
          onClick={async () => {
            if (currentToken) {
              setConnecting(true);
              setTokenVisible(false);

              try {
                const result = await connectToQuip(currentToken);

                if (result.connectionKey) {
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Connected!',
                      id: 'LxNA1Z',
                      description: 'Quip integration. Toast success message.',
                    }),
                    { variant: 'SUCCESS' }
                  );
                } else {
                  throw new Error();
                }
              } catch (e) {
                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage:
                      'Could not connect to Quip. Please try again and check that you have copied the whole token.',
                    id: 'BsPhtS',
                    description:
                      'Quip integration. Could not connect warning message.',
                  }),
                  { variant: 'WARNING' }
                );
              } finally {
                setTokenVisible(true);
                setBlank(true);
                setCurrentToken('');
                setConnecting(false);
                setNavigated(false);
              }
            }
          }}
        >
          <FormattedMessage
            defaultMessage="Save"
            description="Button title"
            id="WQBZ4b"
          />
        </Button>
      )}
    </div>
  );
};

const QuipViewer: React.FC<any> = (props) => {
  const { connection } = props;
  return connection.name;
};

const QuipFolder: React.FC<{ connection: UserIntegrationConnection }> = (
  props
) => {
  return props.connection.folderId ? (
    <Button
      size="small"
      href={`https://quip.com/${props.connection.folderId}`}
      onClick={() => {
        trackWebEvent('Integration - Connection List Action', {
          type: 'quip',
          action: 'open-folder',
        });
        return true;
      }}
      target="_blank"
    >
      <FormattedMessage
        defaultMessage="View Folder"
        id="sqmYLH"
        description="Quip integration. View folder link button."
      />
    </Button>
  ) : null;
};

const shareImpl: IntegrationShare = async (connection, options) => {
  const result = await createQuipDocument({
    connectionKey: connection.connectionKey,
    meetingId: options.meetingId,
    title: options.title,
    rawTranscript: options.rawTranscript,
    contactId: options.contactId,
    shareSettings: {
      isSharingDetails: options.isSharingDetails,
      isSharingHighlights: options.isSharingHighlights,
      isSharingNotes: options.isSharingNotes,
      isSharingTranscript: options.isSharingTranscript,
    },
  });

  return { externalId: result.externalId, link: result.link };
};

const integration: IntegrationImplementation = {
  id: 'quip',
  title: 'Quip',
  icon,
  description: () => (
    <div className="text-slate-500">
      <FormattedMessage
        defaultMessage="Save transcripts to your private Quip space."
        id="hfi/L6"
        description="Quip integration description."
      />
    </div>
  ),
  connector: QuipConnector,
  viewer: QuipViewer,
  actions: [QuipFolder],
  shareDescription: () => (
    <FormattedMessage
      defaultMessage="Create a page"
      id="XufIhy"
      description="Quip integration share description."
    />
  ),
  share: shareImpl,
  helpUrl:
    'https://help.tactiq.io/en/articles/5492855-tactiq-s-quip-integration',
};

export default integration;
