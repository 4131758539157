import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconAsana from '../../../img/asana.png';
import iconBox from '../../../img/box.png';
import iconBlackboard from '../../../img/blackboard.png';
import iconCanvas from '../../../img/canvas.png';
import iconFigma from '../../../img/figma.png';
import iconGithub from '../../../img/github.png';
import iconJira from '../../../img/jira.png';
import iconProductboard from '../../../img/productboard.png';
import iconScoro from '../../../img/scoro.png';
import iconClickup from '../../../img/clickup.png';
import iconEvernote from '../../../img/evernote.png';
import iconIntercom from '../../../img/intercom.png';
import iconJitsi from '../../../img/jitsi.png';
import iconOnedrive from '../../../img/onedrive.png';
import iconOnenote from '../../../img/onenote.png';
import iconMiro from '../../../img/miro.png';
import iconMonday from '../../../img/monday.png';
import iconTodoist from '../../../img/todoist.png';
import iconTrello from '../../../img/trello.png';
import iconWhereby from '../../../img/whereby.svg';
import iconYoutube from '../../../img/youtube.png';
import iconZendesk from '../../../img/zendesk.svg';
import iconZohoCRM from '../../../img/zohocrm.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import iconDropbox from '../../../img/dropboxIcon.svg';

const waitlist: IntegrationImplementation[] = [
  {
    id: 'asana',
    title: 'Asana',
    icon: iconAsana,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Asana based on your highlights and tags."
          id="MS0jhR"
          description="Asana integration description."
        />
      </div>
    ),
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks"
          id="NjLpKP"
          description="Asana integration share description."
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'box',
    category: IntegrationCategory.Storage,
    title: 'Box',
    icon: iconBox,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Store your transcripts to Box."
          id="953K4n"
          description="Box integration description"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'dropbox',
    category: IntegrationCategory.Storage,
    title: 'Dropbox',
    icon: iconDropbox,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Save transcripts to a folder in your Dropbox."
          id="mbcgly"
          description="Dropbox integration description"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'jira',
    title: 'Jira',
    icon: iconJira,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Jira based on your highlights and tags."
          id="rX8CtD"
          description="Jira integration description"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks"
          description="Jira integration share description"
          id="NKuAfT"
        />
      </div>
    ),
  },
  {
    id: 'jitsi',
    title: 'Jitsi',
    icon: iconJitsi,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Transcribe your Jitsi meetings."
          description="Jitsi integration description"
          id="CBN/Fr"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'whereby',
    title: 'Whereby',
    icon: iconWhereby,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Transcribe your Whereby meetings."
          description="Whereby integration description"
          id="2vhrTF"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'monday',
    title: 'Monday',
    icon: iconMonday,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Monday based on your highlights and tags."
          description="Monday integration description"
          id="exh3sv"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks"
          description="Monday integration share description"
          id="4LbO2E"
        />
      </div>
    ),
  },
  {
    id: 'clickup',
    title: 'ClickUp',
    icon: iconClickup,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in ClickUp based on your highlights and tags."
          description="ClickUp integration description"
          id="4Zf/+w"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks"
          description="ClickUp integration share description"
          id="gCYKja"
        />
      </div>
    ),
  },
  {
    id: 'intercom',
    title: 'Intercom',
    icon: iconIntercom,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link your transcripts to Intercom conversations and contacts."
          description="Intercom integration description"
          id="tQD5cj"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link conversation"
          description="Intercom integration share description"
          id="joQsHl"
        />
      </div>
    ),
  },
  {
    id: 'zohocrm',
    category: IntegrationCategory.CRM,
    title: 'Zoho CRM',
    icon: iconZohoCRM,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link your transcripts with contacts and deals in Zoho CRM."
          description="Zoho CRM integration description"
          id="cA5e/x"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link"
          description="Zoho CRM integration share description"
          id="a7C56y"
        />
      </div>
    ),
  },
  {
    id: 'evernote',
    title: 'Evernote',
    icon: iconEvernote,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Store your transcripts in Evernote."
          description="Evernote integration description"
          id="1crNEm"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create a note"
          description="Evernote integration share description"
          id="t2oo8e"
        />
      </div>
    ),
  },
  {
    id: 'onenote',
    title: 'Microsoft OneNote',
    icon: iconOnenote,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Store your transcripts in OneNote"
          description="Microsoft OneNote integration description"
          id="8/EDFa"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create a page"
          description="Microsoft OneNote integration share description"
          id="OJsgMz"
        />
      </div>
    ),
  },
  {
    id: 'trello',
    title: 'Trello',
    icon: iconTrello,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create cards in Trello based on your highlights and tags."
          description="Trello integration description"
          id="In6ZhF"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create a card"
          description="Trello integration share description"
          id="8AoeNK"
        />
      </div>
    ),
  },
  {
    id: 'todoist',
    title: 'Todoist',
    icon: iconTodoist,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Todoist based on your highlights and tags."
          description="Todoist integration description"
          id="lb9ksJ"
        />
      </div>
    ),
    waitlist: true,
    shareDescription: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create a task"
          description="Todoist integration share description"
          id="QEKRt1"
        />
      </div>
    ),
  },
  {
    id: 'onedrive',
    category: IntegrationCategory.Storage,
    title: 'Microsoft OneDrive',
    icon: iconOnedrive,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Store your transcripts in OneDrive."
          description="Microsoft OneDrive integration description"
          id="lX1Zee"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'canvas',
    title: 'Canvas',
    icon: iconCanvas,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link your transcripts with drawings in Canvas"
          description="Canvas integration description"
          id="/VmzCv"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'blackboard',
    title: 'Blackboard',
    icon: iconBlackboard,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Link your transcripts with drawings in Blackboard"
          description="Blackboard integration description"
          id="bZVmbg"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'scoro',
    title: 'Scoro',
    icon: iconScoro,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Scoro based on your highlights and tags."
          description="Scoro integration description"
          id="v51Yum"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'productboard',
    title: 'Productboard',
    icon: iconProductboard,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Productboard based on your highlights and tags."
          description="Productboard integration description"
          id="38cdHU"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'miro',
    title: 'Miro',
    icon: iconMiro,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Post meetings highlights as stickers in Miro boards"
          description="Miro integration description"
          id="QQlw4T"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'github',
    title: 'Github',
    icon: iconGithub,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create issues in Github based on your highlights and tags."
          description="Github integration description"
          id="YqBoeq"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'figma',
    title: 'Figma',
    icon: iconFigma,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Post meetings highlights to the board and access the meeting quotes from Figma layers"
          description="Figma integration description"
          id="uwTxmw"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'zendesk',
    title: 'Zendesk',
    icon: iconZendesk,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Create tasks in Zendesk based on your highlights and tags."
          description="Zendesk integration description"
          id="lQAM6d"
        />
      </div>
    ),
    waitlist: true,
  },
  {
    id: 'youtube',
    title: 'Youtube',
    icon: iconYoutube,
    description: () => (
      <div className="text-slate-500">
        <FormattedMessage
          defaultMessage="Transcribe Youtube videos."
          description="Youtube integration description"
          id="pO0Pad"
        />
      </div>
    ),
    waitlist: true,
  },
];

export default waitlist;
