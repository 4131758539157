import { RawTranscript } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '../../../../components/Alert';
import { Button } from '../../../../components/buttons';
import { updateMeeting } from '../../../../graphql/meetings';
import { MeetingReach } from '../../../../graphql/operations';
import {
  trackTranscriptSharingStarted,
  trackWebEvent,
} from '../../../../helpers/analytics';
import { useFullMeeting } from '../../common/meeting-hooks';
import { Share2 } from 'lucide-react';
import { useSelector } from 'react-redux';
import { selectTeam } from '../../../../redux/selectors';

/**
 * Quick share
 * @param {unknown} param0 params
 * @returns {React.FC} a component
 */
export const QuickShare: React.FC<{
  meetingId: string;
  suggestedParticipants: string[];
  rawTranscript: RawTranscript;
  onShare: () => void;
}> = ({ meetingId, rawTranscript, suggestedParticipants, onShare }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = React.useState(false);
  const meeting = useFullMeeting();
  const team = useSelector(selectTeam);

  const onShareClick = useCallback(async () => {
    setIsLoading(true);

    trackTranscriptSharingStarted('Transcript Sidebar - Quick Share', team?.id);

    await updateMeeting({
      id: meetingId,
      rawTranscript,
      permissions: {
        reach: MeetingReach.RESTRICTED,
        emails: suggestedParticipants,
        settings: {
          isSharingDetails: true,
          isSharingHighlights: true,
          isSharingNotes: true,
          isSharingTranscript: true,
        },
      },
    });

    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage: 'Meeting is successfully shared',
        id: 'QCtRLc',
      }),
      { variant: 'SUCCESS' }
    );

    onShare();
  }, [intl, meetingId, onShare, rawTranscript, suggestedParticipants]);

  const onDismissClick = useCallback(async () => {
    if (!meeting) return;
    setIsLoading(true);

    trackWebEvent('Quick share dismiss button clicked', {
      meetingId: meeting.id,
    });

    await updateMeeting({
      id: meeting.id,
      noSharingPrompts: true,
    });
  }, [meeting]);

  if (!meeting) return null;
  if (meeting?.noSharingPrompts) return null;

  return (
    <Alert
      severity="info"
      variant="light"
      alternateIcon={<Share2 />}
      compact
      action={
        <>
          <Button loading={isLoading} onClick={onShareClick}>
            <FormattedMessage defaultMessage="Share" id="OKhRC6" />
          </Button>
          <Button
            loading={isLoading}
            variant="neutral-secondary"
            onClick={onDismissClick}
          >
            <FormattedMessage defaultMessage="Dismiss" id="TDaF6J" />
          </Button>
        </>
      }
      description={
        <FormattedMessage
          defaultMessage="Share this transcript with meeting participants ({suggestedParticipants})"
          id="RGjnPM"
          values={{ suggestedParticipants: suggestedParticipants.join(', ') }}
        />
      }
    />
  );
};
