import { Plus } from 'lucide-react';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Emoji } from 'emoji-picker-react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

import {
  MeetingAiPromptOutput,
  UpdateAiPromptOutputDocument,
  CreateMeetingKitDocument,
  MeetingKitItemType,
  UpdateMeetingKitDocument,
  UpdateMeetingKitItemDocument,
  CreateMeetingKitItemDocument,
} from '../../../../graphql/operations';
import { RootState } from '../../../../redux/store';
import { Button } from '../../../../components/buttons';
import { TextInput } from '../../../../components/TextInput';
import { ModalDialog } from '../../../../components/modals';
import { trackWebEvent } from '../../../../helpers/analytics';
import {
  setMeetingKit,
  setMeetingKitItem,
} from '../../../../redux/modules/global';
import { Select } from '../../../../components/Select';

export interface AddToMeetingKitModalProps {
  open: boolean;
  onClose: () => void;
  meetingId: string;
  promptSource: MeetingAiPromptOutput | null;
}

const NEW_MEETING_KIT_ID = 'new-meeting-kit-id';

const AddToMeetingKitModal: React.FC<AddToMeetingKitModalProps> = ({
  open,
  onClose,
  meetingId,
  promptSource,
}) => {
  const meetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.used
  );
  const [selectedMeetingKitId, setSelectedMeetingKitId] = useState<string>(
    meetingKits[0]?.id ?? NEW_MEETING_KIT_ID
  );

  const [prompt, setPrompt] = useState<string>(promptSource?.prompt || '');
  const [promptName, setPromptName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const [promptNameBlurred, setPromptNameBlurred] = useState<boolean>(false);

  const [createMeetingKit] = useMutation(CreateMeetingKitDocument);
  const [updateMeetingKit] = useMutation(UpdateMeetingKitDocument);
  const [createMeetingKitItem] = useMutation(CreateMeetingKitItemDocument);
  const [updateMeetingKitItem] = useMutation(UpdateMeetingKitItemDocument);
  const [updateAIPromptOutput] = useMutation(UpdateAiPromptOutputDocument);

  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (promptSource) {
      setPrompt(promptSource.prompt);
      setPromptName('');
      setIsSaving(false);
    }
  }, [promptSource]);

  const promptNameHasError =
    promptName.trim().length === 0 &&
    selectedMeetingKitId === NEW_MEETING_KIT_ID;

  const isFormValid = !promptNameHasError && prompt.trim().length > 0;

  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title={
        <p className="font-bold text-2xl">
          <FormattedMessage
            defaultMessage="Save question as a new prompt"
            id="yos1je"
          />
        </p>
      }
      actions={
        <>
          <Button
            loading={isSaving}
            onClick={async () => {
              setPromptNameBlurred(true);
              if (!isFormValid) {
                return;
              }
              setIsSaving(true);
              trackWebEvent(`Save prompt to a Kit - Save clicked`);
              try {
                let meetingKitId: string | undefined;
                let meetingKitName = promptName;
                if (selectedMeetingKitId === NEW_MEETING_KIT_ID) {
                  const newMeetingKit = (await createMeetingKit()).data
                    ?.createMeetingKit?.meetingKit;

                  meetingKitId = newMeetingKit?.id;
                  if (!meetingKitId) {
                    throw new Error('Failed to create a new meeting kit');
                  }
                  // update new meeting kit name
                  const updatedKit = (
                    await updateMeetingKit({
                      variables: {
                        input: {
                          name: meetingKitName,
                          icon: newMeetingKit?.icon || '',
                          description: '',
                          meetingKitId,
                          items: [],
                        },
                      },
                    })
                  ).data?.updateMeetingKit.meetingKit;
                  if (!updatedKit) {
                    throw new Error('Failed to update a new meeting kit');
                  }
                  dispatch(setMeetingKit(updatedKit));
                } else {
                  // we are adding to an existing kit
                  meetingKitId = selectedMeetingKitId;
                  meetingKitName =
                    meetingKits.find((kit) => kit.id === meetingKitId)?.name ??
                    '';
                }

                const item = (
                  await createMeetingKitItem({
                    variables: {
                      input: {
                        meetingKitId,
                        type: MeetingKitItemType.PROMPT,
                      },
                    },
                  })
                ).data?.createMeetingKitItem?.meetingKitItem;
                if (!item?.id) {
                  throw new Error('Failed to save a prompt');
                }
                const updatedItem = (
                  await updateMeetingKitItem({
                    variables: {
                      input: {
                        meetingKitId,
                        meetingKitItemId: item.id,
                        name: promptName,
                        description: '',
                        icon: item.icon,
                        outputType: item.outputType,
                        updatePrompt: {
                          prompt,
                        },
                      },
                    },
                  })
                ).data?.updateMeetingKitItem.meetingKitItem;
                if (!updatedItem) {
                  throw new Error('Failed to update a new meeting kit');
                }
                dispatch(
                  setMeetingKitItem({ kitId: meetingKitId, item: updatedItem })
                );

                const content =
                  promptSource?.content?.__typename ===
                  'MeetingAIOutputTypeString'
                    ? promptSource.content.text
                    : '';

                // finally, link output to saved meeting kit item
                await updateAIPromptOutput({
                  variables: {
                    input: {
                      meetingId,
                      outputPromptId: promptSource!.requestedAt.toString(),
                      promptTitle:
                        promptName === meetingKitName
                          ? promptName
                          : `${meetingKitName} - ${promptName}`,
                      content,
                      contentType: promptSource!.contentType,
                      meetingKitId,
                      meetingKitItemId: item.id,
                    },
                  },
                });

                setIsSaving(false);
                onClose();
              } catch (e) {
                setIsSaving(false);
                enqueueSnackbar(e.message, { variant: 'ERROR' });
              }
            }}
          >
            <FormattedMessage defaultMessage="Save" id="jvo0vs" />
          </Button>
          <Button
            variant="neutral-secondary"
            onClick={() => {
              trackWebEvent(`Save prompt to a Kit - Close clicked`);
              onClose();
            }}
          >
            <FormattedMessage defaultMessage="Close" id="rbrahO" />
          </Button>
        </>
      }
    >
      <div className="my-4 flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <p className="block font-semibold text-slate-600 text-sm leading-6">
            <FormattedMessage
              defaultMessage="Meeting kit for a new prompt"
              id="zuiATV"
            />
          </p>
          <Select
            value={selectedMeetingKitId}
            onChange={setSelectedMeetingKitId}
            options={[
              ...meetingKits.map((kit) => ({
                value: kit.id,
                label: kit.name,
                icon: kit.icon ? (
                  <Emoji unified={kit.icon} size={16} />
                ) : undefined,
              })),
              {
                value: NEW_MEETING_KIT_ID,
                label: intl.formatMessage({
                  defaultMessage: 'Create Kit',
                  id: 'aJ0iq+',
                }),
                icon: <Plus size={16} />,
              },
            ]}
          />
        </div>
        <TextInput
          type="text"
          value={promptName}
          label={<FormattedMessage defaultMessage="Name" id="HAlOn1" />}
          onBlur={() => setPromptNameBlurred(true)}
          onChange={(value) => setPromptName(value)}
          error={promptNameBlurred && promptNameHasError}
          autoFocus={true}
        />
        <TextInput
          type="textarea"
          value={prompt}
          label={
            <FormattedMessage defaultMessage="Prompt (question)" id="L30MEF" />
          }
          onChange={(value) => setPrompt(value)}
          error={prompt.trim().length === 0}
        />
      </div>
    </ModalDialog>
  );
};

export { AddToMeetingKitModal };
