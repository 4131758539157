export const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp);

  const currentYear = new Date().getFullYear();
  const year = date.getFullYear();

  const weekday = date.toLocaleDateString(undefined, { weekday: 'long' });
  const month = date.toLocaleDateString(undefined, { month: 'long' });
  const day = date.toLocaleDateString(undefined, { day: 'numeric' });

  const yearString = year !== currentYear ? `, ${year}` : '';

  return `${weekday}, ${month} ${day}${yearString}`;
};
