import { useMutation } from '@apollo/client';
import { BlockGroup, combineBlocks } from '@tactiq/model';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Alert } from '../../../components/Alert';
import { WorkflowIntroPopup } from '../../Workflows/VideoTutorialButtons';

import {
  ChangeTranscriptBlocksDocument,
  MeetingPlatform,
  MeetingReach,
  Tag,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { changeTranscriptTextForBlocks } from '../../../helpers/change-transcript';
import {
  highlightTranscriptBlocks,
  removeHighlightFromTranscriptBlocks,
} from '../../../helpers/highlight-transcript';
import {
  isMeetingAdmin,
  isMeetingEditable,
  isMeetingOwner,
} from '../../../helpers/meetings';
import { useQuery } from '../../../helpers/router';
import {
  TranscriptChanges,
  TranscriptSelection,
} from '../../../models/transcript';
import { changeTranscript } from '../../../redux/modules/global';
import {
  createSelectMeetingTagsSelector,
  createSelectRawTranscriptSelector,
  createSelectTranscriptBlocksSelector,
  createSelectTransformedTranscriptSelector,
  selectIsMeetingLoading,
  selectIsProUser,
  selectTeam,
  selectTimestampOption,
  selectUid,
} from '../../../redux/selectors';
import { RootState } from '../../../redux/store';
import { LoadingContent } from '../../Landing/Loading/LoadingComponent';
import { AlertZoomDesktopRequirementsInfo } from '../../Setup/AlertZoomDesktopRequirementsInfo';
import { AlertAllParticipantsHidden } from './AlertAllParticipantsHidden';
import { useFullMeeting } from '../common/meeting-hooks';
import { MeetingContext } from './MeetingContext';
import TranscriptSection from './TranscriptSection';
import { VisibleOnlyWhen } from './VisibleOnlyWhen';
import { AIGenerated } from './ai/AIGenerated';
import { Avatars } from './avatars';
import { TranscriptComments } from './comments';
import TranscriptGDoc from './gdoc';
import { MeetingHeaderLabels } from './header/MeetingHeaderLabels';
import { TranscriptsAndShares } from './header/TranscriptsAndShares';
import { TranscriptViewMenu } from './menu/Menu';
import TranscriptNotesComponent from './notes';
import { QuickShare } from './quick-share';
import RelatedMeetings from './related-meetings';
import { ShareMeeting } from './share';
import { ParticipantStats } from './stats';
import Transcript, { Ref as TranscriptRef } from './transcript/Transcript';
import { transformBlocks } from './transcript/transform-blocks';
import { ExtendedTranscriptBlock, ViewMode } from './types';
import { FuseResult, FuseResultMatch } from 'fuse.js';
import debounce from 'lodash/debounce';
import { TranscriptSkeleton } from './skeleton/TranscriptSkeleton';
import { MeetingSkeleton } from './skeleton/MeetingSkeleton';
import WorkflowRunMenu from '../../Workflows/WorkflowRunMenu';

export interface MeetingContentProps {
  meetingId: string;
  showShareModal?: boolean;
}

const DEFAULT_BLOCKS_WITH_MATCHES = [[], []];

const trackEventDebounced = debounce(trackWebEvent, 1000);

/**
 * Meeting Content
 * @param {unknown} param0 params
 * @param {string} param0.meetingId meeting id
 * @param {boolean} param0.showShareModal show share modal
 * @returns {React.FC} a component
 */
export const MeetingContent: React.FC<MeetingContentProps> = ({
  meetingId,
  showShareModal,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.original);
  const [currentMatches, setCurrentMatches] = useState<
    readonly FuseResultMatch[] | undefined
  >();
  const selectMeetingTags = useMemo(createSelectMeetingTagsSelector, []);
  const tags =
    useSelector((state: RootState) => selectMeetingTags(state, meetingId)) ??
    [];
  const isPaidUser = useSelector(selectIsProUser);
  const userId = useSelector(selectUid);
  const team = useSelector(selectTeam);
  const meeting = useFullMeeting(meetingId);
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const rawTranscript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );
  const selectTransformedTranscript = useMemo(
    createSelectTransformedTranscriptSelector,
    []
  );
  const transcript = useSelector((state: RootState) =>
    selectTransformedTranscript(state, meetingId)
  );
  const selectTranscriptBlocks = useMemo(
    createSelectTranscriptBlocksSelector,
    []
  );
  const sourceBlocks = useSelector((state: RootState) =>
    selectTranscriptBlocks(state, meetingId)
  );
  const isLoadingMeetingData = useSelector(selectIsMeetingLoading);
  const isEditable = isMeetingEditable(meeting);
  const isAdmin = isMeetingAdmin(meeting);
  const isOwner = userId && isMeetingOwner(userId, meeting);
  const timestampOption = useSelector(selectTimestampOption);
  const searchParams = useQuery();
  const { integrationId } = useParams() as {
    integrationId: string | undefined;
  };
  const reach = searchParams.get('reach')?.toUpperCase() as
    | MeetingReach
    | undefined;

  const canEditInRealtime = isPaidUser || meeting?.hasEnded;
  const intl = useIntl();

  const [changeTranscriptBlocks] = useMutation(ChangeTranscriptBlocksDocument);

  const cannotEditInRealtimeAlert = useCallback(() => {
    trackWebEvent('MeetingView - Transcript - Cannot edit in realtime', {
      meetingId,
    });

    enqueueSnackbar(
      intl.formatMessage({
        defaultMessage:
          'You need Tactiq Pro to edit the meeting before it finishes',
        id: 'e70AsO',
      }),
      { variant: 'WARNING' }
    );
  }, [intl, meetingId]);

  const onHighlight = useCallback(
    async (selection: TranscriptSelection, tag?: Tag) => {
      if (!meeting) return;
      if (!rawTranscript) return;
      if (!canEditInRealtime) return cannotEditInRealtimeAlert();

      trackWebEvent('MeetingView - Transcript - Highlight', {
        team_id: team?.id,
        meetingId,
        messagesCount: selection.messageIds.length,
        tag: tag?.name,
        accessType: meeting?.accessType,
        isOwner,
        isAdmin,
      });

      const changes = highlightTranscriptBlocks(
        rawTranscript.blocks,
        selection,
        tag
      );

      if (changes) {
        dispatch(
          changeTranscript({
            meetingId,
            changes,
          })
        );

        await changeTranscriptBlocks({
          variables: {
            input: {
              id: meetingId,
              ...changes,
            },
          },
        });
      }
    },
    [
      team,
      canEditInRealtime,
      cannotEditInRealtimeAlert,
      changeTranscriptBlocks,
      dispatch,
      isAdmin,
      isOwner,
      meeting,
      meetingId,
      rawTranscript,
    ]
  );

  const onRemoveHighlight = useCallback(
    async (messageIds: string[]) => {
      if (!meeting) return;
      if (!rawTranscript) return;
      if (!canEditInRealtime) return cannotEditInRealtimeAlert();

      trackWebEvent('MeetingView - Transcript - Highlight removed', {
        meetingId,
        messagesCount: messageIds.length,
        accessType: meeting?.accessType,
        isOwner,
        isAdmin,
      });

      const changes = removeHighlightFromTranscriptBlocks(
        rawTranscript.blocks,
        messageIds
      );

      dispatch(
        changeTranscript({
          meetingId,
          changes,
        })
      );

      await changeTranscriptBlocks({
        variables: {
          input: {
            id: meetingId,
            ...changes,
          },
        },
      });
    },
    [
      canEditInRealtime,
      cannotEditInRealtimeAlert,
      changeTranscriptBlocks,
      dispatch,
      isAdmin,
      isOwner,
      meeting,
      meetingId,
      rawTranscript,
    ]
  );

  const onChangeBlock = useCallback(
    async (messageIds: string[], newTranscript: string) => {
      if (!meeting) return;
      if (!rawTranscript) return;
      if (!canEditInRealtime) return cannotEditInRealtimeAlert();

      trackWebEvent('MeetingView - Transcript - Block changed', {
        team_id: team?.id,
        meetingId,
        messagesCount: messageIds.length,
        accessType: meeting?.accessType,
        isOwner,
        isAdmin,
      });

      const changes = changeTranscriptTextForBlocks(
        rawTranscript.blocks,
        messageIds,
        newTranscript
      );

      if (changes) {
        dispatch(
          changeTranscript({
            meetingId,
            changes,
          })
        );

        await changeTranscriptBlocks({
          variables: {
            input: {
              id: meetingId,
              ...changes,
            },
          },
        });
      }
    },
    [
      team,
      canEditInRealtime,
      cannotEditInRealtimeAlert,
      changeTranscriptBlocks,
      dispatch,
      isAdmin,
      isOwner,
      meeting,
      meetingId,
      rawTranscript,
    ]
  );

  const onRemoveBlock = useCallback(
    async (messageIds: string[]) => {
      if (!meeting) return;
      if (!rawTranscript) return;
      if (!canEditInRealtime) return cannotEditInRealtimeAlert();

      trackWebEvent('MeetingView - Transcript - Block removed', {
        meetingId,
        messagesCount: messageIds.length,
        accessType: meeting?.accessType,
        isOwner,
        isAdmin,
      });

      const changes: TranscriptChanges = {
        oldMessageIds: messageIds,
        newBlocks: rawTranscript.blocks
          .filter((b) => messageIds.includes(b.messageId))
          .map((b) => ({
            ...b,
            isDeleted: true,
          })),
        updatedAt: Date.now(),
      };

      dispatch(
        changeTranscript({
          meetingId,
          changes,
        })
      );

      await changeTranscriptBlocks({
        variables: {
          input: {
            id: meetingId,
            ...changes,
          },
        },
      });
    },
    [
      canEditInRealtime,
      cannotEditInRealtimeAlert,
      changeTranscriptBlocks,
      dispatch,
      isAdmin,
      isOwner,
      meeting,
      meetingId,
      rawTranscript,
    ]
  );

  const transcriptViewer = useRef<TranscriptRef | undefined>();

  const onShareModalClose = useCallback(() => {
    navigate(`/transcripts/${meetingId}`);
  }, [meetingId, navigate]);

  const onMatchSelected = useCallback((result: FuseResult<unknown>) => {
    setCurrentMatches(result.matches);
    const block = result.item as ExtendedTranscriptBlock;
    transcriptViewer.current?.scrollTo(block.messageId);
  }, []);

  const onChangeQuery = useCallback(
    (value: string) => {
      trackEventDebounced('Searched the transcript', {
        query: value,
        team_id: team?.id,
      });
      setQuery(value);
      setCurrentMatches(undefined);
    },
    [setQuery, team]
  );

  const filteredParticipants = useSelector(
    (state: RootState) => state.global.ui.filteredParticipants
  );

  const [blocks, matches] = useMemo(() => {
    if (sourceBlocks.length) {
      const [blocksWithMatches, matchedResults] = transformBlocks(
        sourceBlocks,
        transcript?.translation,
        query,
        viewMode,
        filteredParticipants[meetingId] ?? []
      );

      return [
        combineBlocks(
          blocksWithMatches
        ) as BlockGroup<ExtendedTranscriptBlock>[],
        matchedResults,
      ];
    }

    return DEFAULT_BLOCKS_WITH_MATCHES;
  }, [
    sourceBlocks,
    transcript?.translation,
    query,
    viewMode,
    filteredParticipants,
    meetingId,
  ]);

  if (!meeting) {
    return null;
  }

  let content = <MeetingSkeleton />;

  if (isLoadingMeetingData) {
    content = <TranscriptSkeleton />;
  } else if (blocks.length || filteredParticipants[meetingId]?.length) {
    const firstTimestamp = blocks[0]?.timestamp || 0;

    content = (
      <>
        <WorkflowIntroPopup showAfterMs={60000} />
        <AIGenerated detectedLanguage={transcript?.detectedLanguage} />
        <div className="block md:hidden">
          <TranscriptNotesComponent meetingId={meetingId} />
        </div>

        <div className="block md:hidden">
          <TranscriptComments meetingId={meetingId} />
        </div>

        <div className="block md:hidden">
          <ParticipantStats meetingId={meetingId} />
        </div>

        {transcript && rawTranscript && blocks?.length ? (
          <TranscriptSection
            header={
              <FormattedMessage
                defaultMessage="Transcript"
                id="Uedsnt"
                description="Meeting view. Transcript header."
              />
            }
            place="transcript-header"
          >
            <Transcript
              blocks={sourceBlocks}
              transformedBlocks={blocks}
              translation={transcript.translation}
              detectedLanguage={transcript.detectedLanguage}
              meeting={meeting}
              firstTimestamp={firstTimestamp}
              timestampOption={timestampOption}
              viewMode={viewMode}
              currentMatches={currentMatches}
              ref={transcriptViewer}
              tags={tags}
              onHighlight={onHighlight}
              onRemoveHighlight={onRemoveHighlight}
              onChangeBlock={onChangeBlock}
              onRemoveBlock={onRemoveBlock}
            />
          </TranscriptSection>
        ) : (
          <div className="py-6">
            <AlertAllParticipantsHidden meetingId={meetingId} />
          </div>
        )}

        {rawTranscript && !isLoadingMeetingData ? (
          <VisibleOnlyWhen condition={isAdmin}>
            {showShareModal && (
              <ShareMeeting
                meetingId={meetingId}
                rawTranscript={rawTranscript}
                blocks={sourceBlocks}
                onClose={onShareModalClose}
                integrationId={integrationId}
                emails={searchParams.getAll('emails')}
                reach={reach}
              />
            )}
          </VisibleOnlyWhen>
        ) : (
          <LoadingContent />
        )}
      </>
    );
  } else if (
    meeting.platform === MeetingPlatform.ZOOM_API &&
    meeting.duration &&
    meeting.isDraft
  ) {
    content = <AlertZoomDesktopRequirementsInfo />;
  } else if (isEditable && !isLoadingMeetingData && !rawTranscript) {
    content = <TranscriptGDoc meetingId={meetingId} />;
  }

  return (
    <MeetingContext.Provider value={meetingId}>
      <div className="flex flex-col divide-y divide-slate-200 overflow-hidden lg:h-[100vh]">
        <div className="px-8 pt-2 pb-5 md:top-0">
          <TranscriptViewMenu
            meeting={meeting}
            query={query}
            matches={matches}
            viewMode={viewMode}
            detectedLanguage={transcript?.detectedLanguage}
            translation={transcript?.translation}
            onChangeQuery={onChangeQuery}
            onChangeViewMode={setViewMode}
            onMatchSelected={onMatchSelected}
          />
        </div>

        {!!meeting.archivedAt && (
          <Alert
            severity="warning"
            description={
              <FormattedMessage
                defaultMessage="This meeting is archived"
                id="zYCn3Y"
              />
            }
          />
        )}

        <div className="px-8 py-4">
          <div className="flex flex-col gap-2">
            <h1 className="flex items-center justify-between font-semibold text-2xl text-slate-800">
              {meeting.title}
            </h1>

            <Avatars meetingId={meetingId} />
          </div>
        </div>

        <div className="space-between flex h-[100%] flex-col overflow-hidden lg:flex-row-reverse">
          <div className="flex min-w-[350px] flex-grow flex-col gap-2 overflow-x-hidden px-4 pt-4 pr-8 pb-4 lg:overflow-y-auto">
            <WorkflowRunMenu meetingId={meetingId} />

            <TranscriptsAndShares
              rawTranscript={rawTranscript}
              transcriptViewer={transcriptViewer}
            />

            {isAdmin && <QuickShare meetingId={meetingId} />}

            <MeetingHeaderLabels />

            <div className="hidden md:block">
              <ParticipantStats meetingId={meetingId} />
            </div>

            <RelatedMeetings meetingId={meetingId} />

            <div className="hidden md:block">
              <TranscriptNotesComponent meetingId={meetingId} />
            </div>

            <div className="hidden md:block">
              <TranscriptComments meetingId={meetingId} />
            </div>
          </div>

          <div className="flex-shrink flex-grow overflow-y-auto overflow-x-hidden px-8">
            {content}
          </div>
        </div>
      </div>
    </MeetingContext.Provider>
  );
};
