import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TierPricingDialogSource, trackWebPage } from '../../helpers/analytics';
import {
  selectTeam,
  selectUid,
  selectUserPlan,
  selectUserTier,
} from '../../redux/selectors';
import { Page } from '../Common/Navigation';
import { PromoBanner } from '../Common/PromoComponent';
import { Billing } from './Billing';
import { SendInvitationComponent } from './SendInvitationComponent';
import { TierPricingCardGroup } from './TierPricing/TierPricingCard';

/**
 * CreditPageComponent
 */
const CreditPageComponent: React.FC = () => {
  const userTier = useSelector(selectUserTier);

  const userId = useSelector(selectUid);
  const plan = useSelector(selectUserPlan);
  const team = useSelector(selectTeam);

  const intl = useIntl();

  const hasActiveTeamPlan = team?.isPaid;
  const canManageTeamPlan = team?.members.find((u) => u.uid === userId)?.roles
    .ADMIN;

  const isActive =
    plan.paid?.isPaid || (hasActiveTeamPlan && canManageTeamPlan);

  useEffect(() => {
    trackWebPage('Credit Page');
  }, []);

  return (
    <Page
      title={intl.formatMessage({
        defaultMessage: 'Billing',
        id: 'SYeYP2',
        description: 'Menu bar text.',
      })}
      maxWidth="lg"
      hidePaymentAlert
    >
      <h1 className="mb-3 text-3xl">
        <FormattedMessage defaultMessage="Billing" id="Tbo377" />
      </h1>

      <PromoBanner />

      {isActive ? (
        <Billing />
      ) : (
        <TierPricingCardGroup
          userTier={userTier}
          source={TierPricingDialogSource.BILLING}
          teamTier={team?.tier}
        />
      )}

      {!isActive && <SendInvitationComponent />}
    </Page>
  );
};

export default CreditPageComponent;
