import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Node,
  Edge,
  ReactFlow,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  OnNodesChange,
  OnEdgesChange,
  getOutgoers,
  getConnectedEdges,
  reconnectEdge,
  OnNodesDelete,
  addEdge,
  MarkerType,
  OnReconnect,
  IsValidConnection,
  OnConnect,
  ConnectionLineType,
} from '@xyflow/react';
import { getOverlappingArea, nodeToRect } from '@xyflow/system';

// The docs say that you need this for functionality - i'm not sure why though
import '@xyflow/react/dist/style.css';

import { nanoid } from 'nanoid';
import { nodeTypes } from './nodes';
import { WorkflowExecution } from '../../graphql/operations';
import {
  trackConnectWorkflowEdge,
  trackDeleteWorkflowEdge,
  trackDeleteWorkflowNode,
  viewWorkflowEditPage,
} from '../../helpers/analytics';
import { NodeValidator } from '@tactiq/model';
import { WatchVideoEditorControl } from './VideoTutorialButtons';
import { Background } from './Background';
import EdgeComponent, { EdgeMarkerDefs } from './edges/EdgeComponent';
import { EdgeStateProvider } from './edges/EdgeStateProvider';

type Props = {
  workflowId: string;
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
  setEdges: Dispatch<SetStateAction<Edge[]>>;
  nodeDragThreshold: number;
  nodeCounter: number;
  recentExecution?: WorkflowExecution;
  readonly?: boolean;
};

const GRID_RESOLUTION = 8;
const NODE_AUTOLAYOUT_BOTTOM_PADDING = 60;

function adjustOverlappingNodes(nodes: Node[]): Node[] {
  const nodesMap = new Map<string, Node>();
  for (const n of nodes) {
    nodesMap.set(n.id, {
      ...n,
      position: { ...n.position },
    });
  }
  // sort vertically
  const sortedNodes = nodes.slice().sort((a, b) => a.position.y - b.position.y);

  // go top to bottom, if current node overlaps with any of the next nodes, move it down
  for (let i = 0; i < sortedNodes.length; i++) {
    for (let j = i + 1; j < sortedNodes.length; j++) {
      const node = nodesMap.get(sortedNodes[i].id);
      const nextNode = nodesMap.get(sortedNodes[j].id);
      if (!node || !nextNode) {
        continue;
      }
      if (node.dragging || nextNode.dragging) {
        continue;
      }
      const nodeRect = nodeToRect(node);
      const nextNodeRect = nodeToRect(nextNode);
      // extend node down so that we have extra space for handles and edges
      nodeRect.height += NODE_AUTOLAYOUT_BOTTOM_PADDING;
      if (getOverlappingArea(nodeRect, nextNodeRect) > 0) {
        nextNode.position.y = node.position.y + nodeRect.height;
      }
    }
  }
  return nodes.map((node) => nodesMap.get(node.id)) as Node[];
}

export default function WorkflowEditor(props: Props): ReactElement {
  const { nodes, edges, setNodes, setEdges, workflowId } = props;
  const [showVideoButtonPanel, setShowVideoButtonPanel] = useState(true);
  // reactflow does not signal 'mouse leave' event when you enter another edge
  // before leaving the previous one, so there is no reliable way to track
  // multiple hovered edges. We will just track the last one
  const [hoveredEdgeId, setHoveredEdgeId] = useState<string | null>(null);
  const [reconnectingEdgeId, setReconnectingEdgeId] = useState<string | null>(
    null
  );

  useEffect(() => {
    viewWorkflowEditPage({ workflowId });
  }, [workflowId]);

  const onNodesChange: OnNodesChange<Node> = useCallback(
    (changes) => {
      return setNodes((nds) => {
        return adjustOverlappingNodes(
          applyNodeChanges(changes, nds).map(NodeValidator.validate) as Node[]
        );
      });
    },
    [setNodes]
  );
  const onEdgesChange: OnEdgesChange<Edge> = useCallback(
    (changes) =>
      setEdges((eds: Edge[]) => {
        return applyEdgeChanges(changes, eds);
      }),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (params) => {
      trackConnectWorkflowEdge({ workflowId });
      return setEdges((eds) => addEdge(params, eds));
    },
    [setEdges, workflowId]
  );

  const onReconnect: OnReconnect = useCallback(
    (oldEdge, newConnection) =>
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els)),
    [setEdges]
  );

  const isValidConnection: IsValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const target = nodes.find((node) => node.id === connection.target);
      const hasCycle = (node: Node, visited = new Set()) => {
        if (visited.has(node.id)) return false;

        visited.add(node.id);

        for (const outgoer of getOutgoers(node, nodes, edges)) {
          if (outgoer.id === connection.source) return true;
          if (hasCycle(outgoer, visited)) return true;
        }
      };

      if (target?.id === connection.source) return false;
      return Boolean(target && !hasCycle(target));
    },
    [nodes, edges]
  );

  const onNodesDelete: OnNodesDelete = useCallback(
    (deleted) => {
      setEdges(
        deleted.reduce((acc, node) => {
          trackDeleteWorkflowNode({ workflowId, type: node.type });
          const connectedEdges = getConnectedEdges([node], edges);
          const remainingEdges = acc.filter((e) => !connectedEdges.includes(e));
          const incomingEdge = connectedEdges.find((e) => e.target === node.id);
          const outgoingEdge = connectedEdges.find((e) => e.source === node.id);

          // tip of the branch node is deleted,
          // no need to reconnect anything
          if (!outgoingEdge) {
            return remainingEdges;
          }

          // this should not be possible. We only allow start node not to have incoming edge
          // and start node if filtered in onBeforeDelete
          if (!incomingEdge) {
            return remainingEdges;
          }

          const createdEdge = {
            id: nanoid(),
            type: 'smoothstep',
            source: incomingEdge.source,
            target: outgoingEdge.target,
            label: incomingEdge.label,
            sourceHandle: incomingEdge.sourceHandle,
            markerEnd: incomingEdge.markerEnd,
          };

          return [...remainingEdges, createdEdge];
        }, edges)
      );
    },
    [edges, setEdges, workflowId]
  );

  const nodesWithExecutionData = props.recentExecution
    ? nodes.map((node) => {
        const recentExecutionData = props.recentExecution?.nodeData.find(
          (nd) => nd.id === node.id
        );

        return {
          ...node,
          data: {
            ...node.data,
            recentExecutionData,
          },
        };
      })
    : nodes;

  return (
    <div className="relative h-full w-full flex-grow bg-slate-25/50">
      <EdgeMarkerDefs />
      <EdgeStateProvider
        value={{
          hoveredEdgeId,
          reconnectingEdgeId,
        }}
      >
        <ReactFlow
          className="border-0"
          defaultEdgeOptions={{ type: 'smoothstep' }}
          nodes={nodesWithExecutionData}
          edges={edges.map((e) => ({
            ...e,
            style: { strokeWidth: 1 },
            markerEnd: { type: MarkerType.ArrowClosed },
          }))}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onReconnect={onReconnect}
          onReconnectStart={(_mouseEvent, edge) =>
            setReconnectingEdgeId(edge.id)
          }
          onReconnectEnd={() => setReconnectingEdgeId(null)}
          isValidConnection={isValidConnection}
          onConnect={onConnect}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodeOrigin={[0.5, 0]}
          maxZoom={1}
          zoomOnDoubleClick={false}
          fitView
          snapToGrid
          snapGrid={[GRID_RESOLUTION, GRID_RESOLUTION]}
          onBeforeDelete={async (data) => {
            if (data.nodes.length) {
              return data.nodes.some((n) => {
                if (n.type === 'StartNode') return false;
                return true;
              });
            }
            return true;
          }}
          onEdgesDelete={() => trackDeleteWorkflowEdge({ workflowId })}
          onEdgeMouseEnter={(e, edge) => setHoveredEdgeId(edge.id)}
          onEdgeMouseLeave={() => setHoveredEdgeId(null)}
          onNodesDelete={onNodesDelete}
          nodeTypes={nodeTypes}
          edgeTypes={{
            EdgeComponent,
            default: EdgeComponent,
            smoothstep: EdgeComponent,
          }}
          proOptions={{ hideAttribution: true }}
        >
          <Controls />
          {showVideoButtonPanel ? (
            <WatchVideoEditorControl
              onClose={() => setShowVideoButtonPanel(false)}
            />
          ) : null}
          <Background />
        </ReactFlow>
      </EdgeStateProvider>
    </div>
  );
}

export function WorkflowPreview(props: {
  nodes: Node[];
  edges: Edge[];
  setNodes: Dispatch<SetStateAction<Node[]>>;
}): ReactElement {
  const { nodes, edges, setNodes } = props;
  const onNodesChange: OnNodesChange<Node> = useCallback(
    (changes) => {
      return setNodes((nds) => {
        return adjustOverlappingNodes(
          applyNodeChanges(changes, nds).map(NodeValidator.validate) as Node[]
        );
      });
    },
    [setNodes]
  );

  return (
    <div className="relative h-full w-full flex-grow bg-slate-25/50">
      <EdgeMarkerDefs />
      <ReactFlow
        className="border-0"
        nodes={nodes}
        edges={edges.map((e) => ({
          ...e,
          style: { strokeWidth: 1 },
          type: 'smoothstep',
          markerEnd: { type: MarkerType.ArrowClosed },
        }))}
        nodeOrigin={[0.5, 0]}
        maxZoom={1}
        draggable={false}
        edgesFocusable={false}
        edgesReconnectable={false}
        elementsSelectable={false}
        nodesConnectable={false}
        onNodesChange={onNodesChange}
        nodesDraggable={false}
        nodesFocusable={false}
        zoomOnDoubleClick={false}
        fitView
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        edgeTypes={{
          default: EdgeComponent,
          smoothstep: EdgeComponent,
        }}
      >
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
}
