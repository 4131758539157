import { Menu } from '../../../components/Menu';
import {
  ArrowDown,
  ArrowRightLeft,
  ArrowUp,
  Bell,
  Check,
  CreditCard,
  DoorOpen,
  MoreVertical,
  ShieldOff,
  ShieldPlus,
  X,
} from 'lucide-react';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components/Alert';
import { Chip } from '../../../components/Chips';
import { Button } from '../../../components/buttons';
import {
  Team,
  TeamMember,
  TeamMemberStatus,
  UserTier,
} from '../../../graphql/operations';
import { trackWebEvent } from '../../../helpers/analytics';
import { TransferSeatDialog } from './TransferSeatDialog';
import { useSelector } from 'react-redux';
import {
  selectUserPlanCancelledAfterCurrentPeriod,
  selectUserTier,
} from '../../../redux/selectors';
import { RemoveMemberDialog } from './RemoveMemberDialog';
import { useRemoveInvitation } from '../../../services/Team';

/**
 * Member component
 */
export const Member: React.FC<{
  userId: string;
  isAdmin: boolean;
  teamHasOtherAdmins: boolean;
  isPaidTeam: boolean;
  team: Team;
  member: TeamMember;
  onUpgrade: (
    member: TeamMember,
    isRequest: boolean,
    source: string
  ) => Promise<void>;
  onRequestUpgrade: (
    member: TeamMember,
    source: string,
    onUpgrade: (member: TeamMember, isRequest: boolean) => Promise<void>
  ) => void;
  onDowngrade: (member: TeamMember) => void;
  onChangeRoles: (
    member: TeamMember,
    roles: Omit<TeamMember['roles'], 'COLLABORATOR'>
  ) => void;
  canMemberRequestUpgrade: (team: Team, member: TeamMember) => boolean;
}> = (props) => {
  const {
    userId,
    team,
    member,
    isAdmin,
    teamHasOtherAdmins,
    onRequestUpgrade,
    canMemberRequestUpgrade,
  } = props;
  const [isTransferSeatDialogOpen, setIsTransferSeatDialogOpen] =
    useState(false);
  const [removeMemberId, setRemoveMemberId] = useState<null | string>(null);

  const isPending = member.status === TeamMemberStatus.PENDING;
  const isCurrentUser = userId === member.uid;
  const isFreeUser = member.status === TeamMemberStatus.FREE;
  const planCancelled = useSelector(selectUserPlanCancelledAfterCurrentPeriod);
  const currentUserTier = useSelector(selectUserTier);

  const onUpgrade = useCallback(async () => {
    await props.onUpgrade(member, false, 'team_list');
  }, [member, props]);
  const onDowngrade = useCallback(() => {
    props.onDowngrade(member);
  }, [member, props]);

  const onRemoveInvitation = useRemoveInvitation();

  let status = null;
  const alerts = [];

  const personalStr = (
    <FormattedMessage defaultMessage="Personal" id="NDx+B0" />
  );
  const teamStr = <FormattedMessage defaultMessage="Team" id="wsUmh9" />;
  const cancelledStr = (
    <FormattedMessage defaultMessage=" (Pending Cancellation)" id="jUlPN6" />
  );

  switch (member.status) {
    case TeamMemberStatus.PRO:
      status = (
        <div className="flex flex-row items-center gap-4">
          {personalStr}
          {planCancelled ? cancelledStr : null}
          <Chip color="red">
            <FormattedMessage defaultMessage="Not on team plan" id="ZlTvzd" />
          </Chip>
        </div>
      );
      break;
    case TeamMemberStatus.TEAM:
      status = (
        <div className="flex flex-row gap-4">
          {teamStr}
          {planCancelled ? cancelledStr : null}
        </div>
      );
      break;
    case TeamMemberStatus.PENDING:
      status = <FormattedMessage defaultMessage="Pending..." id="99OtWT" />;
      break;
    case TeamMemberStatus.FREE:
      status = (
        <div className="flex flex-col flex-wrap justify-center gap-4">
          <FormattedMessage defaultMessage="Free" id="tf1lIh" />
        </div>
      );

      if (member.isOutOfMeetings) {
        alerts.push(
          <Alert
            key="oom"
            variant="light"
            severity="warning"
            action={
              isAdmin && team.isPaid ? (
                <Button
                  startIcon={<ArrowUp className="h-4 w-4" />}
                  variant="neutral-secondary"
                  onClick={onUpgrade}
                  upgrade
                >
                  <FormattedMessage
                    defaultMessage="Upgrade to Team"
                    id="SxBLzk"
                  />
                </Button>
              ) : null
            }
            description={
              <FormattedMessage defaultMessage="Out of meetings" id="JeIUOX" />
            }
          />
        );
      }
      break;
  }

  const actions = [];

  if (isAdmin) {
    if (!isPending) {
      if (member.roles.ADMIN) {
        if (teamHasOtherAdmins) {
          actions.push(
            <Menu.Item
              key="revoke-admin"
              onClick={async () => {
                trackWebEvent(
                  'Clicked on revoke admin permissions menu button'
                );

                props.onChangeRoles(member, {
                  ADMIN: false,
                });
              }}
              icon={<ShieldOff size="1rem" />}
            >
              <FormattedMessage
                defaultMessage="Revoke Team Admin role"
                id="NJ/0wJ"
              />
            </Menu.Item>
          );
        }
      } else {
        actions.push(
          <Menu.Item
            key="grant-editor"
            onClick={async () => {
              trackWebEvent('Clicked on grant admin permissions menu button');

              props.onChangeRoles(member, {
                ADMIN: true,
              });
            }}
            icon={<ShieldPlus size="1rem" />}
          >
            <FormattedMessage
              defaultMessage="Promote to Team Admin"
              id="6NrDhA"
            />
          </Menu.Item>
        );
      }

      if (
        member.requestedUpgrade &&
        team.isPaid &&
        (member.status === TeamMemberStatus.PRO ||
          member.status === TeamMemberStatus.FREE)
      ) {
        actions.push(
          <Menu.Item
            key="transfer"
            onClick={async () => {
              trackWebEvent('Clicked on approve request upgrade menu button');
              await onUpgrade();
            }}
            icon={<ArrowUp size="1rem" />}
          >
            <FormattedMessage defaultMessage="Upgrade to Team" id="SxBLzk" />
          </Menu.Item>
        );
      } else if (member.status === TeamMemberStatus.PRO) {
        actions.push(
          <Menu.Item
            key="transfer"
            onClick={async () => {
              trackWebEvent('Clicked on consolidate billing menu button');
              await onUpgrade();
            }}
            icon={<CreditCard size="1rem" />}
          >
            <FormattedMessage
              defaultMessage="Consolidate billing"
              id="lenCCT"
            />
          </Menu.Item>
        );
      }

      if (member.status === TeamMemberStatus.TEAM) {
        if (
          team.members.filter((m) => m.status === TeamMemberStatus.FREE).length
        ) {
          actions.push(
            <Menu.Item
              key="transfer"
              onClick={() => {
                trackWebEvent('Clicked on transfer seat button');
                setIsTransferSeatDialogOpen(true);
              }}
              icon={<ArrowRightLeft size="1rem" />}
            >
              <FormattedMessage
                defaultMessage="Transfer seat to another member"
                id="fCh18n"
              />
            </Menu.Item>
          );
        }

        actions.push(
          <Menu.Item
            key="downgrade"
            onClick={onDowngrade}
            icon={<ArrowDown size="1rem" />}
          >
            <FormattedMessage
              defaultMessage="Downgrade to Free"
              id="DfYTWq"
              description="Team view. Downgrade to Free plan button title"
            />
          </Menu.Item>
        );
      }
    }

    if (isPending) {
      actions.push(
        <Menu.Item
          key="remove-invitation"
          onClick={() => onRemoveInvitation(member)}
          className="text-red-600"
          icon={<X size="1rem" />}
        >
          <FormattedMessage
            defaultMessage="Remove invitation"
            id="t5ULv6"
            description="Team view. Remove invitation button title"
          />
        </Menu.Item>
      );
    } else if (!isCurrentUser) {
      if (actions.length) actions.push(<Menu.Divider key="divider" />);
      actions.push(
        <Menu.Item
          key="remove"
          onClick={() => setRemoveMemberId(member.uid)}
          className="text-red-600"
          icon={<X size="1rem" />}
        >
          <FormattedMessage
            defaultMessage="Remove from team"
            id="XUN4ev"
            description="Team view. Remove from team button title"
          />
        </Menu.Item>
      );
    }
  }

  if (isCurrentUser) {
    if (actions.length) actions.push(<Menu.Divider key="divider" />);
    actions.push(
      <Menu.Item
        key="remove"
        onClick={() => setRemoveMemberId(member.uid)}
        className="text-red-600"
        icon={<DoorOpen size="1rem" />}
      >
        <FormattedMessage
          defaultMessage="Leave team"
          id="Aob3UV"
          description="Team view. Leave team button title"
        />
      </Menu.Item>
    );
  }

  let actionsMenu = null;

  if (actions.length) {
    actionsMenu = (
      <Menu className="max-w-80">
        <Menu.Trigger>
          <Button variant="icon">
            <MoreVertical className="h-5 w-5 text-slate-600" />
          </Button>
        </Menu.Trigger>
        {actions}
      </Menu>
    );
  }

  if (member.requestedUpgrade && member.status !== TeamMemberStatus.TEAM) {
    alerts.push(
      <Alert
        key="ru"
        alternateIcon={<Bell className="h-4 w-4" />}
        severity="info"
        variant="light"
        action={
          isAdmin ? (
            <Button
              startIcon={<Check className="h-4 w-4" />}
              variant="neutral-secondary"
              onClick={onUpgrade}
            >
              <FormattedMessage defaultMessage="Approve" id="WCaf5C" />
            </Button>
          ) : null
        }
        description={
          <FormattedMessage defaultMessage="Requested upgrade" id="xEzyWv" />
        }
      />
    );
  }

  let btnStatusUpgrade;

  if (
    isAdmin &&
    !member.requestedUpgrade &&
    !member.isOutOfMeetings &&
    (isFreeUser || isPending || member.status === TeamMemberStatus.PRO)
  ) {
    if (member.status === TeamMemberStatus.PRO) {
      btnStatusUpgrade = (
        <Button startIcon={<ArrowUp className="h-4 w-4" />} onClick={onUpgrade}>
          <FormattedMessage
            defaultMessage="Upgrade to the Team Plan"
            id="drJyUp"
          />
        </Button>
      );
    } else if (member.status === TeamMemberStatus.FREE && team.isPaid) {
      btnStatusUpgrade = (
        <Button
          startIcon={<ArrowUp className="h-4 w-4" />}
          upgrade
          variant="neutral-secondary"
          onClick={onUpgrade}
        >
          <FormattedMessage
            defaultMessage="Upgrade to Team"
            id="rPiytg"
            description="Team view. Upgrade to Team plan button title"
          />
        </Button>
      );
    } else if (
      member.status === TeamMemberStatus.FREE &&
      !team.isPaid &&
      currentUserTier === UserTier.PRO
    ) {
      btnStatusUpgrade = (
        <Chip color="yellow">
          <FormattedMessage
            defaultMessage="Upgrade the Team to assign seats"
            id="nHvehd"
          />
        </Chip>
      );
    }
  } else if (canMemberRequestUpgrade(team, member)) {
    btnStatusUpgrade = (
      <Button
        startIcon={<ArrowUp className="h-4 w-4" />}
        onClick={() => {
          onRequestUpgrade(member, 'team_list', onUpgrade);
        }}
        upgrade
        variant="neutral-secondary"
      >
        <FormattedMessage
          defaultMessage="Request upgrade to Team"
          id="Jd9MuB"
          description="Team view. Request upgrade to Team plan button title"
        />
      </Button>
    );
  } else if (isAdmin && team.isPaid && member.status === TeamMemberStatus.PRO) {
    btnStatusUpgrade = (
      <Button
        startIcon={<ArrowUp className="h-4 w-4" />}
        onClick={async () => {
          trackWebEvent('Clicked on consolidate billing button');
          await onUpgrade();
        }}
      >
        <FormattedMessage defaultMessage="Consolidate billing" id="lenCCT" />
      </Button>
    );
  }

  return (
    <div className="flex items-center gap-2 overflow-auto">
      {/* Plan */}
      <div className="mr-auto flex flex-1 flex-col gap-2 text-sm">
        <div className="flex w-full flex-wrap justify-between gap-2">
          {status}
          {btnStatusUpgrade}
        </div>
        {alerts}
      </div>

      {actionsMenu}

      {isTransferSeatDialogOpen && (
        <TransferSeatDialog
          source={member}
          onClose={() => {
            setIsTransferSeatDialogOpen(false);
          }}
        />
      )}
      {removeMemberId && (
        <RemoveMemberDialog
          onClose={() => setRemoveMemberId(null)}
          member={member}
          team={team}
        />
      )}
    </div>
  );
};
