import {
  DataCollectionListRequest,
  DataCollectionListResponse,
  IntegrationAppClient as IntegrationAppClientSdk,
} from '@integration-app/sdk';
import { IntegrationAppClient as IntegrationAppClientReact } from '@integration-app/react';

export async function listAllFromIntegration(
  // didn't quite liked that but the api client types are different from the sdk and react
  api: IntegrationAppClientSdk | IntegrationAppClientReact,
  connectionType: string,
  collectionKey: string,
  numberOfPages: number = Number.POSITIVE_INFINITY,
  filter?: DataCollectionListRequest['filter']
): Promise<{ records: DataCollectionListResponse['records'] }> {
  let items: DataCollectionListResponse['records'] = [];
  let fetchMore = true;
  let cursor: string | undefined;

  let count = 0;

  while (fetchMore) {
    count++;

    try {
      const response = await api
        .connection(connectionType)
        .dataCollection(collectionKey)
        .list({ cursor, filter });

      items = items.concat(response.records);

      if (
        !response.cursor ||
        cursor === response.cursor ||
        // At this point the request will be taking far too long for our customers and
        // the problem should be solved in another way
        count >= numberOfPages
      ) {
        fetchMore = false;
      } else {
        cursor = response.cursor;
      }
    } catch (error: any) {
      // Back off for a little bit if we get a 429
      if (error.data.key === 'rate_limit_exceeded') {
        const resetHeader =
          error.data.data.response.headers['x-rate-limit-reset'];
        const canReset = Number.parseInt(resetHeader, 10) * 1000;

        await new Promise((resolve) =>
          setTimeout(resolve, canReset - Date.now())
        );
      }
    }
  }

  return { records: items };
}
