import React, { ReactElement, ReactNode } from 'react';
import {
  Check,
  ChevronDown,
  Folder,
  LifeBuoy,
  Plus,
  Wand2,
} from 'lucide-react';
import { Menu } from '../../../../components/Menu';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/buttons';
import { selectUsedMeetingKitsSelector } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';
import { EmojiField } from '../../../Common/icons/EmojiField';
import Magic from '../../../Common/icons/Magic';
import { RootState } from '../../../../redux/store';
import { AvailablePrompt } from './AIGenerated';
import { kMeetingKits } from '../../../../helpers/routes';
import { trackWebEvent } from '../../../../helpers/analytics';

/**
 * A nested dropdown with all the remaining AI Tools and Meeting Kits
 */
export function MoreAiToolsMenu(props: {
  loading: boolean;
  quickPromptsLength?: number;
  usedPrompts: Set<string>;
  trigger?: ReactElement;
  onClick: (id: AvailablePrompt) => void;
}): React.ReactElement<typeof Menu> {
  const {
    onClick,
    usedPrompts,
    loading,
    trigger,
    quickPromptsLength = 6,
  } = props;
  const usedKits = useSelector(selectUsedMeetingKitsSelector);
  const exploreKits = useSelector(
    (state: RootState) => state.global.meetingKits.explore
  );
  const systemKit = useSelector(
    (state: RootState) => state.global.meetingKits.system
  );
  const icon = (item: { icon: string }, fallback: ReactNode) =>
    item.icon ? (
      <EmojiField
        value={item.icon}
        defaultIcon={<Wand2 className="h-4 w-4 text-slate-600" />}
        EmojiProps={{
          unified: item.icon,
          size: 20,
        }}
      />
    ) : (
      fallback
    );

  const usedKitIds = new Set(
    usedKits.flatMap((kit) => [kit.id, kit.owner.sourceId]).filter(Boolean)
  );

  const remainingSystemKits = systemKit.items.slice(quickPromptsLength);

  return (
    <Menu
      placement="bottom"
      onOpenChange={(isOpen) => {
        if (isOpen) {
          trackWebEvent('Transcript AI Tools Viewed More');
        }
      }}
    >
      <Menu.Trigger>
        {trigger || (
          <Button
            className="w-full justify-center font-medium"
            loading={loading}
            endIcon={<ChevronDown size="1rem" />}
            variant="neutral-tertiary"
          >
            <FormattedMessage defaultMessage="View all AI prompts" />
          </Button>
        )}
      </Menu.Trigger>
      {remainingSystemKits.length > 0 ? (
        <>
          {remainingSystemKits.map((item, index) => {
            const used = usedPrompts.has(item.id);
            return (
              <Menu.Item
                onClick={() =>
                  onClick({
                    ...item,
                    type: 'system',
                    title: item.name,
                    meetingKitId: systemKit.id,
                    meetingKitItemId: item.id,
                  })
                }
                disabled={used}
                className={used ? 'bg-green-100' : undefined}
                icon={used ? <Check size="1.25rem" /> : icon(item, <Magic />)}
                key={item.id ?? index}
              >
                {item.name}
              </Menu.Item>
            );
          })}
          <Menu.Divider />
        </>
      ) : null}
      <Menu.Label>Meeting Kits</Menu.Label>
      {[
        ...exploreKits
          .filter((kit) => !usedKitIds.has(kit.id)) // remove any that are in usedKits already
          .map((kit) => ({ ...kit, type: 'explore' as const })),
        ...usedKits.map((kit) => ({ ...kit, type: 'used' as const })),
      ].map((kit) => (
        <Menu
          key={kit.type + kit.id}
          icon={icon(kit, <Folder size="1.25rem" />)}
          label={kit.name}
        >
          {kit.items.length === 0 ? (
            <Menu.Item disabled key="empty">
              Empty
            </Menu.Item>
          ) : (
            kit.items.map((item, index) => {
              const used = usedPrompts.has(item.id);
              return (
                <Menu.Item
                  onClick={() =>
                    onClick({
                      ...item,
                      type: kit.type,
                      title: item.name,
                      meetingKitId: kit.id,
                      meetingKitItemId: item.id,
                    })
                  }
                  className={used ? 'bg-green-100' : undefined}
                  disabled={used}
                  icon={used ? <Check size="1.25rem" /> : icon(item, <Magic />)}
                  key={item.id ?? index}
                >
                  {item.name}
                </Menu.Item>
              );
            })
          )}
        </Menu>
      ))}
      <Menu.Divider />
      <Menu.Item as="link" icon={<Plus size="1.25rem" />} to={kMeetingKits}>
        <FormattedMessage defaultMessage="Add new kit" id="P6Blha" />
      </Menu.Item>
      <Menu.Item
        as="a"
        icon={<LifeBuoy size="1.25rem" />}
        target="_blank"
        href="https://help.tactiq.io/en/articles/7907775-large-language-models-gpt-openai-privacy-and-tactiq"
      >
        <FormattedMessage defaultMessage="Help on using AI" id="gQP2qW" />
      </Menu.Item>
    </Menu>
  );
}
