import React, { ReactElement, useEffect, useId, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { SingleSourceNode, WorkflowNodeProps } from './BaseNode';
import { useConnections } from '../../../services/Sharing';
import { AllIntegrations } from '../../Integration/integrations';
import { Select } from '../../../components/Select';
import { Mail, Blocks as ShareIcon } from 'lucide-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { api2post } from '../../../helpers/api2';
import { Combobox } from '../../../components/Combobox';
import { LiquidTemplateInput } from '../../../components/LiquidTemplateInput';
import { TextInput } from '../../../components/TextInput';
import { useAutocomplete } from './useAutocomplete';
import { useWorkflowId } from '../WorkflowIdContext';
import { useIsPreview } from './useIsPreview';
import { minHeights } from './minHeights';

export function Share(
  props: WorkflowNodeProps<{
    type: 'slack' | 'email';
    bodyTemplate?: string;
    slack?: {
      id: string;
      name: string;
      scope: string;
      connectionKey: string;
    };
    email?: {
      to: string;
      subject: string;
    };
  }>
): ReactElement {
  const intl = useIntl();
  const { id, data } = props;
  const { type } = data;
  const connections = useConnections();
  const connectionSet = new Set(['slack', 'email']);
  const userSet = new Set(connections.data?.map((ii) => ii.type));
  const reactFlow = useReactFlow();
  const autocomplete = useAutocomplete(props);

  const conn = connections.data?.find((c) => c.type === type);
  const { connectionKey, scope } = conn ?? {};
  const { workflowId } = useWorkflowId();
  const isExecution = Boolean(data.execution);
  const isPreview = useIsPreview();
  const messageId = useId();

  return (
    <SingleSourceNode
      workflowNode={props}
      icon={
        <ShareIcon className="size-8 rounded-lg border border-pink-500/10 bg-pink-50 p-1.5 text-pink-500" />
      }
      minHeight={minHeights.Share}
      contentClassName="h-full"
    >
      <div className="flex flex-grow flex-col gap-3">
        <div className="nodrag">
          <Select
            value={type}
            disabled={isExecution}
            onChange={(type) =>
              reactFlow.updateNodeData(id, {
                type,
                connectionKey: conn?.connectionKey,
                scope: conn?.scope,
              })
            }
            full
            options={
              AllIntegrations.filter(
                (c) =>
                  (connectionSet.has(c.id) && userSet.has(c.id)) ||
                  c.id === 'email'
              ).map((c) => {
                const Icon = c.icon;
                return {
                  icon:
                    c.id === 'email' ? (
                      <Mail className="size-4" />
                    ) : (
                      <img className="size-4" src={Icon} alt={c.title} />
                    ),
                  label: c.title,
                  value: c.id,
                };
              }) ?? []
            }
          />
        </div>
        {type === 'slack' && (
          <SlackSelect
            execution={isExecution}
            connectionKey={conn?.connectionKey ?? ''}
            value={data[type]}
            onChange={(next) =>
              reactFlow.updateNodeData(id, {
                [type]: { ...next, connectionKey, scope },
              })
            }
          />
        )}
        {type === 'email' && (
          <EmailSelect
            execution={isExecution}
            value={data[type]}
            onChange={(next) =>
              reactFlow.updateNodeData(id, {
                [type]: { ...next, connectionKey, scope },
              })
            }
          />
        )}

        <div className="flex flex-grow flex-col">
          <div className="flex flex-col gap-y-3">
            <label
              htmlFor={messageId}
              className="block font-semibold text-slate-600 text-sm leading-6"
            >
              <FormattedMessage defaultMessage="Message" />
            </label>
            <LiquidTemplateInput
              id={messageId}
              className="min-h-12 text-base"
              properties={autocomplete.properties}
              variables={autocomplete.variables}
              value={data.bodyTemplate ?? ''}
              onChange={(bodyTemplate) =>
                reactFlow.updateNodeData(id, { bodyTemplate })
              }
              workflowId={workflowId}
              nodeType={props.type}
              disabled={isExecution || isPreview}
              placeholder={intl.formatMessage({
                defaultMessage: 'Email body',
              })}
              ariaLabel={intl.formatMessage({
                defaultMessage: 'Email message input',
              })}
            />
          </div>
        </div>
      </div>
    </SingleSourceNode>
  );
}

const SlackSelect: React.FC<{
  connectionKey: string;
  value?: { id: string; name?: string };
  onChange: (options: { id: string } | null) => void;
  execution: boolean;
}> = (props) => {
  const { onChange, value, connectionKey, execution } = props;
  const [channels, setChannels] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    const fn = async () => {
      const result = await api2post('a/integrations/slack/channels', {
        connectionKey,
      });
      setChannels(result.channels);
    };

    if (connectionKey) fn().catch(() => {});
  }, [connectionKey]);

  return (
    <Combobox
      buttonClasses="nodrag"
      label={<FormattedMessage defaultMessage="channel" id="rFxi1+" />}
      multiple={false}
      id={(i) => i.id}
      name={(i) => i.name ?? ''}
      value={value}
      onChange={onChange}
      options={channels}
      disabled={execution}
      full
    />
  );
};

type EmailValue = { to: string; subject: string };
const EmailSelect: React.FC<{
  execution: boolean;
  value?: EmailValue;
  onChange: (options: EmailValue | undefined) => void;
}> = (props) => {
  const { onChange, value, execution } = props;

  return (
    <div>
      <TextInput
        disabled={execution}
        label="Subject"
        value={value?.subject}
        onChange={(subject) => onChange({ to: '', ...value, subject })}
      />
    </div>
  );
};
