import React, { useMemo, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import {
  trackListIsReindexing,
  TierPricingDialogSource,
} from '../../../helpers/analytics';
import { MeetingCard } from '../card';
import { useSelector } from 'react-redux';
import {
  selectIsProUser,
  selectLabels,
  selectLastRenewDate,
  selectTeam,
  selectUserTier,
} from '../../../redux/selectors';
import { AnyMeeting, BasicMeeting } from '../../../models/meeting';
import { getNextRenewalDateString } from '../../../helpers/utils';
import { PreviewMeetingsAlert } from './PreviewMeetingsAlert';
import { JoinTeamAlert } from '../../Common/JoinTeamAlert';
import { formatDate } from '../common/formatDate';
import { RootState } from '../../../redux/store';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { getMeetingLabels } from '../../../helpers/labels';
import { SearchStatus } from '../../../graphql/operations';
import { Alert } from '../../../components/Alert';
import { Loading } from '../../Landing/Loading/LoadingComponent';
import { isMeetingOwner } from '../../../helpers/meetings';
import { TranscriptListSkeleton } from '../view/skeleton/TranscriptSkeleton';

/**
 * isMeetingCreatedInTheCurrentCycle
 * @param {BasicMeeting} meeting meeting
 * @param {number} lastRenewDate last renew date
 * @returns {boolean} boolean
 */
const isMeetingCreatedInTheCurrentCycle = (
  meeting: BasicMeeting,
  lastRenewDate: number
): boolean => {
  return meeting.created >= lastRenewDate;
};

const Divider: React.FunctionComponent<React.PropsWithChildren> = ({
  children,
}) => (
  <div className="flex items-center">
    <div className="flex-grow border-slate-200 border-t" />
    <div className="mx-2">{children}</div>
    <div className="flex-grow border-slate-200 border-t" />
  </div>
);

interface TranscriptsListProps {
  meetings: BasicMeeting[];
  uploadingMeetings?: BasicMeeting[];
  previewMeetingsToCleanup?: BasicMeeting[];
  lastRenewal?: number;
  showRefreshCycleDivider?: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  loadMore: () => void;
  searchBar?: React.ReactNode;
  emptyMessage?: React.ReactNode;
}

/**
 * Transcript List
 * @param {unknown} param0 params
 * @returns {React.FC<TranscriptsListProps>} component
 */
export const LegacyMeetingList: React.FC<TranscriptsListProps> = ({
  meetings,
  uploadingMeetings,
  previewMeetingsToCleanup = [],
  lastRenewal = Date.now(),
  showRefreshCycleDivider = false,
  isLoading,
  loadMore,
  isLoadingMore,
  searchBar,
  emptyMessage,
}) => {
  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('q') ?? '';
  const labelQuery = searchParams.get('l') ?? '';
  const intl = useIntl();
  const lastRenewDate = useSelector(selectLastRenewDate);
  const isProUser = useSelector(selectIsProUser);
  const searchStatus = useSelector(
    (state: RootState) => state.user.searchStatus
  );

  const userId = useSelector((state: RootState) => state.user.id);

  useEffect(() => {
    // track list views where users see "Your meeting is being prepared. Please wait."
    if (searchStatus !== SearchStatus.READY) {
      trackListIsReindexing(userId);
    }
  }, [searchStatus, userId]);

  const labels = useSelector(selectLabels);
  const meetingsWithLabels = useMemo(() => {
    return meetings.map((m) => ({
      ...m,
      labels: getMeetingLabels(m, meetings, labels, isMeetingOwner(userId, m)),
    }));
  }, [meetings, labels, userId]);

  const transcripts = [...meetingsWithLabels].sort((a, b) => {
    return b.created - a.created;
  });

  const transcriptList: (string | 'cycle-break' | AnyMeeting[])[] = [];
  for (let i = 0; i < transcripts.length; i++) {
    const meeting = transcripts[i];
    const prevMeeting = i > 0 ? transcripts[i - 1] : undefined;
    const date = formatDate(meeting.created);
    const isSameDate =
      transcriptList.length > 0 &&
      formatDate(transcripts[i - 1].created) === date;

    if (!isSameDate) {
      transcriptList.push(date);
    }

    const isPrevCycle =
      showRefreshCycleDivider &&
      prevMeeting &&
      isMeetingCreatedInTheCurrentCycle(prevMeeting, lastRenewDate) &&
      !isMeetingCreatedInTheCurrentCycle(meeting, lastRenewDate);

    if (isPrevCycle) {
      transcriptList.push('cycle-break');
    }

    if (!Array.isArray(transcriptList[transcriptList.length - 1])) {
      transcriptList.push([meeting]);
    } else {
      (transcriptList[transcriptList.length - 1] as AnyMeeting[]).push(meeting);
    }
  }

  const uploadingLabel = intl.formatMessage({
    defaultMessage: 'Uploading:',
    id: 'Uvjqex',
  });

  if (uploadingMeetings?.length) {
    transcriptList.unshift(uploadingMeetings);
    transcriptList.unshift(uploadingLabel);
  }

  let content = null;

  if (searchStatus !== SearchStatus.READY) {
    content = (
      <div className="mt-8">
        <Alert
          severity="info"
          description={
            <FormattedMessage
              defaultMessage="Your meeting list is being prepared. Please wait."
              id="w0Ei0g"
            />
          }
          action={<Loading />}
        />
      </div>
    );
  } else if (isLoading) {
    content = <TranscriptListSkeleton />;
  } else {
    content = (
      <div className="flex">
        <div className="table flex-1 border-collapse text-[#052a57]">
          <Virtuoso
            data={transcriptList}
            endReached={loadMore}
            increaseViewportBy={250}
            itemContent={(index, item) => {
              if (typeof item === 'string') {
                if (item === 'cycle-break') {
                  return (
                    <div className="mt-4 mb-4">
                      <Divider>
                        <span className="text-xs">
                          <FormattedMessage
                            defaultMessage="Free meetings will be renewed on {renewDate}"
                            id="uYwbZp"
                            values={{
                              renewDate:
                                getNextRenewalDateString(lastRenewDate),
                            }}
                          />
                        </span>
                      </Divider>
                    </div>
                  );
                } else {
                  return (
                    <h2
                      className="mt-6 pb-2 font-semibold text-lg text-slate-800"
                      key={index}
                    >
                      {item}
                    </h2>
                  );
                }
              }

              return (
                <span>
                  {item.map((m) => (
                    <MeetingCard key={m.id} meeting={m} />
                  ))}
                </span>
              );
            }}
            useWindowScroll
          />

          {isLoadingMore ? (
            <div className="mt-[250px] flex items-center justify-center">
              <Loading />
            </div>
          ) : null}

          {!transcriptList.length && (query || labelQuery) ? (
            <div>
              <FormattedMessage
                defaultMessage="No matches"
                id="K1xBdW"
                description="Transcripts page. No matches found message."
              />
            </div>
          ) : null}
          {!transcriptList.length && !query && !labelQuery ? (
            <div>
              {emptyMessage ?? (
                <FormattedMessage
                  defaultMessage="No transcripts yet, you should go have some meetings!"
                  id="qtIcwN"
                  description="Transcripts page. No meetings found message."
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 flex flex-col gap-4 pb-40">
      <JoinTeamAlert />

      {searchBar}

      {previewMeetingsToCleanup.length > 0 && !isProUser && (
        <PreviewMeetingsAlert
          lastRenewal={lastRenewal}
          meetingsCount={previewMeetingsToCleanup.length}
          onUpgrade={() => setShowTierPricingDialog(true)}
        />
      )}

      {content}

      {showTierPricingDialog ? (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.PREVIEW_TRANSCRIPT}
          onClose={() => setShowTierPricingDialog(false)}
        />
      ) : null}
    </div>
  );
};
