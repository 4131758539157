import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Connection,
  DataSourceInstance,
  Integration,
  IntegrationAppClient,
  IntegrationAppProvider,
  PaginationResponse,
  useIntegrationApp,
  DataSchema,
  DataCollectionListResponse,
  DataCollectionSearchResponse,
  DataCollectionListRequest,
} from '@integration-app/react';
import { useQuery } from '@apollo/client';
import {
  IntegrationTokenDocument,
  ConnectionAccessTokenDocument,
} from '../graphql/operations';
import useSWR, { SWRResponse, useSWRConfig } from 'swr';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';
import featureFlagService from '../helpers/feature-flags';
import { listAllFromIntegration } from '@tactiq/model';
import {
  trackIntegrationConnected,
  trackIntegrationDisconected,
} from '../helpers/analytics';
import { QueryResult } from './helpers';

// Re-exports
export type { DataSchema };

const WORKFLOW_DATA_SOURCE = 'workflows-data-source';

/**
 * Provider for the integration app recat sdk
 * Handles fetching the token for the current user
 */
export function IntegrationProvider(props: {
  children: ReactNode;
}): ReactElement {
  const query = useQuery(IntegrationTokenDocument, { skip: true });

  const fetchToken = useCallback(async () => {
    const request = await query.refetch();
    return request.data?.integrationToken ?? '';
  }, [query]);

  return (
    <IntegrationAppProvider fetchToken={fetchToken}>
      {props.children}
    </IntegrationAppProvider>
  );
}

/**
 * Hook to access the integration app client.
 * This should probably be locked down and consumed through other hooks from this file
 */
export function useIntegration(): IntegrationAppClient {
  return useIntegrationApp();
}

//
// Integrations
//

/**
 * Fetch details about a single integration
 * This is used to determine how create a connection to it
 */
export function useIntegrationGet(type: string): SWRResponse<Integration> {
  const api = useIntegration();
  return useSWR(['integration', type], () => api.integration(type).get());
}

//
// Connections
//

/**
 * Fetch a list of possible connections
 */
export function useConnectionList(): SWRResponse<
  PaginationResponse<Integration>
> {
  const api = useIntegration();
  const avaiableIntegrations = featureFlagService.getAvailableIntegrations();

  return useSWR(
    'connectionList',
    async () => {
      const integrations = await api.integrations.find();
      integrations.items = integrations.items.filter((ii) =>
        avaiableIntegrations.has(ii.key)
      );
      return integrations;
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the response is always the same unless we add a new integration
    }
  );
}

/**
 * Create a new Connection
 */
export function useConnectionCreate(
  type: string,
  parameters: unknown
): SWRMutationResponse<Connection | undefined, unknown, string[], string> {
  const api = useIntegration();
  const { mutate } = useSWRConfig();
  return useSWRMutation(['connection', type], async (_, { arg }) => {
    const connection = await api
      .integration(type)
      .connect({ parameters, authOptionKey: arg });
    trackIntegrationConnected({ integration: type });
    await mutate('connectionList');
    return connection;
  });
}

/**
 * Disconnect an existing Connection
 */
export function useConnectionDisconnect(
  type: string
): SWRMutationResponse<void> {
  const api = useIntegration();
  const { mutate } = useSWRConfig();
  return useSWRMutation(['connection', type], async () => {
    const response = await api.integration(type).disconnect();
    await mutate('connectionList');
    trackIntegrationDisconected({ integration: type });
    return response;
  });
}

/**
 *
 */
export function useConnectionToken(
  type: string
): QueryResult<typeof ConnectionAccessTokenDocument> {
  const { data, error, loading } = useQuery(ConnectionAccessTokenDocument, {
    variables: { key: type },
  });
  return { data, error, loading };
}

//
// Data Sources
//

/**
 * Fetch/Create a new data source for a single node
 */
export function useDataSource(config?: {
  type: string;
  instanceKey: string;
  autoCreate?: boolean;
}): SWRResponse<DataSourceInstance> {
  const api = useIntegration();
  return useSWR(
    config ? ['dataSource', config.type, config.instanceKey] : null,
    ([, type, instanceKey]) => {
      return api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .get({ autoCreate: config?.autoCreate ?? true });
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the response is always the same unless the user changes the data source
    }
  );
}
/**
 * refresh
 */
export function useDataSourceRefresh(config: {
  type: string;
  instanceKey: string;
}): SWRMutationResponse<DataSourceInstance, unknown, string[], undefined> {
  const { type, instanceKey } = config;
  const api = useIntegration();
  return useSWRMutation(
    config ? ['dataSource', type, instanceKey] : null,
    () => {
      return api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .setup();
    }
  );
}

/**
 * Update the data source
 * */
export function useDataSourceUpdate(config: {
  type: string;
  collectionKey: string;
  instanceKey: string;
}): SWRMutationResponse<
  DataSourceInstance,
  unknown,
  string[],
  Record<string, string>
> {
  const { type, collectionKey, instanceKey } = config;
  const api = useIntegration();

  return useSWRMutation(
    ['dataSource', config.type, config.instanceKey],
    async (_, { arg }) => {
      const response = await api
        .connection(type)
        .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
        .patch({
          collectionKey,
          collectionParameters: arg,
        });

      return response;
    },
    {
      populateCache: true,
    }
  );
}

/**
 * Open the configuration modal for a data source
 */
export function useDataSourceConfigure(config: {
  type: string;
  instanceKey: string;
}): SWRMutationResponse<void> {
  const { type, instanceKey } = config;
  const api = useIntegration();
  return useSWRMutation('dataSourceConfigure', () => {
    return api
      .connection(type)
      .dataSource(WORKFLOW_DATA_SOURCE, { instanceKey })
      .openConfiguration();
  });
}

//
// Data Collections
//

/**
 * Fetch the list of data collections
 * */
export function useDataCollectionList(config: {
  type: string;
  collectionKey: string;
  filter?: DataCollectionListRequest['filter'];
}): SWRResponse<DataCollectionListResponse> {
  const api = useIntegration();
  return useSWR(
    ['dataCollectionList', config.type, config.collectionKey],
    async () => {
      return listAllFromIntegration(
        api,
        config.type,
        config.collectionKey,
        Number.POSITIVE_INFINITY,
        config.filter
      );
    },
    {
      revalidateOnFocus: false, // Prevents revalidation on tab change, the request takes really long
    }
  );
}

/**
 * Search for items in a data collection
 */
export function useDataCollectionSearch(config: {
  type: string;
  collectionKey: string;
}): SWRMutationResponse<
  DataCollectionSearchResponse,
  unknown,
  string[],
  string
> {
  const api = useIntegration();
  return useSWRMutation(
    ['dataCollectionSearch', config.type, config.collectionKey],
    async (_, { arg }) => {
      return await api
        .connection(config.type)
        .dataCollection(config.collectionKey)
        .search({ query: arg });
    }
  );
}

export function useInstanceKey(workflowId: string, nodeId: string): string {
  return `${workflowId}::${nodeId}`;
}

/**
 * Get the name of a google drive folder based on an id
 * This method only exists because the workflow stores the folder id,
 * but the drive picker needs to render the name
 */
export function useGoogleDriveFolder(config: {
  folderId: string;
}): SWRResponse<{ id: string; name: string }> {
  const api = useIntegration();
  return useSWR(
    config.folderId ? ['googleDriveFolder', config.folderId] : null,
    async ([_, folderId]) => {
      // This is the key for the integration.app operation to fetch a
      // google drive file/folder. It's the same in production as in development
      // as it's a base 64 hash of a json blob that describes the api operation.
      const FETCH_FILE_OPERATION_KEY =
        'b64-eyJ0Ijoib3BlbmFwaSIsInAiOiIvZmlsZXMve2ZpbGVJZH0iLCJtIjoiR0VUIn0=';

      const { output } = await api
        .connection('googledrive')
        .operation(FETCH_FILE_OPERATION_KEY)
        // @ts-expect-error - pathParameters does exist
        .run({ pathParameters: { fileId: folderId } });

      return { id: output.id, name: output.name };
    }
  );
}
