import React, { useState } from 'react';
import {
  useConnectionToken,
  useGoogleDriveFolder,
} from '../../services/Integration';
import { FormattedMessage } from 'react-intl';
import useDrivePicker from 'react-google-drive-picker';
import { Button } from '../../components/buttons';
import { google_client_id } from '../../helpers/firebase/config';
import { cx } from '../../helpers/utils';
import { developerKey } from '../Integration/integrations/google-drive';

/**
 * This opens up the google drive picker ui to choose a folder id.
 * We chose this rather than using Integration.app's data as it requires less permissions
 * (our google app has very little, and we don't want to broaden them and go through review)
 */
export function GoogleDriveFolderSelect(props: {
  value: string[];
  disabled: boolean;
  onChange: (next: string[]) => void;
}) {
  const [openPicker] = useDrivePicker();
  const folderRequest = useGoogleDriveFolder({ folderId: props.value[0] });
  const [tempName, setTempName] = useState<string>();
  const token = useConnectionToken('googledrive');
  if (token.error) throw token.error;

  const onClick = () => {
    if (!token.data) return;
    setTempName(undefined);
    openPicker({
      clientId: google_client_id,
      developerKey: developerKey(),
      token: token.data.connectionAccessToken,
      supportDrives: true,
      multiselect: false,
      disableDefaultView: true,
      // Default only search of owned folder. This attempts to stop them selecting a folder they don't have write access to
      customViews: [
        // @ts-ignore - this attached to the window via react-google-drive-picker
        new google.picker.DocsView('folders')
          .setQuery('owner:me')
          .setMimeTypes('application/vnd.google-apps.folder')
          .setIncludeFolders(true)
          .setSelectFolderEnabled(true),
      ],
      callbackFunction: (data) => {
        const folder = data.docs?.[0];
        props.onChange(folder ? [folder.id] : []);
        folder && setTempName(folder.name);
      },
    });
  };
  return (
    <div className="nodrag text-sm">
      <div
        className={cx(
          'flex items-center justify-between rounded-input border border-slate-200 p-2 px-3',
          folderRequest.isLoading && !tempName ? 'text-slate-300' : ''
        )}
      >
        {tempName ?? folderRequest.data?.name ?? (
          <FormattedMessage defaultMessage="My Drive" />
        )}
        {!props.disabled && (
          <Button
            size="small"
            className="h-6"
            variant="neutral-secondary"
            loading={token.loading}
            onClick={onClick}
          >
            <FormattedMessage defaultMessage="Choose folder" />
          </Button>
        )}
      </div>
    </div>
  );
}
