import React from 'react';
import { useSelector } from 'react-redux';
import icon from '../../../img/msteams.png';
import {
  IntegrationCategory,
  IntegrationImplementation,
} from '../../../models/integration';
import { RootState } from '../../../redux/store';
import { ButtonRequestAllPermissions } from '../../Setup/ButtonRequestAllPermissions';
import { Alert } from '../../../components/Alert';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../components/buttons';
import { trackWebEvent } from '../../../helpers/analytics';
import { PermissionsGrantedStatus } from '../../../redux/modules/global';

export const MSTeamsSetupStatus: React.FC = () => {
  const arePermissionsGranted = useSelector(
    (state: RootState) => state.global.integrations?.arePermissionsGranted
  );

  if (arePermissionsGranted) {
    return (
      <Alert
        severity="default"
        description={
          <FormattedMessage
            defaultMessage="Tactiq is fully configured for this browser"
            id="DpW8Rz"
          />
        }
        action={
          <Button
            target="_blank"
            href="https://teams.microsoft.com/v2/"
            color={'success'}
            onClick={() => {
              trackWebEvent('Integration Page Button - Try in MS Teams');
            }}
          >
            <FormattedMessage
              defaultMessage="Try in {brand}"
              id="ZZMTii"
              values={{ brand: 'Microsoft Teams' }}
            />
          </Button>
        }
      ></Alert>
    );
  } else {
    return (
      <div className="flex flex-col gap-4">
        <Alert
          severity="success"
          description={
            <FormattedMessage
              defaultMessage="Tactiq will work at {link}"
              id="EP1YGX"
              values={{
                link: (
                  <a
                    target="_blank"
                    href="https://teams.microsoft.com/v2/"
                    rel="noreferrer"
                  >
                    https://teams.microsoft.com
                  </a>
                ),
              }}
            />
          }
        />
        <Alert
          severity="warning"
          action={
            <ButtonRequestAllPermissions
              event="Microsoft Teams Setup Helper - Fix Permissions"
              label="Fix"
            />
          }
          description={
            <FormattedMessage
              defaultMessage="Tactiq needs additional permissions to support Microsoft Teams in your browser"
              id="fe5tkh"
            />
          }
        />
      </div>
    );
  }
};

const integration: IntegrationImplementation = {
  id: 'msteams',
  category: IntegrationCategory.Meetings,
  order: 1,
  title: 'Microsoft Teams',
  icon,
  helpUrl:
    'https://help.tactiq.io/en/articles/9560779-how-to-transcribe-microsoft-teams-meetings-with-tactiq',
  description: MSTeamsSetupStatus,
  connector: () => <></>,
  isConnected: (integrationsState: any) =>
    integrationsState.arePermissionsGranted ===
    PermissionsGrantedStatus.GRANTED,
};

export default integration;
