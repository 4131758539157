import { useReactFlow, MarkerType, Node, Edge } from '@xyflow/react';
import { Button } from '../../components/buttons';
import {
  PlusIcon,
  Layers as TemplateIcon,
  ThumbsUp as ConfirmationIcon,
  GitFork as ConditionIcon,
  ListTodo,
  Blocks,
  Sparkle,
  ArrowDownToDot,
  Split,
} from 'lucide-react';
import { Menu } from '../../components/Menu';
import { nanoid } from 'nanoid';
import React, { ReactElement } from 'react';
import { useWorkflowId } from './WorkflowIdContext';
import { CustomPromptData } from './nodes/CustomPrompt';
import { NodeType, outputNodeTypes, useNodeTypeToName } from './nodes';
import { trackAddWorkflowNode } from '../../helpers/analytics';
import { selectAiOutputLanguage } from '../../redux/selectors';
import { useSelector } from 'react-redux';
import { useIsPreview } from './nodes/useIsPreview';
import Dagre from 'dagre';
import { useIntl } from 'react-intl';

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: 'TB', ranksep: 100, nodesep: 30 });

  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    })
  );

  Dagre.layout(g);

  const layoutedNodes = nodes.map((node) => {
    const nodeWithPosition = g.node(node.id);

    return {
      ...node,
      position: {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y,
      },
    };
  });

  return { nodes: layoutedNodes, edges };
};

function getPrefersReducedMotion() {
  const QUERY = '(prefers-reduced-motion: no-preference)';
  const mediaQueryList = window.matchMedia(QUERY);
  const prefersReducedMotion = !mediaQueryList.matches;
  return prefersReducedMotion;
}

/**
 * A button that lets you add a node as the target of a node.
 * Adding nodes between nodes is not implemented yet
 */
export default function AddNodeButton({
  source,
  target,
  label,
  sourceHandle,
  position,
  iconBefore,
  onMenuOpenChange,
}: {
  source: string;
  target?: string;
  label?: string;
  iconBefore?: boolean;
  sourceHandle?: string;
  position?: Node['position'];
  onMenuOpenChange?: (open: boolean) => void;
}): ReactElement | null {
  const intl = useIntl();
  const reactFlow = useReactFlow();
  const sourceNode = reactFlow.getNode(source);
  if (!sourceNode) throw new Error('Node not found');
  const { newId } = useWorkflowId();
  const nodeTypeToName = useNodeTypeToName();
  const readonly = useIsPreview();
  const aiOutputLanguage = useSelector(selectAiOutputLanguage);

  const prefillData = usePrefillData(source);

  const onAddNode = <T = Record<string, unknown>>(
    type: NodeType,
    data?: T,
    options?: {
      addAsBranch?: boolean;
    }
  ) => {
    const id = newId();
    trackAddWorkflowNode({ workflowId: id, type });

    let newEdges: Edge[] = [...reactFlow.getEdges()];
    const newNodes = [
      {
        id,
        type,
        data: {
          displayName: nodeTypeToName[type] ?? type,
          ...(data ?? {}),
        },
        position: position ?? {
          x: sourceNode.position.x,
          y: sourceNode.position.y + (sourceNode.measured?.height ?? 0) + 100,
        },
      },
      ...reactFlow.getNodes(),
    ];

    // Add an edge from the source to the new node
    newEdges.push({
      id: nanoid(),
      sourceHandle,
      source,
      target: id,
      type: 'smoothstep',
      markerEnd: {
        type: target ? MarkerType.Arrow : MarkerType.ArrowClosed,
      },
    });

    if (target && !options?.addAsBranch) {
      // Add an edge from the new node to the target
      newEdges.push({
        id: nanoid(),
        sourceHandle:
          type === 'Condition' || type === 'Confirmation' ? 'true' : undefined,
        source: id,
        target,
        type: 'smoothstep',
        markerEnd: {
          type: MarkerType.Arrow,
        },
      });

      // Remove the existing edge from the source to the target
      newEdges = newEdges.filter(
        (edge) => edge.source !== source || edge.target !== target
      );
    }

    let newNodePosition: { x: number; y: number } | undefined;
    // Only auto layout when adding a node between two nodes
    if (target) {
      const layouted = getLayoutedElements(newNodes, newEdges);
      reactFlow.setNodes(layouted.nodes);
      reactFlow.setEdges(layouted.edges);
      newNodePosition = layouted.nodes.find((n) => n.id === id)?.position;
    } else {
      reactFlow.setNodes(newNodes);
      reactFlow.setEdges(newEdges);
      newNodePosition = newNodes.find((n) => n.id === id)?.position;
    }

    reactFlow
      .setCenter(newNodePosition?.x ?? 0, newNodePosition?.y ?? 0, {
        duration: getPrefersReducedMotion() ? 0 : 500,
      })
      .catch((error) => {
        // biome-ignore lint: noConsole
        console.error('Failed to center on new node', error);
      });
  };
  const icon = <PlusIcon className="size-4 text-white" />;

  if (readonly) return null;

  const getItems = (options?: { addAsBranch: boolean }) => (
    <>
      <Menu.Item
        icon={<ListTodo className="size-4 text-slate-500" />}
        onClick={() => onAddNode('SaveToSpace', null, options)}
      >
        {nodeTypeToName.SaveToSpace}
      </Menu.Item>
      <Menu.Item
        icon={<ListTodo className="size-4 text-slate-500" />}
        onClick={() => onAddNode('AddLabels', null, options)}
      >
        {nodeTypeToName.AddLabels}
      </Menu.Item>
      <Menu.Item
        icon={<Sparkle className="size-4 text-slate-500" />}
        onClick={() =>
          onAddNode('RunMeetingKit', { aiOutputLanguage }, options)
        }
      >
        {nodeTypeToName.RunMeetingKit}
      </Menu.Item>
      <Menu.Item
        icon={<Sparkle className="size-4 text-slate-500" />}
        onClick={() =>
          onAddNode<CustomPromptData>(
            'CustomPrompt',
            {
              saveToMeeting: true,
              prompt: prefillData,
              aiOutputLanguage,
              includeTranscriptContext: true,
            },
            options
          )
        }
      >
        {nodeTypeToName.CustomPrompt}
      </Menu.Item>
      <Menu.Item
        icon={<Blocks className="size-4 text-slate-500" />}
        onClick={() => onAddNode('SendData', { parameterData: {} }, options)}
      >
        {nodeTypeToName.SendData}
      </Menu.Item>
      <Menu.Item
        icon={<ConfirmationIcon className="size-4 text-slate-500" />}
        onClick={() =>
          onAddNode('Confirmation', { prompt: prefillData }, options)
        }
      >
        {nodeTypeToName.Confirmation}
      </Menu.Item>
      <Menu.Item
        icon={<ConditionIcon className="size-4 rotate-180 text-slate-500" />}
        onClick={() =>
          onAddNode(
            'Condition',
            {
              condition: prefillData,
            },
            options
          )
        }
      >
        {nodeTypeToName.Condition}
      </Menu.Item>
      <Menu.Item
        icon={<TemplateIcon className="size-4 text-slate-500" />}
        onClick={() =>
          onAddNode(
            'Template',
            {
              saveToMeeting: false,
              template: prefillData,
              title: '',
            },
            options
          )
        }
      >
        {nodeTypeToName.Template}
      </Menu.Item>
    </>
  );

  return (
    <Menu onOpenChange={onMenuOpenChange}>
      <Menu.Trigger>
        <Button
          ariaLabel={intl.formatMessage({
            defaultMessage: 'Add workflow node button',
          })}
          size="small"
          startIcon={iconBefore && icon}
          endIcon={!iconBefore && icon}
        >
          {label}
        </Button>
      </Menu.Trigger>
      {target ? (
        <>
          <Menu
            label={intl.formatMessage({ defaultMessage: 'New step' })}
            icon={<ArrowDownToDot className="size-4 text-slate-500" />}
          >
            {getItems({ addAsBranch: false })}
          </Menu>
          <Menu
            label={intl.formatMessage({ defaultMessage: 'New branch' })}
            icon={<Split className="size-4 text-slate-500" />}
          >
            {getItems({ addAsBranch: true })}
          </Menu>
        </>
      ) : (
        getItems()
      )}
    </Menu>
  );
}

function usePrefillData(source: string): string {
  const rf = useReactFlow();
  const edges = rf.getEdges();

  const firstUsableNode = (function findParent(source: string) {
    // Find the parent node, return early if nothing is found
    const node = rf.getNode(source);
    if (!node) return null;

    // return early if this node has an output
    if (outputNodeTypes.has(node.type ?? '')) return node;

    // keep looking
    const sourceEdge = edges.find((e) => e.target === source);
    if (!sourceEdge) return null;
    return findParent(sourceEdge?.source);
  })(source);

  return firstUsableNode ? `{{ ${firstUsableNode.id}.output }}` : '';
}
