import { useMutation } from '@apollo/client';
import { Check, CircleHelp } from 'lucide-react';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../../../components/Alert';
import { TextInput } from '../../../../components/TextInput';
import { Button } from '../../../../components/buttons';
import { restoreArchivedMeeting } from '../../../../graphql/meetings';
import {
  AiContentType,
  GenerateAiContentDocument,
  MeetingAccess,
  PreviewMeetingKitItemPromptDocument,
  UseMeetingKitItemDocument,
} from '../../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackTranscriptAIUsed,
  trackWebEvent,
} from '../../../../helpers/analytics';
import {
  CanUseAIToolsResult,
  canUseAITools,
} from '../../../../helpers/meetings';
import { cx, smoothScrollIntoView } from '../../../../helpers/utils';
import {
  createSelectRawTranscriptSelector,
  selectAiOutputLanguage,
  selectTeam,
  selectUid,
  selectUsedMeetingKitsSelector,
  selectUserPlan,
  selectUserTier,
} from '../../../../redux/selectors';
import { RootState } from '../../../../redux/store';
import { RequireLLMEnabled } from '../../../Common/RequireLLMEnabled';
import { EmojiField } from '../../../Common/icons/EmojiField';
import { TierPricingDialog } from '../../../Credits/TierPricing/TierPricingDialog';
import { useFullMeeting } from '../../common/meeting-hooks';
import TranscriptSection from '../TranscriptSection';
import { AIPromptOutput } from './AIPromptOutput';
import { Credits } from './Credits';
import { AISummaryPreviewExperiment } from './AISummaryPreviewExperiment';
import featureFlagService from '../../../../helpers/feature-flags';
import Magic from '../../../Common/icons/Magic';
import { MoreAiToolsMenu } from './MoreAiToolsMenu';
import { RanOutOfCredits } from './RanOutOfCredits';
import { AiOutputLanguageSelector } from '../../../Common/AiOutputLanguageSelector';
import { RawTranscript } from '@tactiq/model';
import { Link } from '../../../../components/Link';
import { kSettingsGeneral } from '../../../../helpers/routes';
import { Popover } from '../../../../components/Popover';

export type AvailablePrompt = {
  type: 'system' | 'used' | 'explore'; // TODO: add 'recently used', store that in user
  title: string;
  icon: string | React.ReactNode;
  meetingKitId: string;
  meetingKitItemId: string | AiContentType;
};

function scrollOutputIntoView(kitItemId: string) {
  // TODO: this needs to be synchronized better.
  // currently there is no easy way to know when results are
  // received via subscription and rendered into DOM
  // after the prompt is generated
  //
  // For now: poll for 4 seconds untill the element is there, then give up
  const POLL_INTERVAL = 200;
  const MAX_ATTEMTS = 20;
  let numAttemts = 0;
  function tryScroll() {
    const element = document.getElementById(
      `meetingKitItemOutput-${kitItemId}`
    );
    if (element) {
      smoothScrollIntoView(element, -130);
    } else if (numAttemts < MAX_ATTEMTS) {
      numAttemts++;
      setTimeout(tryScroll, POLL_INTERVAL);
    }
  }
  tryScroll();
}

/**
 * Ai generated section
 */
export const AIGenerated: React.FC<{
  detectedLanguage?: RawTranscript['detectedLanguage'];
}> = ({ detectedLanguage }) => {
  const meeting = useFullMeeting();
  const currentUserId = useSelector(selectUid);
  const aiAvailable = canUseAITools(meeting);
  const [promptInput, setPromptInput] = useState<string>('');
  const [isAiSummaryPreviewShown, setAiSummaryPreviewShown] =
    useState<boolean>(true);
  const [aiOutputLanguage, setAiOutputLanguage] = useState(
    useSelector(selectAiOutputLanguage)
  );
  const selectRawTranscript = useMemo(createSelectRawTranscriptSelector, []);
  const meetingId = meeting?.id ?? '';
  const transcript = useSelector((state: RootState) =>
    selectRawTranscript(state, meetingId)
  );
  const intl = useIntl();

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);

  const userPlan = useSelector(selectUserPlan);
  const hasCredits =
    userPlan.free.aiCredits.allowance - userPlan.free.aiCredits.used > 0;
  // whether there are any prompt outputs right now is irrelevant; as long as the field is there it means there was at least one in the past
  // e.g. consider generating outputs and deleting them all - we should consider this as a meeting where the credit was already spent
  const needsCredits = !meeting?.hadAiUsage;

  const [generateAIContent, generateAIContentMutation] = useMutation(
    GenerateAiContentDocument
  );

  const [previewMeetingKitItemPrompt, previewMeetingKitItemPromptMutation] =
    useMutation(PreviewMeetingKitItemPromptDocument);

  const isTutorial = !!meeting?.isTutorial;

  const onGenerate = useCallback(
    async (meetingKitId: string, meetingKitItemId: string) => {
      trackWebEvent('AI Summary - Generate button - clicked', {
        meetingId,
        meetingKitId,
        meetingKitItemId,
        isTutorial,
      });

      return await generateAIContent({
        variables: {
          input: {
            id: meetingId,
            meetingKitId,
            meetingKitItemId,
            aiOutputLanguage,
          },
        },
      });
    },
    [aiOutputLanguage, generateAIContent, meetingId, isTutorial]
  );

  const systemMeetingKit = useSelector(
    (state: RootState) => state.global.meetingKits.system
  );

  const usedMeetingKits = useSelector(selectUsedMeetingKitsSelector);

  const exploreMeetingKits = useSelector(
    (state: RootState) => state.global.meetingKits.explore
  );

  const [runMeetingKitItem, useMeetingKitItemMutation] = useMutation(
    UseMeetingKitItemDocument
  );

  const [loadingMeetingKitItemId, setLoadingMeetingKitItemId] = useState('');

  const isWorking =
    generateAIContentMutation.loading ||
    useMeetingKitItemMutation.loading ||
    previewMeetingKitItemPromptMutation.loading;
  const aiPromptOutputs = (meeting?.aiOutputs ?? [])
    .slice()
    .sort((a, b) => b.requestedAt - a.requestedAt);

  const usedPrompts = new Set(
    aiPromptOutputs
      .map((ii) => ii.meetingKitItemId)
      .filter((x): x is string => Boolean(x))
  );

  const AllSystemPrompts: AvailablePrompt[] = [];

  for (const item of systemMeetingKit.items) {
    // if (item.outputType === MeetingKitItemOutputType.ACTION_ITEMS) continue;

    if (item.__typename === 'MeetingKitItemPrompt') {
      AllSystemPrompts.push({
        type: 'system',
        title: item.name,
        icon: item.icon ? (
          <EmojiField
            value={item.icon}
            defaultIcon={<Magic />}
            EmojiProps={{
              unified: item.icon,
              size: 20,
            }}
          />
        ) : (
          <Magic />
        ),
        meetingKitId: systemMeetingKit.id,
        meetingKitItemId: item.id,
      });
    }
  }

  const AllMeetingKitPrompts: AvailablePrompt[] = [];

  for (const kit of usedMeetingKits) {
    for (const item of kit.items) {
      if (item.__typename === 'MeetingKitItemPrompt') {
        AllMeetingKitPrompts.push({
          type: 'used',
          title:
            kit.name === item.name ? kit.name : `${kit.name} - ${item.name}`,
          icon: (
            <EmojiField
              value={item.icon}
              defaultIcon={<Magic />}
              EmojiProps={{
                unified: item.icon,
                size: 20,
              }}
            />
          ),
          meetingKitId: kit.id,
          meetingKitItemId: item.id,
        });
      }
    }
  }

  for (const kit of exploreMeetingKits) {
    // skip if it is mine
    if (usedMeetingKits.find((u) => u.id === kit.id)) continue;
    // skip if it is already used
    if (usedMeetingKits.find((u) => u.owner.sourceId === kit.id)) continue;

    for (const item of kit.items) {
      if (item.__typename === 'MeetingKitItemPrompt') {
        AllMeetingKitPrompts.push({
          type: 'explore',
          title:
            kit.name === item.name ? kit.name : `${kit.name} - ${item.name}`,
          icon: (
            <EmojiField
              value={item.icon}
              defaultIcon={<Magic />}
              EmojiProps={{
                unified: item.icon,
                size: 20,
              }}
            />
          ),
          meetingKitId: kit.id,
          meetingKitItemId: item.id,
        });
      }
    }
  }

  const quickPrompts: AvailablePrompt[] = [
    ...AllSystemPrompts,
    ...AllMeetingKitPrompts,
  ].slice(0, 6);

  let alert: React.ReactNode | undefined;

  switch (aiAvailable) {
    case CanUseAIToolsResult.TooShort:
      alert = (
        <Alert
          variant="light"
          severity="warning"
          description={
            <FormattedMessage
              defaultMessage="AI Tools and Meeting Kits are available for meetings longer than 2 minutes."
              id="ct7Xw8"
            />
          }
        />
      );
      break;
    case CanUseAIToolsResult.IsArchived:
      alert = (
        <Alert
          variant="light"
          severity="warning"
          description={
            <FormattedMessage
              defaultMessage="This meeting is archived."
              id="7AnlEO"
            />
          }
          action={
            meeting?.user?.id === currentUserId && (
              <Button
                onClick={async () => {
                  trackWebEvent('Meeting was unarchived from AI tools alert', {
                    meetingId,
                  });
                  await restoreArchivedMeeting(meetingId);
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'Your meeting was successfully restored',
                      id: 'Dqcdg9',
                    }),
                    { variant: 'SUCCESS' }
                  );
                }}
              >
                <FormattedMessage
                  defaultMessage="Restore meeting"
                  id="XFibLh"
                />
              </Button>
            )
          }
        />
      );
      break;
    case CanUseAIToolsResult.IsPreview:
      alert = (
        <Alert
          variant="light"
          severity="warning"
          description={
            <FormattedMessage
              defaultMessage="You've reached free transcripts for that period and are currently viewing a preview. To gain full access and utilize AI tools for additional meetings, consider upgrading to our Pro Plan. Unlock unlimited meetings and more. Upgrade now!"
              id="Z0hAhW"
            />
          }
          action={
            <Button
              onClick={() => {
                trackWebEvent(
                  'Upgrade to Pro button clicked from AI tools alert',
                  {
                    reasonAlertShown: CanUseAIToolsResult.IsPreview,
                  }
                );
                setShowTierPricingDialog(true);
              }}
            >
              <FormattedMessage defaultMessage="Upgrade to Pro" id="5N04mo" />
            </Button>
          }
        />
      );
      break;
    case CanUseAIToolsResult.No: // should not happen but let's keep an alert in case something actually happens so that the users can report this
      alert = (
        <Alert
          variant="light"
          severity="warning"
          description={
            <FormattedMessage
              defaultMessage="Looks like something has gone wrong. Try refreshing the page or contact support."
              id="BLYZyL"
            />
          }
        />
      );
      break;
    case CanUseAIToolsResult.Yes:
      // no alert :)
      break;
  }

  if (!transcript || !meeting) {
    return null;
  }

  if (
    !meeting.hadAiUsage &&
    !alert &&
    !isWorking &&
    userPlan.free.aiCredits.allowance - userPlan.free.aiCredits.used === 0
  ) {
    alert = <RanOutOfCredits />;
  }

  // AI Summary Preview Experiment
  // ==============================
  // The summary preview experiment is displayed under the following conditions:
  // - The experiment feature flag is activated.
  // - The meeting has more than one participant.
  // - The user has not yet generated any AI outputs.
  // - The user is permitted to use AI tools (i.e., the meeting duration is sufficient, the meeting is not archived, it's not a preview, etc.)
  // - The user is not in the process of generating any outputs.
  // - The user has not used any of their ai credits.
  if (
    isAiSummaryPreviewShown &&
    featureFlagService.isAiSummaryPreviewExperimentEnabled() &&
    meeting.participants.length > 1 &&
    !meeting.hadAiUsage &&
    !alert &&
    !isWorking &&
    userPlan.free.aiCredits.used === 0
  ) {
    return (
      <AISummaryPreviewExperiment
        participantNames={meeting.participants.map((p) => p.name)}
        onClose={() => setAiSummaryPreviewShown(false)}
        onGenerateSummary={() => {
          // "Summary and Action items" meeting kit id and item id
          onGenerate('ygmhmpfaJMIoJFAym33j', 'XZWAhznOSMiYoB6ud2Vi');
          trackTranscriptAIUsed(
            'Quick Prompt',
            'Summary and Action items',
            needsCredits,
            team?.id,
            aiOutputLanguage === 'meeting-language'
              ? detectedLanguage?.shortName
              : aiOutputLanguage
          );
        }}
      />
    );
  }

  const onClick = async (prompt: AvailablePrompt) => {
    if (meetingId === 'tutorial') {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage:
            'AI is not available for the tutorial meeting, please try on your meetings instead',
          id: 'n94mUl',
        }),
        { variant: 'INFO' }
      );

      return;
    }

    if (isWorking) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'Please wait for the current prompt to finish',
          id: 'YYVK8T',
        }),
        { variant: 'WARNING' }
      );
      return;
    }

    trackWebEvent('AI - Clicked on a quick prompt', {
      meetingId: meeting.id,
      type: prompt.type === 'explore' ? 'system' : prompt.type, // for the purpose of tracking, we treat explore prompts as system prompts
      title: prompt.title,
      meetingKitId: prompt.meetingKitId,
      meetingKitItemId: prompt.meetingKitItemId,
    });

    if (needsCredits && !hasCredits) {
      enqueueSnackbar(
        intl.formatMessage({
          defaultMessage: 'You do not have enough AI credits',
          id: 'ytL9+4',
        }),
        { variant: 'WARNING' }
      );
      return;
    }

    setLoadingMeetingKitItemId(prompt.meetingKitItemId);

    if (prompt.type === 'system') {
      trackTranscriptAIUsed(
        'Quick Prompt',
        prompt.title,
        needsCredits,
        team?.id,
        aiOutputLanguage === 'meeting-language'
          ? detectedLanguage?.shortName
          : aiOutputLanguage,
        meeting.isTutorial
      );
      await onGenerate(prompt.meetingKitId, prompt.meetingKitItemId);
    } else {
      trackTranscriptAIUsed(
        'AI Kit',
        prompt.title,
        needsCredits,
        team?.id,
        aiOutputLanguage === 'meeting-language'
          ? detectedLanguage?.shortName
          : aiOutputLanguage,
        meeting.isTutorial
      );
      await runMeetingKitItem({
        variables: {
          input: {
            id: meeting.id,
            meetingKitId: prompt.meetingKitId,
            meetingKitItemId: prompt.meetingKitItemId,
            aiOutputLanguage,
          },
        },
      });
    }

    setLoadingMeetingKitItemId('');
    scrollOutputIntoView(prompt.meetingKitItemId);
  };

  return (
    <TranscriptSection
      header={
        <FormattedMessage
          defaultMessage="AI Tools"
          id="28YglJ"
          description="Meeting view. AI generated section header."
        />
      }
      place="aigenerated"
    >
      <RequireLLMEnabled>
        {alert && (
          <div className="-inset-4 absolute z-[1] flex items-center justify-center p-4 backdrop-blur-sm">
            <div className="sticky top-32 bottom-4 w-full px-10">{alert}</div>
          </div>
        )}
        <div
          className={cx(
            alert ? 'pointer-events-none' : '',
            'mt-4 flex flex-col gap-6'
          )}
        >
          <div className="flex flex-col gap-2">
            <div className="flex grow items-center justify-between font-semibold text-base text-slate-900">
              <FormattedMessage defaultMessage="Quick prompts" id="XJt7xh" />
              <div>
                <RequireLLMEnabled disableAlert={true}>
                  {aiAvailable && !meeting.isTutorial ? (
                    <Credits needsCredits={needsCredits} />
                  ) : null}
                </RequireLLMEnabled>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              {quickPrompts.map((prompt, index) => {
                const complete = usedPrompts.has(prompt.meetingKitItemId);
                return (
                  <Button
                    className="w-full font-medium"
                    startIcon={
                      complete ? <Check size="1.25rem" /> : prompt.icon
                    }
                    disabled={complete}
                    key={prompt.meetingKitItemId ?? index}
                    variant={complete ? 'success-tertiary' : 'neutral-tertiary'}
                    loading={
                      prompt.meetingKitItemId === loadingMeetingKitItemId &&
                      isWorking
                    }
                    onClick={async () => {
                      if (!alert) {
                        await onClick(prompt);
                      }
                    }}
                  >
                    <div className="truncate">{prompt.title}</div>
                  </Button>
                );
              })}
            </div>
            <MoreAiToolsMenu
              loading={
                isWorking &&
                quickPrompts.every(
                  (ii) => ii.meetingKitItemId !== loadingMeetingKitItemId
                )
              }
              quickPromptsLength={quickPrompts.length}
              usedPrompts={usedPrompts}
              onClick={onClick}
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex flex-row items-start gap-2">
              <TextInput
                type="textarea"
                textAreaProps={{ rows: 1, className: 'py-1 min-h-8' }}
                value={promptInput}
                onChange={setPromptInput}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Or ask Tactiq anything about the meeting.',
                  id: 'RyvNkP',
                })}
              />

              <Button
                startIcon={<Magic />}
                loading={previewMeetingKitItemPromptMutation.loading}
                variant="neutral-secondary"
                onClick={async () => {
                  if (!promptInput?.trim()) return;

                  if (meetingId === 'tutorial') {
                    enqueueSnackbar(
                      intl.formatMessage({
                        defaultMessage:
                          'AI is not available for the tutorial meeting, please try on your meetings instead',
                        id: 'n94mUl',
                      }),
                      { variant: 'INFO' }
                    );

                    return;
                  }

                  const prompt = promptInput;
                  trackWebEvent(
                    'AI Summary - Custom prompt generated - clicked',
                    {
                      prompt,
                      meetingId,
                    }
                  );
                  trackTranscriptAIUsed(
                    'AI Question',
                    'AI Question',
                    needsCredits,
                    team?.id,
                    aiOutputLanguage === 'meeting-language'
                      ? detectedLanguage?.shortName
                      : aiOutputLanguage
                  );
                  setPromptInput('');
                  await previewMeetingKitItemPrompt({
                    variables: {
                      input: {
                        id: meetingId,
                        prompt,
                        saveToMeeting: true,
                        aiOutputLanguage,
                      },
                    },
                  });
                }}
              >
                <FormattedMessage defaultMessage="Ask" id="ZT0z9z" />
              </Button>
            </div>
          </div>

          <div className="flex flex-wrap items-center justify-end gap-3">
            <div className="flex items-center gap-x-1">
              <span className="text-sm">
                <FormattedMessage
                  defaultMessage="Language for AI output"
                  id="LnF3hG"
                />
              </span>

              <Popover
                placement="top"
                content={
                  <FormattedMessage
                    defaultMessage="Set the AI-genereated language for this meeting only. You can set the default AI output language in <link>your settings</link>."
                    id="Lr53p6"
                    values={{
                      link: (chunks) => (
                        <Link to={kSettingsGeneral}>{chunks}</Link>
                      ),
                    }}
                  />
                }
              >
                <button>
                  <CircleHelp size="1rem" />
                </button>
              </Popover>
              <AiOutputLanguageSelector
                onChange={(value) => {
                  trackWebEvent('Transcript AI Output Language Changed', {
                    langCode: value,
                  });
                  setAiOutputLanguage(value);
                }}
                detectedLanguage={detectedLanguage}
                value={aiOutputLanguage}
                context="meeting"
                onOpenChange={(isOpen) => {
                  if (isOpen)
                    trackWebEvent('Transcript AI Output Language View');
                }}
              />
            </div>
          </div>

          {aiPromptOutputs.length ? (
            <div className="mt-6 space-y-6">
              <h2 className="font-semibold text-slate-900 text-xl">
                <FormattedMessage
                  defaultMessage="AI meeting notes"
                  id="8zymCO"
                  description="Meeting view. AI generated output header."
                />
              </h2>
              {aiPromptOutputs.map((output) => {
                return (
                  <AIPromptOutput
                    key={output.requestedAt}
                    output={output}
                    readOnly={
                      ![MeetingAccess.WRITE, MeetingAccess.ADMIN].includes(
                        meeting.access
                      ) && output.askedBy?.uid !== currentUserId
                    }
                    title={
                      AllSystemPrompts.find(
                        (p) => p.meetingKitItemId === output.meetingKitItemId
                      )?.title
                    }
                    icon={
                      AllSystemPrompts.find(
                        (p) => p.meetingKitItemId === output.meetingKitItemId
                      )?.icon
                    }
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </RequireLLMEnabled>
      {showTierPricingDialog && (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.AI_GENERATED}
          onClose={() => setShowTierPricingDialog(false)}
        />
      )}
    </TranscriptSection>
  );
};
