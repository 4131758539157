import { useMutation } from '@apollo/client';
import { isAfter } from 'date-fns';
import { Gift, Link, Send } from 'lucide-react';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../components/buttons';
import { ModalDialog } from '../../components/modals';
import {
  PreviewTeamUpgradeInvoiceDocument,
  RequestUpgradeDocument,
  Team,
  TeamIncreaseSeatCountDocument,
  TeamMemberStatus,
  TeamTier,
  UserTier,
} from '../../graphql/operations';
import { trackWebEvent } from '../../helpers/analytics';
import { isProduction } from '../../helpers/firebase/config';
import { getCountryCode } from '../../helpers/locale';
import {
  selectTeam,
  selectUid,
  selectUserPricing,
  selectUserTier,
} from '../../redux/selectors';
import { PreviewInvoice } from '../Credits/PreviewInvoice';
import { enqueueSnackbar } from 'notistack';
import { gotTeam } from '../../redux/modules/user';

// Promotion time compoment is housed here and used in the billing page/navigation bar
// Update the below variables for the new promotion as well as text in between 'UPDATE HERE' comments

// Event name for tracking in mixpanel
export const PromotionEventName = 'Year End';
// Param from the email that will trigger the automatic opening of the dialog
export const EmailParamString = 'year-end-2024';
// Date the components will no longer be shown (Format: YYYY-MM-DD)
export const CutOffDate = '2025-01-10';
// Date the offer expires for showing in text for dialogs (Format: Month Day, Year)
export const CutOffDateString = 'January 10th, 2025';
// Production coupon code for Stripel
export const ProductionCouponCode = 'oWGGE58m';
// Testing coupon code for Stripe
export const TestingCouponCode = 'p4Co7zdS';

export function canSeePromo(
  uid: string | undefined,
  tier: UserTier,
  team?: Team
): boolean {
  if (!uid) return false;
  if (isAfter(new Date(), new Date(CutOffDate))) return false;
  if (team?.tier === TeamTier.ENTERPRISE) return false;
  if (!team?.isPaid) return false;

  const membersCount = team?.members.length ?? 0;
  const paidMembersCount =
    team?.members.filter(
      (x) =>
        x.status === TeamMemberStatus.TEAM || x.status === TeamMemberStatus.PRO
    ).length ?? 0;

  if (membersCount < 5 || membersCount > 60) return false;
  if (paidMembersCount < 4 || paidMembersCount > 30) return false;

  return true;
}

export function canGetPromo(
  uid: string | undefined,
  tier: UserTier,
  team?: Team
): boolean {
  if (!team?.members.find((x) => x.uid === uid)?.roles.ADMIN) return false;

  return canSeePromo(uid, tier, team);
}

/**
 * Promo Dialog
 * @param {unknown} param0 params
 * @param {() => void} param0.onClose close callback
 * @returns {React.FC} component
 */
export const PromoDialog: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canGetPromotion = canGetPromo(userId, userTier, team);
  React.useEffect(() => {
    trackWebEvent(`${PromotionEventName} - Dialog - Shown`, {
      tier: userTier,
    });
  }, [userTier]);
  const userPricing = useSelector(selectUserPricing);
  const { team: teamPricing } = userPricing;
  const globalPricing = teamPricing;
  const countryCode = getCountryCode();
  const currency = globalPricing.currencies?.find(
    (c) => c.countryCode === countryCode
  );
  const pricing = React.useMemo(() => {
    if (currency) {
      return currency;
    }
    return globalPricing;
  }, [currency, globalPricing]);
  const currencySymbol =
    'currencySymbol' in pricing ? pricing.currencySymbol : pricing.currency;

  const intl = useIntl();
  const dispatch = useDispatch();
  const [requestUpgrade] = useMutation(RequestUpgradeDocument);
  const [previewInvoiceVisible, setPreviewInvoiceVisible] = useState(false);

  const [previewTeamUpgradeInvoice] = useMutation(
    PreviewTeamUpgradeInvoiceDocument
  );
  const [teamIncreaseSeatCount] = useMutation(TeamIncreaseSeatCountDocument);

  if (!team?.plan) return null;

  if (previewInvoiceVisible) {
    return (
      <>
        <ModalDialog
          icon={
            <div className="animate-bounce">
              <Gift strokeWidth={2} size={20} />
            </div>
          }
          open
          onClose={onClose}
          title={
            <FormattedMessage defaultMessage="Special Year-End Offer for your team!" />
          }
          text={
            <div className="flex flex-col gap-2 text-neutral-primary">
              <p>
                <FormattedMessage
                  defaultMessage="Your team can get an additional {seats} annual seats with {amount} off - and boost productivity for the whole next year! Offer expires {expiry}."
                  values={{
                    amount: <b className="text-indigo-600">30%</b>,
                    seats: <b className="text-indigo-600">10</b>,
                    expiry: <b>{CutOffDateString}</b>,
                  }}
                />
              </p>
              {!canGetPromotion && (
                <p>
                  <b>
                    <FormattedMessage defaultMessage="What can you do?" />
                  </b>
                  <br />
                  <ul className="ml-4 list-disc">
                    <li>
                      <FormattedMessage defaultMessage="Request an upgrade to let your team administrator(s) know about this sale." />
                    </li>
                    <li>
                      <FormattedMessage defaultMessage="Share the offer link - copy the link and send it to your administrator(s) yourself." />
                    </li>
                  </ul>
                </p>
              )}
            </div>
          }
        >
          <PreviewInvoice
            open={previewInvoiceVisible}
            onClose={() => setPreviewInvoiceVisible(false)}
            invoiceProducer={async (approved: boolean) => {
              if (!team.plan) {
                setPreviewInvoiceVisible(false);
                return false;
              }

              if (approved) {
                dispatch(
                  gotTeam(
                    (
                      await teamIncreaseSeatCount({
                        variables: {
                          quantity: 10,
                          forceAnnual: true,
                          coupon: isProduction()
                            ? ProductionCouponCode
                            : TestingCouponCode,
                        },
                      })
                    ).data?.team_billing_increaseSeatCount
                  )
                );

                enqueueSnackbar(
                  intl.formatMessage({
                    defaultMessage:
                      'Your purchase was successful! Your team will receive the new seats shortly.',
                  }),
                  {
                    variant: 'INFO',
                  }
                );
              } else {
                return (
                  await previewTeamUpgradeInvoice({
                    variables: {
                      input: {
                        quantity:
                          team.plan.recurrenceInterval === 'year'
                            ? 10
                            : team.plan.quantity + 10,
                        forceAnnual: true,
                        coupon: isProduction()
                          ? ProductionCouponCode
                          : TestingCouponCode,
                      },
                    },
                  })
                )?.data?.team_billing_previewUpgradeInvoice?.invoice;
              }
            }}
            tag="year-end-2024"
            description={
              <FormattedMessage defaultMessage="Buy 10 extra seats with a 30% discount" />
            }
          />
        </ModalDialog>
      </>
    );
  }

  return (
    <>
      <ModalDialog
        icon={
          <div className="animate-bounce">
            <Gift strokeWidth={2} size={20} />
          </div>
        }
        open
        onClose={onClose}
        title={
          <FormattedMessage defaultMessage="Special Year-End Offer for your team!" />
        }
        text={
          <div className="flex flex-col gap-2 text-neutral-primary">
            <p>
              <FormattedMessage
                defaultMessage="Your team can get an additional {seats} annual seats with {amount} off - and boost productivity for the whole next year! Offer expires {expiry}."
                values={{
                  amount: <b className="text-indigo-600">30%</b>,
                  seats: <b className="text-indigo-600">10</b>,
                  expiry: <b>{CutOffDateString}</b>,
                }}
              />
            </p>
            {!canGetPromotion && (
              <p>
                <b>
                  <FormattedMessage defaultMessage="What can you do?" />
                </b>
                <br />
                <ul className="ml-4 list-disc">
                  <li>
                    <FormattedMessage defaultMessage="Request an upgrade to let your team administrator(s) know about this sale." />
                  </li>
                  <li>
                    <FormattedMessage defaultMessage="Share the offer link - copy the link and send it to your administrator(s) yourself." />
                  </li>
                </ul>
              </p>
            )}
          </div>
        }
        actions={
          <>
            {canGetPromotion && (
              <Button
                onClick={async () => {
                  trackWebEvent(
                    `${PromotionEventName} - Dialog - Upgrade clicked`
                  );
                  setPreviewInvoiceVisible(true);
                }}
              >
                <FormattedMessage defaultMessage="Buy 10 extra seats and get a discount" />
              </Button>
            )}
            {!canGetPromotion && (
              <Button
                startIcon={<Send className="h-4 w-4" />}
                onClick={async () => {
                  trackWebEvent(
                    `${PromotionEventName} - Dialog - Request upgrade clicked`
                  );
                  await requestUpgrade();
                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage:
                        'Your request has been sent to the team admin',
                    }),
                    {
                      variant: 'INFO',
                    }
                  );
                }}
              >
                <FormattedMessage
                  defaultMessage="Request upgrade"
                  id="/U9Ppo"
                />
              </Button>
            )}
            {!canGetPromotion && (
              <Button
                startIcon={<Link className="h-4 w-4" />}
                variant="neutral-secondary"
                onClick={async () => {
                  trackWebEvent(
                    `${PromotionEventName} - Dialog - Copy link clicked`
                  );
                  navigator.clipboard.writeText(
                    'https://app.tactiq.io/#/team?offer=year-end-2024'
                  );

                  enqueueSnackbar(
                    intl.formatMessage({
                      defaultMessage: 'The link has been copied!',
                    }),
                    {
                      variant: 'INFO',
                    }
                  );
                }}
              >
                <FormattedMessage defaultMessage="Copy link" />
              </Button>
            )}
          </>
        }
      >
        <div className="mx-auto my-3 h-full w-full rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-1">
          <div className="flex h-full w-full flex-row items-center justify-between gap-2 rounded-lg bg-white px-3 py-2">
            <p>
              <span className="font-bold text-indigo-600 text-xl">
                {currencySymbol}
                {pricing.annualAmount * 0.7} {''}
              </span>
              <FormattedMessage
                defaultMessage="per user per year"
                id="o+knyb"
              />
            </p>
            <div className="rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 px-3 py-1 font-bold text-white">
              <p>
                <FormattedMessage defaultMessage="30% discount!" />
              </p>
            </div>
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

/**
 * Nav Bar Button
 * @returns {React.FC} component
 */
export const PromotionNavButton: React.FC = () => {
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canSeePromotion = canSeePromo(userId, userTier, team);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPromoDialog, setShowPromoDialog] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.get('offer') === EmailParamString) {
      setShowPromoDialog(true);
      trackWebEvent(`${PromotionEventName} - Link from email present`, {
        tier: userTier,
      });
    }
  }, [searchParams, userTier]);

  if (!canSeePromotion) return null;

  return (
    <button
      type="button"
      onClick={() => {
        trackWebEvent(`${PromotionEventName} - Clicked`, {
          tier: userTier,
          where: 'navigation',
        });
        setShowPromoDialog(true);
      }}
      className="mt-3 flex w-full flex-row items-center gap-x-3 rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-2 text-white"
    >
      <div className="animate-bounce">
        <Gift strokeWidth={2} size={20} />
      </div>

      <div>
        <p className="font-bold">
          {/* UPDATE HERE */}
          <FormattedMessage defaultMessage="Year end sale!" />
          {/* UPDATE HERE */}
        </p>
      </div>
      {showPromoDialog && (
        <PromoDialog
          onClose={() => {
            setShowPromoDialog(false);
            setSearchParams();
          }}
        />
      )}
    </button>
  );
};

/**
 * Promotion Banner
 * @returns {React.FC} component
 */
export const PromoBanner: React.FC = () => {
  const userId = useSelector(selectUid);
  const userTier = useSelector(selectUserTier);
  const team = useSelector(selectTeam);
  const canSeePromotion = canSeePromo(userId, userTier, team);

  if (!canSeePromotion) return null;

  return (
    <div className="px-8 pt-4">
      <div className="mx-auto h-full w-full rounded-xl bg-gradient-to-r from-fuchsia-500 via-purple-500 to-indigo-600 p-1">
        <div className="flex h-full w-full flex-col items-center justify-between gap-2 rounded-lg bg-white px-6 py-5 md:flex-row">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-2xl text-blue-950">
              {/* UPDATE HERE */}
              <FormattedMessage defaultMessage="Special Year-End Offer for your team!" />
              {/* UPDATE HERE */}
            </p>
            <div className="flex flex-col">
              <p>
                <FormattedMessage
                  defaultMessage="Your team can get an additional {seats} annual seats with {amount} off - and boost productivity for the whole next year! Offer expires {expiry}."
                  values={{
                    amount: <b className="text-indigo-600">30%</b>,
                    seats: <b className="text-indigo-600">10</b>,
                    expiry: <b>{CutOffDateString}</b>,
                  }}
                />
              </p>
            </div>
          </div>
          <Button
            size="large"
            startIcon={<Gift className="h-4 w-4" />}
            onClick={() => {
              trackWebEvent(`${PromotionEventName} - Clicked`, {
                tier: userTier,
                where: 'banner',
              });
            }}
            href="/#/team?offer=year-end-2024"
          >
            <FormattedMessage defaultMessage="Claim your 30% off now" />
          </Button>
        </div>
      </div>
    </div>
  );
};
