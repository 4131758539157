import { NodeType } from '.';

export const minHeights: Record<NodeType, number> = {
  AddLabels: 150,
  CustomPrompt: 415,
  RunMeetingKit: 170,
  SaveToSpace: 150,
  SendData: 132,
  Share: 300,
  StartNode: 60,
  Template: 256,
  Condition: 256,
  Confirmation: 256,
};
