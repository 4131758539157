import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert } from '../../../../components/Alert';
import { Button } from '../../../../components/buttons';
import {
  TierPricingDialogSource,
  trackOpenUpgradeDialog,
  trackUserOutOfCreditsAlert,
} from '../../../../helpers/analytics';
import { RootState } from '../../../../redux/store';
import { TierPricingDialog } from '../../../Credits/TierPricing/TierPricingDialog';

export const RanOutOfCredits: React.FC = () => {
  const user = useSelector((state: RootState) => state.user);
  const userPlan = user.plan;
  const userTier = user.tier;
  const teamTier = user.team?.tier;
  const allowance = userPlan.free.aiCredits.allowance;
  const [showUpgradeDialog, setShowUpgradeDialog] = React.useState(false);

  React.useEffect(() => {
    trackUserOutOfCreditsAlert(user.id);
  }, [user.id]);

  return (
    <div>
      <Alert
        variant="light"
        severity="warning"
        description={
          <div className="flex flex-col">
            <FormattedMessage
              defaultMessage="You've used <b>{allowance}/{allowance} AI Credits</b> for the month."
              values={{
                b: (chunks) => <strong>{chunks}</strong>,
                allowance,
              }}
            />
            <FormattedMessage defaultMessage="Upgrade your plan for more credits" />
          </div>
        }
        action={
          <Button
            upgrade
            size="small"
            textSize="xs"
            variant="neutral-secondary"
            onClick={() => {
              trackOpenUpgradeDialog(
                user.id,
                user.team?.id,
                userTier,
                teamTier
              );
              setShowUpgradeDialog(true);
            }}
          >
            <FormattedMessage defaultMessage="Upgrade now" />
          </Button>
        }
      />
      {showUpgradeDialog && (
        <TierPricingDialog
          onClose={() => setShowUpgradeDialog(false)}
          userTier={userTier}
          teamTier={teamTier}
          source={TierPricingDialogSource.RAN_OUT_OF_CREDITS}
        />
      )}
    </div>
  );
};
